import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { CAAS_RESOURCE } from 'shared/resources'

const SimulatorPage = lazy(() => import('pages/Caas/Simulator'))
const BannerPage = lazy(() => import('pages/Caas/Banner'))
const RegulationPage = lazy(() => import('pages/Caas/Regulation'))
const RegulationCreatePage = lazy(() => import('pages/Caas/CreateRegulation'))
const RegulationUpdatePage = lazy(() => import('pages/Caas/UpdateRegulation'))

export const CaasRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Caas',
    iconComponent: Icons.CalendarToday,
    menus: [
      {
        path: `${CAAS_RESOURCE}/update`,
        name: 'Editar Caas',
        component: SimulatorPage,
        exact: true,
        menus: [],
      },
      {
        path: `${CAAS_RESOURCE}/banner`,
        name: 'Caas Banner',
        component: BannerPage,
        exact: true,
        menus: [],
      },
      {
        path: `${CAAS_RESOURCE}/regulation`,
        name: 'Caas Regulamento',
        component: RegulationPage,
        exact: true,
        menus: [],
      },
      {
        path: `${CAAS_RESOURCE}/create`,
        name: 'Criar Regulamento Caas',
        component: RegulationCreatePage,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${CAAS_RESOURCE}/details/:id`,
        name: 'Editar Regulamento Caas',
        component: RegulationUpdatePage,
        isSubRoute: true,
        menus: [],
      },
    ],
  },
]

export default function CaasRoutesComponent() {
  return <>{buildRouteAndSubRoute(CaasRoutes)}</>
}
