import { makeStyles } from '@material-ui/core'

export const tableStyle = makeStyles((theme) => ({
  containerStyled: {
    marginTop: 8,
    overflowX: 'visible',
  },
  textStatus: {
    textAlign: 'center',
    fontSize: 12,
  },
  paginationStyled: {
    backgroundColor: '#6B6B6B',
    color: '#F1F1F1',
    justifyContent: 'flex-start',
    display: 'flex',
  },
  pagination: {
    marginTop: 16,
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },

  arrow: {
    height: 0,
    width: 0,
    position: 'absolute',
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderTop: '8px solid transparent',
    borderBottom: '8px solid #939598',
    right: 10,
    top: 15,
  },
  modal: {
    height: 239,
    width: 147,
    backgroundColor: '#F1F1F1',
    borderWidth: 1,
    borderColor: '#BEBEBE',
    borderStyle: 'solid',
    position: 'absolute',
    right: 0,
    top: 30,
    borderRadius: 5,
    zIndex: 2,
  },
  textModal: {
    marginTop: 10,
    height: 30,
    display: 'block',
    textAlign: 'center',
    borderBottomColor: '#BEBEBE',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    color: '#A3301F',
    cursor: 'pointer',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  rowAlternative: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    gap: 10,
  },
  status: {
    height: 15,
    width: 15,
    borderRadius: 7.5,
  },
  title: {
    marginBottom: 8,
  },
  textArea: {
    width: '100%',
    height: 120,
    marginTop: 20,
    padding: 8,
    resize: 'none',
    font: 'normal 1em sans-serif;',
    borderRadius: 4,
  },
  separator: {
    width: '100%',
    backgroundColor: '#D3D3D3',
    height: 1,
    marginBottom: 16,
  },

  // ************* styled prévias - V2 ********************** //
  headers: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleList: {
    fontSize: '2rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.04063rem',
    marginBottom: '0.75rem',
  },

  subtitleList: {
    color: '#333',
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginBottom: '2rem',
  },
  formControl: {
    padding: 0,
    marginRight: 13,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
  iconButton: {
    background: '#DE2817',
    height: 55,
    borderRadius: '0 5px 5px 0',
    marginRight: 0,
    '&:hover': {
      background: '#DE2817',
    },
  },
  filterCountBox: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  filterCountNumber: {
    top: 10,
    left: 12,
    width: 15,
    height: 15,
    border: '1px solid white',
    position: 'absolute',
    borderRadius: '50%',
    background: '#DE2817',
    fontsize: 11,
    color: 'white',
    textAlign: 'center',
    '& span': {
      fontSize: '0.6rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 'bold',
    },
  },
  colorTextStatus: {
    width: '180px',
    textAlign: 'center',
    padding: 6,
    borderRadius: 6,
  },

  tableCellItemCursor: {
    '& td': {
      border: '1px solid #f1f1f1',
      '&:nth-child(1)': {
        fontWeight: 'bold',
      },
    },
  },
  tableCellRow: {
    backgroundColor: 'white',
    '&:nth-child(even)': {
      backgroundColor: '#EBEBEB',
    },
  },
  actionsIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '0.75rem',
    fontSize: '1.1rem',
  },
  iconButtonAction: {
    cursor: 'pointer',
    color: 'black',
    fontSize: '1.1rem',
  },
  alignCenter: {
    textAlign: 'center',
  },
  inputCPF: {
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    fontSize: '0.875rem',
    textAlign: 'left',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: 1.43,
    color: 'black',
  },
  headersModalArchive: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem',
    '& svg': {
      color: '#9E9E9E',
      fontSize: '1.2rem',
      cursor: 'pointer',
    },
  },
  modalWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '0 1.5rem',
  },
  modalDataRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  modalData: {
    padding: '0.5rem 0.5rem 0.5rem 0',
    flex: 1,
    marginRight: '1rem',
  },
  dataText: {
    fontWeight: 'bold',
  },
  footerModal: {
    backgroundColor: '#f1f1f1',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem 1.5rem',
    marginTop: '1rem',
  },
  noteContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: '10px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f5f5f5',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  noteText: {
    color: theme.palette.text.primary,
  },
}))
