import { EnumNegotiationStatus } from './types'

export const getStatusDescription = (status: string | undefined) => {
  switch (status) {
    case EnumNegotiationStatus.Waiting:
      return 'Não processados'
    case EnumNegotiationStatus.WaitingPayment:
      return 'Pendentes de Pagamento'
    case EnumNegotiationStatus.Completed:
      return 'Finalizado'
    case EnumNegotiationStatus.Refused:
      return 'Recusado'
    case EnumNegotiationStatus.Unfinished:
      return 'Rascunho'
    case EnumNegotiationStatus.PaymentApproved:
      return 'Pagamento Aprovado'
    case EnumNegotiationStatus.PaymentRecused:
      return 'Pagamento Recusado'
    case EnumNegotiationStatus.PaymentRefused:
      return 'Pagamento Rejeitado'
    default:
      return ''
  }
}
