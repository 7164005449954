import { action } from 'typesafe-actions'
import { PostSalesType } from './types'

export const getPostSaleRequest = (res: any) => action(PostSalesType.GET_POST_SALE_REQUEST, res)
export const getPostSaleSuccess = (data: any) => action(PostSalesType.GET_POST_SALE_SUCCESS, {data})
export const getPostSaleFailure = (res: any) => action(PostSalesType.GET_POST_SALE_FAILURE)

export const getBlacklistRequest = (params: any) => action(PostSalesType.GET_BLACKLIST_REQUEST, {params})
export const getBlacklistSuccess = (data: any) => action(PostSalesType.GET_BLACKLIST_SUCCESS, {data})
export const getBlacklistFailure = (res: any) => action(PostSalesType.GET_BLACKLIST_FAILURE)

export const setBlacklistRequest = (data: any) => action(PostSalesType.SET_BLACKLIST_REQUEST , {data})
export const setBlacklistSuccess = (data: any) => action(PostSalesType.SET_BLACKLIST_SUCCESS , {data})
export const setBlacklistFailure = (data: any) => action(PostSalesType.SET_BLACKLIST_FAILURE , {data})

export const removeBlacklistRequest = (data: any) => action(PostSalesType.REMOVE_BLACKLIST_REQUEST , {data})
export const removeBlacklistSuccess = (data: any) => action(PostSalesType.REMOVE_BLACKLIST_SUCCESS , {data})
export const removeBlacklistFailure = (data: any) => action(PostSalesType.REMOVE_BLACKLIST_FAILURE , {data})
// export const getBlacklistSuccess = (data: any) => action(PostSalesType.GET_BLACKLIST_SUCCESS, {data})
// export const getBlacklistFailure = (res: any) => action(PostSalesType.GET_BLACKLIST_FAILURE)

export const getPersonRequest = (params: any) => action(PostSalesType.GET_PERSON_REQUEST, {params})
export const getPersonSuccess = (data: any) => action(PostSalesType.GET_PERSON_SUCCESS, {data})
export const getPersonFailure = (res: any) => action(PostSalesType.GET_PERSON_FAILURE)

export const getConversationRequest = (params: any) => action(PostSalesType.GET_CONVERSATION_REQUEST, {params})
export const getConversationSuccess = (data: any) => action(PostSalesType.GET_CONVERSATION_SUCCESS, {data})
export const getConversationFailure = (res: any) => action(PostSalesType.GET_CONVERSATION_FAILURE)

export const exportPostSaleListRequest = () => action(PostSalesType.EXPORT_SALE_LIST_REQUEST, )
export const exportPostSaleListSuccess = (data: any) => action(PostSalesType.EXPORT_SALE_LIST_SUCCESS, {data})

export const getDashboardRequest = (params: any) => action(PostSalesType.GET_DASHBOARD_REQUEST, params)
export const getDashboardSuccess = (data: any) => action(PostSalesType.GET_DASHBOARD_SUCCESS, {data})