export const InsuranceTypes = {
  GET_INSURANCE_REQUEST: '@@insurance/GET_INSURANCE_REQUEST',
  GET_INSURANCE_SUCCESS: '@@insurance/GET_INSURANCE_SUCCESS',
  GET_INSURANCE_FAILURE: '@@insurance/GET_INSURANCE_FAILURE',

  GET_INSURANCE_BY_ID_REQUEST: '@@insurance/GET_INSURANCE_BY_ID_REQUEST',
  GET_INSURANCE_BY_ID_SUCCESS: '@@insurance/GET_INSURANCE_BY_ID_SUCCESS',
  GET_INSURANCE_BY_ID_FAILURE: '@@insurance/GET_INSURANCE_BY_ID_FAILURE',

  POST_ACCEPT_OR_DECLINE_REQUEST: '@@insurance/POST_ACCEPT_OR_DECLINE_REQUEST',
  POST_ACCEPT_OR_DECLINE_SUCCESS: '@@insurance/POST_ACCEPT_OR_DECLINE_SUCCESS',
  POST_ACCEPT_OR_DECLINE_FAILURE: '@@insurance/POST_ACCEPT_OR_DECLINE_FAILURE',

  GET_INSURANCE_EXPORT_REQUEST: '@@insurance/GET_INSURANCE_EXPORT_REQUEST',
  GET_INSURANCE_EXPORT_SUCCESS: '@@insurance/GET_INSURANCE_EXPORT_SUCCESS',
  GET_INSURANCE_EXPORT_FAILURE: '@@insurance/GET_INSURANCE_EXPORT_FAILURE',
}

export interface IInsuranceState {
  list: Record<string, any>
  details: Record<string, any>
  actionInsurance: Record<string, any>
  export: Record<string, any>
}
