import { ToastrTypes, IToastrState } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: IToastrState = {
  message: '',
  show: false,
  type: 'error',
}

export const toastrReducer = (
  state: IToastrState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IToastrState => {
  switch (action.type) {
    case ToastrTypes.SHOW_TOASTR:
      return {
        show: true,
        message: action.payload.message,
        type: action.payload.type,
      }
    case ToastrTypes.HIDE_TOASTER:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
