import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { ECOMMERCE_RESOURCE } from 'shared/resources'

const EcommerceListPage = lazy(() => import('pages/Ecommerce/List'))
const EcommerceDetailPage = lazy(() => import('pages/Ecommerce/Detail'))

export const EcommerceRoutes: MenuItems[] = [
  {
    path: '',
    name: 'E-commerce',
    iconComponent: Icons.ShoppingCart,
    menus: [
      {
        path: `${ECOMMERCE_RESOURCE}/lista`,
        name: 'Lista',
        component: EcommerceListPage,
        exact: true,
        menus: [],
      },
      {
        path: `${ECOMMERCE_RESOURCE}/detalhe/:id`,
        name: 'Detail',
        component: EcommerceDetailPage,
        isSubRoute: true,
        menus: [],
      },
    ],
  },
]

export default function EcommerceRoutesComponent() {
  return <>{buildRouteAndSubRoute(EcommerceRoutes)}</>
}
