import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'
import { WhatsTypes, IWhatsAppState } from './types'

export const initialState: IWhatsAppState = {
  list: {
    loading: false,
    data: [],
    error: '',
  },
  account: {
    loading: false,
    data: [],
    error: '',
    pg: 1,
    pgs: 1,
    limit: 20,
    name: '',
    status: '',
  },
  accountById: {
    loading: false,
    data: [],
    error: '',
    pg: 1,
    pgs: 1,
    limit: 20,
    name: '',
    status: '',
  },
  qrCode: {
    data: '',
  },
  qrCodeStatus: {
    loading: false,
    data: [],
    error: '',
  },
  shootById: {
    loading: false,
    data: [],
    error: '',
    qtd: '',
    pgs: 0,
  },
  listShootById: {
    loading: false,
    data: [],
    error: '',
    qtd: '',
    pgs: 0,
  },
  qrCodeDevice: {
    loading: false,
    data: [],
    error: '',
  },
  cancelShoot: {
    loading: false,
    data: [],
    error: '',
  },
  createShoot: {
    loading: false,
    data: [],
    error: '',
  },
  createAccount: {
    loading: false,
    data: [],
    error: '',
  },
  validAccount: {
    loading: false,
    data: [],
    error: '',
  },
}

export const whatsAppReducer = (
  state: IWhatsAppState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IWhatsAppState => {
  switch (action.type) {
    case WhatsTypes.GET_WHATS_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
      }
    case WhatsTypes.GET_WHATS_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: action.payload.res.itens,
      }

    case WhatsTypes.GET_WHATS_ACCOUNT_BY_ID_SUCCESS:
      return {
        ...state,
        accountById: action.payload.res.item,
      }

    case WhatsTypes.GET_WHATS_QRCODE_SUCCESS:
      return {
        ...state,
        qrCode: action.payload.res,
      }
    case WhatsTypes.GET_WHATS_QRCODE_STATUS_SUCCESS:
      return {
        ...state,
        qrCodeStatus: action.payload.res,
      }

    case WhatsTypes.GET_WHATS_SHOOT_BY_ID_SUCCESS:
      return {
        ...state,
        shootById: action.payload.res,
      }

    case WhatsTypes.GET_WHATS_LIST_SHOOT_BY_ID_SUCCESS:
      return {
        ...state,
        listShootById: action.payload.res,
      }

    case WhatsTypes.GET_WHATS_QRCODE_DEVICE_SUCCESS:
      return {
        ...state,
        qrCodeDevice: action.payload.res,
      }

    case WhatsTypes.GET_WHATS_CANCEL_SHOOT_SUCCESS:
      return {
        ...state,
        cancelShoot: action.payload.res,
      }

    case WhatsTypes.GET_WHATS_CREATE_SHOOT_REQUEST:
      return {
        ...state,
        createShoot: {
          ...state.createShoot,
          loading: true,
        },
      }
    case WhatsTypes.GET_WHATS_CREATE_SHOOT_SUCCESS:
      return {
        ...state,
        createShoot: {
          ...state.createShoot,
          data: action.payload.res,
          error: '',
          loading: false,
        },
      }
    case WhatsTypes.GET_WHATS_CREATE_SHOOT_FAILURE:
      return {
        ...state,
        createShoot: {
          ...state.createShoot,
          data: [],
          error: 400,
          loading: false,
        },
      }
    case WhatsTypes.GET_WHATS_CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        createAccount: action.payload.res,
      }
    case WhatsTypes.GET_WHATS_VALIDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        validAccount: action.payload.res,
      }

    default:
      return state
  }
}
