import apiGrowth from 'store/utils/growth'
import { InsuranceTypes } from './types'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
  getInsuranceListSuccess,
  getInsuranceListFailure,
  getInsuranceListByIdSuccess,
  getInsuranceListByIdFailure,
  postAcceptOrDeclineDpsSuccess,
  postAcceptOrDeclineDpsFailure,
  getInsuranceExportSuccess,
  getInsuranceExportFailure,
} from './actions'

// Listagem de DPS
export function* getInsuranceList(param) {
  try {
    const res: any = yield call(apiGrowth.get, `/api/seguro/backstage/dps`, { params: param.payload })
    yield put(getInsuranceListSuccess(res))
  } catch (err) {
    yield put(getInsuranceListFailure())
  }
}

// Informações de apenas uma DPS
export function* getInsuranceDetails(data: any) {
  const id = data.payload.id
  try {
    const res: any = yield call(apiGrowth.get, `/api/seguro/backstage/dps/${id}`)
    yield put(getInsuranceListByIdSuccess(res))
  } catch (err) {
    yield put(getInsuranceListByIdFailure())
  }
}

// Exportar a DPS
export function* getInsuranceExport() {
  try {
    const res: any = yield call(apiGrowth.get, `/api/seguro/backstage/exportar`)
    yield put(getInsuranceExportSuccess(res))
  } catch (err) {
    yield put(getInsuranceExportFailure())
  }
}

// Aceitar ou recusar uma DPS
export function* postAcceptOrDeclineDps(params: any) {
  try {
    const info = {
      option: params?.payload?.params?.option,
      dps: params?.payload?.params?.dps,
      userMail: params?.payload?.params?.userMail,
    }

    const res: any = yield call(apiGrowth.post, `/api/seguro/backstage/dps-status`, info)
    yield put(postAcceptOrDeclineDpsSuccess(res))
  } catch (err) {
    yield put(postAcceptOrDeclineDpsFailure())
  }
}

function* watchSagas(): Generator {
  yield takeLatest(InsuranceTypes.GET_INSURANCE_REQUEST, getInsuranceList)
  yield takeLatest(InsuranceTypes.GET_INSURANCE_BY_ID_REQUEST, getInsuranceDetails)
  yield takeLatest(InsuranceTypes.GET_INSURANCE_EXPORT_REQUEST, getInsuranceExport)
  yield takeLatest(InsuranceTypes.POST_ACCEPT_OR_DECLINE_REQUEST, postAcceptOrDeclineDps)
}

export default function* previasSagas() {
  yield all([fork(watchSagas)])
}
