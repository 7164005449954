import React from 'react'
import { Box, TableCell, makeStyles, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown19, faArrowDown91 } from '@fortawesome/pro-light-svg-icons'
interface TableCellItemProps {
  title: string | undefined
  identification?: string | undefined
  hasMinWidth?: number
  hasIcons?: boolean
  ascOrDesc?: any
  setAscOrDesc?: any
  page?: number
  setPage?: any
}

const style = makeStyles(() => ({
  titleColor: {
    color: '#F1F1F1',
    fontWeight: 'bold',
    fontSize: 16,
    borderRight: 1,
    borderRightStyle: 'solid',
    padding: 8,
    paddingLeft: 16,
    borderRightColor: 'white',
  },
  headers: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

const TableCellItem: React.FC<TableCellItemProps> = ({
  title = '',
  hasMinWidth,
  hasIcons,
  ascOrDesc,
  setAscOrDesc,
  identification,
}) => {
  const classes = style()

  let sortTitle = '';

  const ordinationCampaign = (ord: string) => {
    setAscOrDesc({ ...ascOrDesc, [identification!]: ord })

    if (title === 'Registro') sortTitle = 'createdAt'
    if (ascOrDesc === undefined) ascOrDesc = 'asc'
  }

  const chooseIcons = (title: string, ascOrDesc: string) => {
    if (ascOrDesc === 'asc') {
      switch (title) {
        case 'Registro':
          return (
            <FontAwesomeIcon
              icon={faArrowDown19}
              onClick={() => ordinationCampaign('desc')}
              style={{ cursor: 'pointer' }}
            />
          )
        default:
          return ''
      }
    } else {
      switch (title) {
        case 'Registro':
          return (
            <FontAwesomeIcon
              icon={faArrowDown91}
              onClick={() => ordinationCampaign('asc')}
              style={{ cursor: 'pointer' }}
            />
          )
        default:
          return ''
      }
    }
  }
  return (
    <TableCell style={{ minWidth: hasMinWidth, border: '1px solid #f1f1f1' }}>
      <Box className={classes.headers}>
        <Typography>{title}</Typography>
        {!hasIcons && chooseIcons(title, ascOrDesc)}
      </Box>
    </TableCell>
  )
}

export default TableCellItem
