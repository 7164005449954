/* eslint-disable react/display-name */
import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { CONTEMPLAY_RESOUCE } from 'shared/resources'

const ContemplayDocsList = lazy(() => import('pages/Contemplay/List/'))
const Blacklist = lazy(() => import('pages/Contemplay/Blacklist/'))
const ChangeCategory = lazy(() => import('pages/Contemplay/ChangeCategory/'))

export const ContemplayRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Contemplay',
    iconComponent: Icons.Build,
    menus: [
      {
        path: `${CONTEMPLAY_RESOUCE}`,
        name: 'Links Contemplay',
        component: ContemplayDocsList,
        exact: true,
        menus: [],
      },
      {
        path: `${CONTEMPLAY_RESOUCE}/blacklist`,
        name: 'Blacklist',
        component: Blacklist,
        exact: true,
        menus: [],
      },
      {
        path: `${CONTEMPLAY_RESOUCE}/change-category`,
        name: 'Alteração de categoria',
        component: ChangeCategory,
        exact: true,
        menus: [],
      },
    ],
  },
]

export default () => <>{buildRouteAndSubRoute(ContemplayRoutes)}</>
