import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { LP_UNITY_RESOURCE } from 'shared/resources'

const LpUnityListPage = lazy(() => import('pages/LpUnity/List'))
const LpUnityDetailPage = lazy(() => import('pages/LpUnity/Details'))
const LpUnityCreatePage = lazy(() => import('pages/LpUnity/Create'))

export const LpUnityRoutes: MenuItems[] = [
  {
    path: '',
    name: 'LPUnidades',
    iconComponent: Icons.CalendarToday,
    menus: [
      {
        path: `${LP_UNITY_RESOURCE}/management`,
        name: 'Lista LP unidades',
        component: LpUnityListPage,
        exact: true,
        menus: [],
      },
      {
        path: `${LP_UNITY_RESOURCE}/details/:id`,
        name: 'Editar LP unidades',
        component: LpUnityDetailPage,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${LP_UNITY_RESOURCE}/create`,
        name: 'Criar LP unidades',
        component: LpUnityCreatePage,
        isSubRoute: true,
        menus: [],
      },
    ],
  },
]

export default function LpUnityRoutesComponent() {
  return <>{buildRouteAndSubRoute(LpUnityRoutes)}</>
}
