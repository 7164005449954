import { RolesTypes, IRolesState } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: IRolesState = {
  data: [],
  loading: false,
  error: '',
}

export const rolesReducer = (
  state: IRolesState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IRolesState => {
  switch (action.type) {
    case RolesTypes.GET_ROLES_REQUEST:
    case RolesTypes.CREATE_ROLE_REQUEST:
    case RolesTypes.UPDATE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case RolesTypes.GET_ROLES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      }
    case RolesTypes.GET_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: 'Erro ao obter dados.',
      }
    default:
      return state
  }
}
