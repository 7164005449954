export const AssemblyMotorsTypes = { 
  GET_ASSEMBLY_MOTORS_SELECTS_REQUEST: '@@assembly/GET_ASSEMBLY_MOTORS_SELECTS_REQUEST',
  GET_ASSEMBLY_MOTORS_SELECTS_SUCCESS: '@@assembly/GET_ASSEMBLY_MOTORS_SELECTS_SUCCESS',
  GET_ASSEMBLY_MOTORS_SELECTS_FAILURE: '@@assembly/GET_ASSEMBLY_MOTORS_SELECTS_FAILURE',
  GET_ASSEMBLY_MOTORS_SELECTS_CURRENT: '@@assembly/GET_ASSEMBLY_MOTORS_SELECTS_CURRENT',
  GET_ASSEMBLY_MOTORS_DEFAULT_REQUEST: '@@assembly/GET_ASSEMBLY_MOTORS_DEFAULT_REQUEST',
  GET_ASSEMBLY_MOTORS_DEFAULT_SUCCESS: '@@assembly/GET_ASSEMBLY_MOTORS_DEFAULT_SUCCESS',
  GET_ASSEMBLY_MOTORS_DEFAULT_FAILURE: '@@assembly/GET_ASSEMBLY_MOTORS_DEFAULT_FAILURE',
  CREATE_NEW_ASSEMBLY_MOTORS_REQUEST: '@@assembly/CREATE_NEW_ASSEMBLY_MOTORS_REQUEST',
  CREATE_NEW_ASSEMBLY_MOTORS_SUCCESS: '@@assembly/CREATE_NEW_ASSEMBLY_MOTORS_SUCCESS',
  CREATE_NEW_ASSEMBLY_MOTORS_FAILURE: '@@assembly/CREATE_NEW_ASSEMBLY_MOTORS_FAILURE',
  UPDATE_ASSEMBLY_MOTORS_REQUEST: '@@assembly/UPDATE_ASSEMBLY_MOTORS_REQUEST',
  UPDATE_ASSEMBLY_MOTORS_SUCCESS: '@@assembly/UPDATE_ASSEMBLY_MOTORS_SUCCESS',
  UPDATE_ASSEMBLY_MOTORS_FAILURE: '@@assembly/UPDATE_ASSEMBLY_MOTORS_FAILURE',
  UPDATE_ASSEMBLY_MOTORS_CLEAR: '@@assembly/UPDATE_ASSEMBLY_MOTORS_CLEAR',
}

export interface IAssemblyMotorsState {
  selects: Record<string, any>
  results: Record<string, any>
  lines: Record<string, any>
  lotteries: Record<string, any>
  datatime: string
  new: Record<string, any>
  edit: Record<string, any>
}
