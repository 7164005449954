import React, {
  useState,
  // Consumer,
  // Provider as ReactProvider
} from 'react'
import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from 'store'
import { Provider } from 'react-redux'
import { createTheme, ThemeProvider } from '@material-ui/core'
import Routes from 'routes'
import Toastr from 'components/Toastr'
import { themeDefaults } from 'config/material-theme'
import Pusher from 'config/pusher'

const initialState = (window as any).initialReduxState
const store = configureStore(initialState)

type ThemeProps = 'light' | 'dark' | undefined

export const ThemeCtx = React.createContext<{
  theme: ThemeProps
  toggleTheme: () => void
}>({
  theme: 'light',
  toggleTheme: () => null,
})

export const pusher = Pusher()
export const channel = pusher.subscribe('admin-channel')

const App: React.FC = () => {
  const [appTheme, setAppTheme] = useState<ThemeProps>('light')
  const theme = createTheme({
    palette: {
      ...themeDefaults.palette,
      type: appTheme || 'light',
    },
  })

  const toggleTheme = () => setAppTheme(appTheme === 'light' ? 'dark' : 'light')

  return (
    <ThemeProvider theme={theme}>
      <ThemeCtx.Provider value={{ theme: appTheme, toggleTheme: toggleTheme }}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Routes />
            <Toastr />
          </ConnectedRouter>
        </Provider>
      </ThemeCtx.Provider>
    </ThemeProvider>
  )
}

export default App
