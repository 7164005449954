import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import apiGrowth from 'store/utils/growth'
import {
  createCaasRegulationFailure,
  createCaasRegulationSuccess,
  deleteCaasRegulationFailure,
  deleteCaasRegulationSuccess,
  getCaasBannerFailure,
  getCaasBannerSuccess,
  getCaasConsorciosFailure,
  getCaasConsorciosSuccess,
  getCaasRegulationByIdFailure,
  getCaasRegulationByIdSuccess,
  getCaasRegulationFailure,
  getCaasRegulationSuccess,
  getCaasSimulatorsFailure,
  getCaasSimulatorsSuccess,
  updateCaasBannerFailure,
  updateCaasBannerSuccess,
  updateCaasRegulationFailure,
  updateCaasRegulationSuccess,
  updateCaasSimulatorsFailure,
  updateCaasSimulatorsSuccess,
} from './actions'
import { CaasTypes } from './types'

// Simuladores
export function* getCaasSimuladores(action: any) {
  try {
    const res: any = yield call(apiGrowth.get, `api/caas/simuladores`, action.payload)
    yield put(getCaasSimulatorsSuccess(res))
  } catch (err) {
    yield put(getCaasSimulatorsFailure())
  }
}

// Consórcios
export function* getCaasConsorcios(action: any) {
  try {
    const res: any = yield call(apiGrowth.get, `api/caas/consorcios`, action.payload)
    yield put(getCaasConsorciosSuccess(res))
  } catch (err) {
    yield put(getCaasConsorciosFailure())
  }
}

// Banners

export function* getCaasAllBanners(action: any) {
  try {
    const id = action.payload.params.consorcio_id
    const res: any = yield call(apiGrowth.get, `api/caas/banners/${id}`)
    yield put(getCaasBannerSuccess(res))
  } catch (err) {
    yield put(getCaasBannerFailure())
  }
}

export function* updateBanner(action: any) {
  const id = action.payload.params.consorcio_id
  try {
    const res: any = yield call(apiGrowth.put, `api/caas/banners/${id}`, action.payload.params)
    yield put(updateCaasBannerSuccess(res))
  } catch (err) {
    yield put(updateCaasBannerFailure())
  }
}

//Editar Simuladores
export function* updateCaasSimulator(params: any) {
  try {
    const res: any = yield call(apiGrowth.put, `/api/caas/simuladores/1`, params.payload.params)
    yield put(updateCaasSimulatorsSuccess(res))
  } catch (err) {
    yield put(updateCaasSimulatorsFailure())
  }
}

// Regulamentos
export function* getCaasAllRegulation(action: any) {
  try {
    const res: any = yield call(apiGrowth.get, `/api/caas/regulamentos`, action.payload)
    yield put(getCaasRegulationSuccess(res))
  } catch (err) {
    yield put(getCaasRegulationFailure())
  }
}

export function* getCaasRegulationById(action: any) {
  try {
    const id = action.payload.params
    const res: any = yield call(apiGrowth.get, `/api/caas/regulamentos/${id}`)
    yield put(getCaasRegulationByIdSuccess(res))
  } catch (err) {
    yield put(getCaasRegulationByIdFailure())
  }
}

export function* updateCaasRegulation(action: any) {
  try {
    const id = action.payload.data.id
    const res: any = yield call(apiGrowth.put, `/api/caas/regulamentos/${id}`, action.payload.data.values)
    yield put(updateCaasRegulationSuccess(res))
  } catch (err) {
    yield put(updateCaasRegulationFailure())
  }
}

export function* createCaasRegulation(action: any) {
  try {
    const res: any = yield call(apiGrowth.post, `/api/caas/regulamentos`, action.payload.data)
    yield put(createCaasRegulationSuccess(res))
  } catch (err) {
    yield put(createCaasRegulationFailure())
  }
}

export function* deleteCaasRegulation(action: any) {
  try {
    const id = action.payload.data
    yield call(apiGrowth.delete, `/api/caas/regulamentos/${id}`)
    yield put({ type: deleteCaasRegulationSuccess })
  } catch (err) {
    yield put({ type: deleteCaasRegulationFailure })
  }
}

function* watchSagas(): Generator {
  yield takeLatest(CaasTypes.UPDATE_CAAS_SIMULATORS_REQUEST, updateCaasSimulator)
  yield takeLatest(CaasTypes.GET_CAAS_SIMULATORS_REQUEST, getCaasSimuladores)
  yield takeLatest(CaasTypes.GET_CAAS_CONSORTIUMS_REQUEST, getCaasConsorcios)
  yield takeLatest(CaasTypes.GET_CAAS_BANNER_REQUEST, getCaasAllBanners)
  yield takeLatest(CaasTypes.UPDATE_CAAS_BANNER_REQUEST, updateBanner)
  yield takeLatest(CaasTypes.GET_CAAS_REGULATION_REQUEST, getCaasAllRegulation)
  yield takeLatest(CaasTypes.GET_CAAS_REGULATION_BY_ID_REQUEST, getCaasRegulationById)
  yield takeLatest(CaasTypes.UPDATE_CAAS_REGULATION_REQUEST, updateCaasRegulation)
  yield takeLatest(CaasTypes.CREATE_CAAS_REGULATION_REQUEST, createCaasRegulation)
  yield takeLatest(CaasTypes.DELETE_CAAS_REGULATION_REQUEST, deleteCaasRegulation)
}

export default function* previasSagas() {
  yield all([fork(watchSagas)])
}
