import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { SALES_MACHINE_RESOURCE } from 'shared/resources'

const SalesMachineListPage = lazy(() => import('pages/SalesMachine/List'))

export const SalesMachineRoutes: MenuItems[] = [
  {
    path: '',
    name: 'SalesMachine',
    iconComponent: Icons.CalendarToday,
    menus: [
      {
        path: `${SALES_MACHINE_RESOURCE}/list`,
        name: 'Lista de usuários',
        component: SalesMachineListPage,
        exact: true,
        menus: [],
      },
    ],
  },
]

export default function SalesMachineRoutesComponent() {
  return <>{buildRouteAndSubRoute(SalesMachineRoutes)}</>
}
