import { getStatusDescription } from './function'
import Helpers from 'shared/helpers'
import { IUser } from 'store/auth/types'

export const NegotiationsTypes = {
  GET_ALL_REQUEST: '@@negotiations/GET_ALL_REQUEST',
  GET_ALL_SUCCESS: '@@negotiations/GET_ALL_SUCCESS',
  GET_ALL_FAILURE: '@@negotiations/GET_ALL_FAILURE',

  GET_DETAIL_REQUEST: '@@negotiations/GET_DETAIL_REQUEST',
  GET_DETAIL_SUCCESS: '@@negotiations/GET_DETAIL_SUCCESS',
  GET_DETAIL_FAILURE: '@@negotiations/GET_DETAIL_FAILURE',

  POST_ACCEPT_REQUEST: '@@negotiations/POST_ACCEPT_REQUEST',
  POST_ACCEPT_SUCCESS: '@@negotiations/POST_ACCEPT_SUCCESS',
  POST_ACCEPT_FAILURE: '@@negotiations/POST_ACCEPT_FAILURE',

  POST_REJECT_REQUEST: '@@negotiations/POST_REJECT_REQUEST',
  POST_REJECT_SUCCESS: '@@negotiations/POST_REJECT_SUCCESS',
  POST_REJECT_FAILURE: '@@negotiations/POST_REJECT_FAILURE',

  POST_WAITING_PAYMENT_REQUEST: '@@negotiations/POST_WAITING_PAYMENT_REQUEST',
  POST_WAITING_PAYMENT_SUCCESS: '@@negotiations/POST_WAITING_PAYMENT_SUCCESS',
  POST_WAITING_PAYMENT_FAILURE: '@@negotiations/POST_WAITING_PAYMENT_FAILURE',

  RESEND_EMAIL_REQUEST: '@@negotiations/RESEND_EMAIL_REQUEST',
  RESEND_EMAIL_SUCCESS: '@@negotiations/RESEND_EMAIL_SUCCESS',
  RESEND_EMAIL_FAILURE: '@@negotiations/RESEND_EMAIL_FAILURE',

  POST_PAYMENT_REJECT_REQUEST: '@@negotiations/POST_PAYMENT_REJECT_REQUEST',
  POST_PAYMENT_REJECT_SUCCESS: '@@negotiations/POST_PAYMENT_REJECT_SUCCESS',
  POST_PAYMENT_REJECT_FAILURE: '@@negotiations/POST_PAYMENT_REJECT_FAILURE',

  POST_PAYMENT_CONFIRMATION_REQUEST: '@@negotiations/POST_PAYMENT_CONFIRMATION_REQUEST',
  POST_PAYMENT_CONFIRMATION_SUCCESS: '@@negotiations/POST_PAYMENT_CONFIRMATION_SUCCESS',
  POST_PAYMENT_CONFIRMATION_FAILURE: '@@negotiations/POST_PAYMENT_CONFIRMATION_FAILURE',

  POST_NEGOTIATION_REQUEST: '@@negotiations/POST_NEGOTIATION_REQUEST',
  POST_NEGOTIATION_SUCCESS: '@@negotiations/POST_NEGOTIATION_SUCCESS',
  POST_NEGOTIATION_FAILURE: '@@negotiations/POST_NEGOTIATION_FAILURE',

  RESET_DETAIL: '@@negotiations/RESET_DETAIL',
}

export enum EnumNegotiationStatus {
  Waiting = 'Waiting',
  WaitingPayment = 'WaitingPayment',
  Completed = 'Completed',
  Refused = 'Refused',
  Unfinished = 'Unfinished', // RASCUNHO
  PaymentApproved = 'PaymentApproved',
  PaymentRecused = 'PaymentRecused',
  PaymentRefused = 'PaymentRefused',
}

export interface INegotiation {
  id: string
  createdAt: string
  negociacoes: number
  nm_bem: number
  groups: string[]
  status: string
  customer: ICustomerList
}

export interface INegotiationDetail {
  _id: string
  status: string
  enabled: boolean
  payment: {
    firstParcel: number
    parcel: number
    type: string
    urlReceipt: string
  }
  createdAt: string
  updatedAt: string
  customer: ICustomerList
  user: IUser
  negociacoes: ICota[]
}

export interface ICota {
  CD_Grupo: string
  PAGAMENTO_TIPO: string
  PAGAMENTO_PLANO: string
  PRIMEIRA_PARCELA: number
  PARCELAS: number
  BEM: {
    ID_Bem: number
    CD_Bem: string
    NM_Bem: string
    Valor_Bem_Atual: number
  }
  COTA: {
    CD_Cota: number
    Versao: number
    VL_ATUAL: number
    QT_PC_Paga: number
    VL_PC_Atual: number
    VL_PAGO: number
    TA_GRUPO: number
    DT_Contemplacao: string | null
    COTA_TRANSF: number
    PE_TA_PG: number
    PE_FC_IDEAL_PG: number
    PE_FC_PG: number
    PE_AD_PG: number
    PZ_GRUPO: number
    PZ_RESTANTE: number
    ID_Cota: number
  }
  newCota: {
    cota: string | number
    contractNumber: string | number
    distribuition: string
  }
}

export interface IResendEmailRequest {
  id: string
  email: string
}

export interface ICustomerList extends ICustomer {
  socios: ICustomer[]
}

export interface ICustomer {
  // CNPJ
  cep: string
  cidade: string
  complemento: string
  cpfCnpj: string
  dataFundacao: string
  email: string
  faturamentoMedio: string
  numero: string
  razaoSocial: string
  rua: string

  // CPF
  nomeCompleto: string
  celular: string
  dataNascimento: string
  genero: string
  nomeMae: string
  nomePai: string
  profissao: string
  valorDaRenda: string
  rg: string

  dataExpedicao: string
  naturalidade: string
  nacionalidade: string
  estadoCivil: string
  politicamenteExposto: string

  // Socios
  nome: string
  pjPf: 'pf' | 'pj'
  qual: string
}

export interface INegotiationsState {
  data: INegotiation[]
  detail: INegotiationDetail | null
  loading: boolean
  loadingActions: boolean
  error: string
}

export class Negotiation implements INegotiation {
  id: string
  createdAt: string
  negociacoes: number
  nm_bem: number
  groups: string[]
  status: string
  customer: ICustomerList

  constructor(data: INegotiation) {
    this.id = data.id
    this.negociacoes = data.negociacoes
    this.nm_bem = data.nm_bem
    this.groups = data.groups
    this.customer = data.customer
    this.createdAt = Helpers.toViewDate(data.createdAt)
    this.status = getStatusDescription(data.status)
  }
}
