export enum RecorrenciaTypes {
  GET_LIST_REQUEST = '@@recorrencia/GET_LIST_REQUEST',
  GET_LIST_SUCCESS = '@@recorrencia/GET_LIST_SUCCESS',
  GET_LIST_FAILURE = '@@recorrencia/GET_LIST_FAILURE',
  SET_FILTER_LIST_REQUEST = '@@recorrencia/SET_FILTER_LIST_REQUEST',
  SET_FILTER_LIST_SUCCESS = '@@recorrencia/SET_FILTER_LIST_SUCCESS',
  SET_FILTER_LIST_FAILURE = '@@recorrencia/SET_FILTER_LIST_FAILURE',
}

export interface Recorrencia {
  idTransacao: number
  idDocumento: number
  idCota: number
  codCota: string
  codGrupo: string
  cpfcnpj: string
  nome: string
  newconIdPessoa: number
  dataVencimento: string
  dataDebito: string
  dateInMls: number
  paymentType: string
}
export interface IRecorrenciaState {
  data: Recorrencia[]
  loading: boolean
  error: boolean
}
