import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { REPASSE_RESOURCE } from 'shared/resources'

const NegotiationList = lazy(() => import('pages/Repasse/Negotiations/List'))
const NegotiationDetail = lazy(() => import('pages/Repasse/Negotiations/Detail'))
const Report = lazy(() => import('pages/Repasse/Report/'))

export const RepasseRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Repasse',
    iconComponent: Icons.ListAltOutlined,
    menus: [
      {
        path: `${REPASSE_RESOURCE}/negociacoes`,
        name: 'Lista de Repasses',
        component: NegotiationList,
        exact: true,
        menus: [],
      },
      {
        path: `${REPASSE_RESOURCE}/negociacoes/:id`,
        name: 'Detalhe do Repasse',
        component: NegotiationDetail,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${REPASSE_RESOURCE}/relatorio`,
        name: 'Relatórios',
        component: Report,
        menus: [],
      },
    ],
  },
]

export default function RepasseRoutesComponent() {
  return <>{buildRouteAndSubRoute(RepasseRoutes)}</>
}
