import { IMenu } from 'store/modules_config/types'
import { action } from 'typesafe-actions'
import { IModule, ModulesConfigTypes } from './types'

export const getModuleRequest = () => action(ModulesConfigTypes.GET_MODULES_REQUEST)
export const getModuleSuccess = (data: IModule[]) => action(ModulesConfigTypes.GET_MODULES_SUCCESS, { data })
export const getModuleFailure = () => action(ModulesConfigTypes.GET_MODULES_FAILURE)

export const getModuleByIdRequest = (id: string) => action(ModulesConfigTypes.GET_MODULE_BYID_REQUEST, { id })
export const getModuleByIdSuccess = (data: IModule) => action(ModulesConfigTypes.GET_MODULE_BYID_SUCCESS, { data })
export const getModuleByIdFailure = () => action(ModulesConfigTypes.GET_MODULE_BYID_FAILURE)

export const saveModuleRequest = (module: IModule) => action(ModulesConfigTypes.SAVE_MODULE_REQUEST, { module })
export const createModuleRequest = (module: IModule) => action(ModulesConfigTypes.CREATE_MODULE_REQUEST, { module })
export const saveModuleMenuRequest = (menu: IMenu, moduleId: string) =>
  action(ModulesConfigTypes.SAVE_MODULE_MENU_REQUEST, { menu, moduleId })
export const deleteModuleMenuRequest = (menu: IMenu, moduleId: string) =>
  action(ModulesConfigTypes.DELETE_MODULE_MENU_REQUEST, { menu, moduleId })
export const updateModuleMenuRequest = (menu: IMenu, moduleId: string) =>
  action(ModulesConfigTypes.UPDATE_MODULE_MENU_REQUEST, { menu, moduleId })

export const resetModuleDetail = () => action(ModulesConfigTypes.RESET_MODULE_DETAIL)

export const deleteModuleRequest = (id: string) => action(ModulesConfigTypes.DELETE_MODULE_REQUEST, { id })
export const deleteModuleSuccess = () => action(ModulesConfigTypes.DELETE_MODULE_SUCCESS)
export const deleteModuleFailure = () => action(ModulesConfigTypes.DELETE_MODULE_FAILURE)
