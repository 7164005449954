import { action } from 'typesafe-actions'
import { PreviasTypes } from './types'

export const getListRequest = (body: any) => action(PreviasTypes.GET_LIST_REQUEST, { body })
export const getListSuccess = (data: any) => action(PreviasTypes.GET_LIST_SUCCESS, { data })
export const getListFailure = () => action(PreviasTypes.GET_LIST_FAILURE)

export const getListRelatorioRequest = () => action(PreviasTypes.GET_LIST_RELATORIO_REQUEST)
export const getListRelatorioSuccess = (data: any) => action(PreviasTypes.GET_LIST_RELATORIO_SUCCESS, { data })
export const getListRelatorioFailure = () => action(PreviasTypes.GET_LIST_RELATORIO_FAILURE)

export const getUserDetailRequest = (id: any, shouldReload = true) =>
  action(PreviasTypes.GET_USER_DETAIL_REQUEST, { id, shouldReload })
export const getUserDetailSuccess = (data: any) => action(PreviasTypes.GET_USER_DETAIL_SUCCESS, { data })
export const getUserDetailFailure = () => action(PreviasTypes.GET_USER_DETAIL_FAILURE)

export const getHistoryListRequest = (id: string, page: number, size: number) =>
  action(PreviasTypes.GET_HISTORY_LIST_REQUEST, { id, page, size })
export const getHistoryListSuccess = (data: any) => action(PreviasTypes.GET_HISTORY_LIST_SUCCESS, { data })
export const getHistoryListFailure = () => action(PreviasTypes.GET_HISTORY_LIST_FAILURE)

export const getUnitsRequest = (id: any) => action(PreviasTypes.GET_UNITS_REQUEST, { id })
export const getUnitsSuccess = (data: any) => action(PreviasTypes.GET_UNITS_SUCCESS, { data })
export const getUnitsFailure = () => action(PreviasTypes.GET_UNITS_FAILURE)

export const getBanksRequest = () => action(PreviasTypes.GET_BANKS_REQUEST)
export const getBanksSuccess = (data: any) => action(PreviasTypes.GET_BANKS_SUCCESS, { data })
export const getBanksFailure = () => action(PreviasTypes.GET_BANKS_FAILURE)

export const getMinutaRequest = (body: any) => action(PreviasTypes.GET_MINUTA_REQUEST, { body })
export const getMinutaSuccess = (data: any) => action(PreviasTypes.GET_MINUTA_SUCCESS, { data })
export const getMinutaFailure = () => action(PreviasTypes.GET_MINUTA_FAILURE)

export const getArchivedListRequest = (page: number, size: number) =>
  action(PreviasTypes.GET_ARCHIVED_LISTS_REQUEST, { page, size })
export const getArchivedListSuccess = (data: any) => action(PreviasTypes.GET_ARCHIVED_LISTS_SUCCESS, { data })
export const getArchivedListFailure = () => action(PreviasTypes.GET_ARCHIVED_LISTS_FAILURE)

export const setFilterListRequest = (body: any) => action(PreviasTypes.SET_FILTER_LIST_REQUEST, { body })
export const setFilterListSuccess = (data: any) => action(PreviasTypes.SET_FILTER_LIST_SUCCESS, { data })
export const setFilterListFailure = () => action(PreviasTypes.SET_FILTER_LIST_FAILURE)

export const setMotivosPadraoRequest = (body: any) => action(PreviasTypes.SET_MOTIVOS_PADRAO_REQUEST, { body })
export const setMotivosPadraoSuccess = (data: any) => action(PreviasTypes.SET_MOTIVOS_PADRAO_SUCCESS, { data })
export const setMotivosPadraoFailure = () => action(PreviasTypes.SET_MOTIVOS_PADRAO_FAILURE)
export const createMotivoPadrao = (body: any) => action(PreviasTypes.CREATE_MOTIVO_PADRAO, { body })
export const editMotivoPadrao = (body: any) => action(PreviasTypes.EDIT_MOTIVO_PADRAO, { body })

export const deleteMotivoPadrao = (body: any) => action(PreviasTypes.DELETE_MOTIVO_PADRAO, { body })

export const setBlacklistRequest = (body: any) => action(PreviasTypes.SET_BLACKLIST_REQUEST, { body })
export const setBlacklistSuccess = (data: any) => action(PreviasTypes.SET_BLACKLIST_SUCCESS, { data })
export const setBlacklistFailure = () => action(PreviasTypes.SET_BLACKLIST_FAILURE)

export const setUploadRequest = (body: any) => action(PreviasTypes.SET_BLACKLIST_UPLOAD_REQUEST, { body })
export const setUploadSuccess = (data: any) => action(PreviasTypes.SET_BLACKLIST_UPLOAD_SUCCESS, { data })
export const setUploadFailure = () => action(PreviasTypes.SET_BLACKLIST_UPLOAD_FAILURE)

export const clearBlacklistFile = () => action(PreviasTypes.CLEAR_BLACKLIST_FILE)

export const setAddBlacklistRequest = (body: any) => action(PreviasTypes.SET_ADD_BLACKLIST_REQUEST, { body })
export const setAddBlacklistSuccess = (data: any) => action(PreviasTypes.SET_ADD_BLACKLIST_SUCCESS, { data })
export const setAddBlacklistFailure = () => action(PreviasTypes.SET_ADD_BLACKLIST_FAILURE)

export const setRemoveBlacklistRequest = (body: any) => action(PreviasTypes.SET_REMOVE_BLACKLIST_REQUEST, { body })
export const setRemoveBlacklistSuccess = (data: any) => action(PreviasTypes.SET_REMOVE_BLACKLIST_SUCCESS, { data })
export const setRemoveBlacklistFailure = () => action(PreviasTypes.SET_REMOVE_BLACKLIST_FAILURE)

export const setBlacklistByIdRequest = (id: string) => action(PreviasTypes.SET_BLACKLIST_BY_ID_REQUEST, { id })
export const setBlacklistByIdSuccess = (data: any) => action(PreviasTypes.SET_BLACKLIST_BY_ID_SUCCESS, { data })
export const setBlacklistByIdFailure = () => action(PreviasTypes.SET_BLACKLIST_BY_ID_FAILURE)

export const setBlacklistAddComment = (body: any) => action(PreviasTypes.SET_BLACKLIST_ADD_COMMENT, { body })
export const setBlacklistAddCommentSuccess = (data: any) =>
  action(PreviasTypes.SET_BLACKLIST_ADD_COMMENT_SUCCESS, { data })
export const setBlacklistAddCommentFailure = () => action(PreviasTypes.SET_BLACKLIST_ADD_COMMENT_FAILURE)

export const setDownloadBlacklistReport = () => action(PreviasTypes.BLACKLIST_DOWNLOAD_FILE_REQUEST)

export const setProrrogarRequest = (previaId: string, body: any) =>
  action(PreviasTypes.SET_PRORROGAR_REQUEST, { previaId, body })
export const setProrrogarSuccess = (data: any) => action(PreviasTypes.SET_PRORROGAR_SUCCESS, { data })
export const setProrrogarFailure = () => action(PreviasTypes.SET_PRORROGAR_FAILURE)

export const setRobotsRequest = (previaId: string, body: any) =>
  action(PreviasTypes.SET_ROBOTS_REQUEST, { previaId, body })
export const setRobotsSuccess = (data: any) => action(PreviasTypes.SET_ROBOTS_SUCCESS, { data })
export const setRobotsFailure = () => action(PreviasTypes.SET_ROBOTS_FAILURE)

export const setCancelContratoRequest = (id: string, body: any) =>
  action(PreviasTypes.SET_CANCEL_CONTRATO_REQUEST, { id, body })
export const setCancelContratoSuccess = (data: any) => action(PreviasTypes.SET_CANCEL_CONTRATO_SUCCESS, { data })
export const setCancelContratoFailure = () => action(PreviasTypes.SET_CANCEL_CONTRATO_FAILURE)

export const setCancelTermConfidentialityRequest = (body: any) =>
  action(PreviasTypes.SET_CANCEL_TERM_CONFIDENTIALITY_REQUEST, { body })
export const setCancelTermConfidentialitySuccess = (data: any) =>
  action(PreviasTypes.SET_CANCEL_TERM_CONFIDENTIALITY_SUCCESS, { data })
export const setCancelTermConfidentialityFailure = () => action(PreviasTypes.SET_CANCEL_TERM_CONFIDENTIALITY_FAILURE)

export const setResendTermConfidentialityRequest = (id: string) =>
  action(PreviasTypes.SET_RESEND_TERM_CONFIDENTIALITY_REQUEST, { id })
export const setResendTermConfidentialitySuccess = (data: any) =>
  action(PreviasTypes.SET_RESEND_TERM_CONFIDENTIALITY_SUCCESS, { data })
export const setResendTermConfidentialityFailure = () => action(PreviasTypes.SET_RESEND_TERM_CONFIDENTIALITY_FAILURE)

export const setRegisterUCARequest = (data: any) => action(PreviasTypes.SET_REGISTER_UCA_REQUEST, { data })
export const setRegisterUCASuccess = (data: any) => action(PreviasTypes.SET_REGISTER_UCA_SUCCESS, { data })
export const setRegisterUCAFailure = () => action(PreviasTypes.SET_REGISTER_UCA_FAILURE)

export const sendCommentRequest = (id: string, body: any, bodyContrato?: any, type?: string) =>
  action(PreviasTypes.SEND_COMMENT_REQUEST, { id, body, bodyContrato, type })
export const sendCommentSuccess = (data: any) => action(PreviasTypes.SEND_COMMENT_SUCCESS, { data })
export const sendCommentFailure = () => action(PreviasTypes.SEND_COMMENT_FAILURE)

export const sendDesistenciaRequest = (id: string, body: any) =>
  action(PreviasTypes.SEND_DESISTENCIA_REQUEST, { id, body })
export const sendDesistenciaSuccess = (data: any) => action(PreviasTypes.SEND_DESISTENCIA_SUCCESS, { data })
export const sendDesistenciaFailure = () => action(PreviasTypes.SEND_DESISTENCIA_FAILURE)

export const uploadPreviasRequest = (body: any, id: any) => action(PreviasTypes.UPLOAD_PREVIAS_REQUEST, { body, id })
export const uploadPreviasSuccess = (data: any) => action(PreviasTypes.UPLOAD_PREVIAS_SUCCESS, { data })
export const uploadPreviasFailure = () => action(PreviasTypes.UPLOAD_PREVIAS_FAILURE)

export const uploadFileRequest = (previasId: string, file: File) =>
  action(PreviasTypes.UPLOAD_FILE_REQUEST, { previasId, file })
export const uploadFileSuccess = (data: any) => action(PreviasTypes.UPLOAD_FILE_SUCCESS, { data })
export const uploadFileFailure = () => action(PreviasTypes.UPLOAD_FILE_FAILURE)

export const deleteFileConsultaExternaRequest = (previasId: string, fileId: string) =>
  action(PreviasTypes.DELETE_FILE_CONSULTA_EXTERNA_REQUEST, { previasId, fileId })

export const refreshDocsRequest = (id: any, type = 'pf') => action(PreviasTypes.REFRESH_DOCS_REQUEST, { id, type })
export const refreshDocsSuccessPf = (data: any) => action(PreviasTypes.REFRESH_DOCS_SUCCESS, { data })
export const refreshDocsSuccessPj = (data: any) => action(PreviasTypes.REFRESH_DOCS_SUCCESS_PJ, { data })
export const refreshDocsFailure = () => action(PreviasTypes.REFRESH_DOCS_FAILURE)

export const updateUserPjRequest = (previaId: string, body: any) =>
  action(PreviasTypes.UPDATE_USER_PJ_REQUEST, { previaId, body })
export const updateUserPjSuccess = (data: any) => action(PreviasTypes.UPDATE_USER_PJ_SUCCESS, { data })
export const updateUserPjFailure = () => action(PreviasTypes.UPDATE_USER_PJ_FAILURE)

export const resetStateRequest = () => action(PreviasTypes.RESET_STATE_REQUEST)
export const resetStateSuccess = (data: any) => action(PreviasTypes.RESET_STATE_SUCCESS, { data })
export const resetStateFailure = () => action(PreviasTypes.RESET_STATE_FAILURE)

export const arquivarPreviaRequest = (previaId: string, tipo: any, body: any) =>
  action(PreviasTypes.ARQUIVAR_PREVIA_REQUEST, { previaId, tipo, body })
export const arquivarPreviaSuccess = (data: any) => action(PreviasTypes.ARQUIVAR_PREVIA_SUCCESS, { data })
export const arquivarPreviaFailure = () => action(PreviasTypes.ARQUIVAR_PREVIA_FAILURE)

export const reverterDesistencia = (previaId: string) => action(PreviasTypes.REVERTER_DESISTENCIA, { previaId })

export const finishPreviaRequest = (id: string, body: any) => action(PreviasTypes.FINISH_PREVIA_REQUEST, { id, body })
export const finishPreviaSuccess = (data: any) => action(PreviasTypes.FINISH_PREVIA_SUCCESS, { data })
export const finishPreviaFailure = () => action(PreviasTypes.FINISH_PREVIA_FAILURE)

export const postAddToBlacklistRequest = (body: any) => action(PreviasTypes.POST_ADD_BLACK_LIST_REQUEST, { body })
export const postAddToBlacklistSuccess = (data: any) => action(PreviasTypes.POST_ADD_BLACK_LIST_SUCCESS, { data })
export const postAddToBlacklistFailure = () => action(PreviasTypes.POST_ADD_BLACK_LIST_FAILURE)

export const setDocumentsRequest = (id: string, body: any, type: string) =>
  action(PreviasTypes.SET_DOCUMENTS_REQUEST, { id, body, type })
export const setDocumentsSuccess = (data: any) => action(PreviasTypes.SET_DOCUMENTS_SUCCESS, { data })
export const setDocumentsFailure = () => action(PreviasTypes.SET_DOCUMENTS_FAILURE)

export const setContratoRequest = (body: any, show?: boolean, id?: string) =>
  action(PreviasTypes.SET_CONTRATO_REQUEST, { body, show, id })
export const setContratoSuccess = (data: any) => action(PreviasTypes.SET_CONTRATO_SUCCESS, { data })
export const setContratoFailure = () => action(PreviasTypes.SET_CONTRATO_FAILURE)

export const sendAprovarReprovarDocumentoRequest = (id: string, body: any, type = 'pf') =>
  action(PreviasTypes.SEND_APROVAR_REPROVAR_DOCUMENTO_REQUEST, { body, id, type })
export const sendAprovarReprovarDocumentoSuccess = () => action(PreviasTypes.SEND_APROVAR_REPROVAR_DOCUMENTO_SUCCESS)

export const sendApproveAllDocsRequest = (id: string, type: string) => action(PreviasTypes.SEND_APROVAR_ALL_DOCUMENTS_REQUEST, { id, type })
export const sendApproveAllDocsSuccess = () => action(PreviasTypes.SEND_APROVAR_ALL_DOCUMENTS_SUCCESS)
export const sendApproveAllDocsFailure = () => action(PreviasTypes.SEND_APROVAR_ALL_DOCUMENTS_FAILURE)

export const aprovarAnalisePfRequest = (id: string) => action(PreviasTypes.APROVAR_ANALISE_PF_REQUEST, { id })
export const aprovarAnalisePfSuccess = () => action(PreviasTypes.APROVAR_ANALISE_PF_SUCCESS)
export const aprovarAnalisePffailure = () => action(PreviasTypes.APROVAR_ANALISE_PF_FAILURE)

export const reprovarAnalisePfRequest = ({
  id,
  approved,
  descricao,
}: {
  id: string
  approved: string
  descricao: string
}) => action(PreviasTypes.REPROVAR_ANALISE_PF_REQUEST, { id, approved, descricao })
export const reprovarAnalisePfSuccess = () => action(PreviasTypes.REPROVAR_ANALISE_PF_SUCCESS)
export const reprovarAnalisePffailure = () => action(PreviasTypes.REPROVAR_ANALISE_PF_FAILURE)
