import { UserTypes, IUserState } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: IUserState = {
  data: [],
  list: [],
  loading: false,
  error: '',
}

export const userReducer = (
  state: IUserState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IUserState => {
  switch (action.type) {
    case UserTypes.GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case UserTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        loading: false,
      }
    case UserTypes.GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        list: [],
        error: 'Erro ao obter dados.',
      }
    default:
      return state
  }
}
