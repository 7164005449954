import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import * as Icons from '@material-ui/icons'
import style from './NavbarItems.styles'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'store/auth/actions'
import { Collapse, List, Typography } from '@material-ui/core'
import { APP_VERSION } from 'shared/constants'
import { IApplicationState } from 'store'
import Icon from '@material-ui/core/Icon'
import { IMenu } from 'store/modules_config/types'
import Helpers from 'shared/helpers'
import { PermissionsRoutes } from 'routes/Modules/Permissions'
import { ModulesConfigRoutes } from 'routes/Modules/ModulesConfig'
import { EnumFuncoes } from 'shared/enums'

const NavbarItems: React.FC<RouteComponentProps<any>> = ({ history }) => {
  const classes = style()
  const dispatch = useDispatch()
  const state = useSelector((selector: IApplicationState) => ({
    modules: selector.auth.data?.modules,
  }))

  const handleLogout = () => {
    dispatch(logout())
    history.push('/login')
  }

  return (
    <div className={classes.wrap}>
      <div>
        <ListItem button onClick={() => history.push('/')}>
          <ListItemIcon className={classes.item}>
            <Icons.HomeOutlined />
          </ListItemIcon>
          <ListItemText primary="Início" />
        </ListItem>

        {state.modules?.map((item: any, i: number) => {
          return <NavbarItem key={i} {...{ item, history }} />
        })}

        {Helpers.getUserLocalstorage()?.user.funcao === EnumFuncoes.MASTER &&
          [...PermissionsRoutes, ...ModulesConfigRoutes]
            .filter((x) => !x.isSubRoute)
            .map(({ icon, iconComponent, ...item }, i) => {
              return <NavbarItem key={i} {...{ item, icon, iconComponent, history }} />
            })}
      </div>
      <Typography align="center" variant="subtitle2" gutterBottom>
        <div className={classes.widthFull}>
          <div className={classes.separator} />
        </div>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon className={classes.item}>
            <Icons.ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>
        <small>v{APP_VERSION}</small>
      </Typography>
    </div>
  )
}

const NavbarItem: React.FC<any> = ({ history, item, iconComponent: IconComponent }) => {
  const classes = style()

  const [openCollapse, setOpenCollapse] = React.useState(false)

  const handleOpenSettings = () => {
    setOpenCollapse(!openCollapse)
  }

  const handleChangeRoute = (item: IMenu) => {
    if (item.type === 'external') {
      history.push({
        pathname: `/externo/${item.path}`,
        state: {
          ...item.iframe,
        },
      })
    } else {
      history.push(item.path)
    }
  }

  return (
    <>
      <ListItem button onClick={() => (item?.menus.length > 0 ? handleOpenSettings() : history.push(item.path))}>
        <ListItemIcon className={classes.item}>
          {IconComponent ? <IconComponent /> : <Icon>{item?.icon}</Icon>}
        </ListItemIcon>
        <ListItemText primary={item.name} />
        {item?.menus.length > 0 && (
          <React.Fragment>{openCollapse ? <Icons.ExpandLess /> : <Icons.ExpandMore />}</React.Fragment>
        )}
      </ListItem>
      <Collapse in={openCollapse} timeout="auto">
        {item?.menus.map((subMenu: IMenu, index: number) => {
          return (
            <List
              className={classes.color}
              component="div"
              disablePadding
              key={index}
              onClick={() => handleChangeRoute(subMenu)}
            >
              <ListItem button className={classes.nested}>
                <ListItemText inset primary={subMenu.name} />
              </ListItem>
            </List>
          )
        })}
      </Collapse>
    </>
  )
}

export default withRouter(NavbarItems)
