/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { DADOS_KPIS_RESOURCE } from 'shared/resources'

const KpisListPage = lazy(() => import('pages/Kpis/List'))

export const KpisRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Dados',
    iconComponent: Icons.Assessment,
    menus: [
      {
        path: `${DADOS_KPIS_RESOURCE}/inovacao`,
        name: 'Kpis',
        component: KpisListPage,
        exact: true,
        menus: [],
      },
    ],
  },
]

export default () => <>{buildRouteAndSubRoute(KpisRoutes)}</>
