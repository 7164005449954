import { faEnvelopeOpenText, faMessage } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearBlacklistFile, setBlacklistAddComment, setBlacklistByIdRequest } from 'store/previas/actions'
import ConfirmationModal from 'components/ConfirmationModal'
import Dropzone from './Dropzone'
import { IApplicationState } from 'store'

const AddCommentModal: React.FC<any> = ({ open, setOpen }) => {
  const dispatch = useDispatch()
  const { blacklist, blacklistDetail } = useSelector((state: IApplicationState) => state.previas)
  const [data, setData] = useState({
    reason: '',
    file: '',
  })

  const [errors, setErrors] = useState({
    reason: false,
  })

  useEffect(() => {
    setData({
      reason: '',
      file: '',
    })
    dispatch(clearBlacklistFile())
    // eslint-disable-next-line
  }, [open])

  const handleConfirm = () => {
    if (data.reason === '') {
      setErrors({ reason: true })
      return
    }
    setErrors({ reason: false })
    dispatch(
      setBlacklistAddComment({
        id: blacklistDetail.data.blacklist._id,
        description: data.reason,
        file: data.file,
      }),
    )
    dispatch(setBlacklistByIdRequest(blacklistDetail.data.blacklist._id))
    dispatch(clearBlacklistFile())
    setOpen(false)
  }

  useEffect(() => {
    if (blacklist.file) {
      setData({ ...data, file: blacklist.file.location })
    }
    // eslint-disable-next-line
  }, [blacklist.file])

  return (
    <ConfirmationModal
      hasTop
      width="30%"
      open={open}
      onCancel={() => setOpen(false)}
      onClose={() => setOpen(false)}
      onConfirm={() => handleConfirm()}
      cancelText="Cancelar"
      confirmText="Adicionar"
      loading={blacklist.loading}
    >
      <Typography variant="h6">
        <FontAwesomeIcon icon={faMessage} /> Adicionar um comentário
      </Typography>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '15px' }}>
        <Typography>
          <FontAwesomeIcon icon={faEnvelopeOpenText} /> Descrição
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          id="reason"
          name="reason"
          autoComplete="reason"
          label="Motivo"
          value={data.reason ?? ''}
          onChange={(e) => setData({ ...data, reason: e.target.value })}
          size="small"
          multiline
          minRows={4}
          maxRows={4}
          error={errors.reason}
        />
      </div>
      <Dropzone hasError={false} />
    </ConfirmationModal>
  )
}

export default withRouter(AddCommentModal)
