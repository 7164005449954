import api from 'store/utils/apiv2'
import { put, call, takeLatest, all, fork } from 'redux-saga/effects'
import {
  getComunicacaoSuccess,
  getComunicacaoFailure,
  createComunicacaoSucess,
  createComunicacaoFailure,
  getUserDetailSuccess,
  getUserDetailFailure,
  updateComunicacaoSucess,
  updateComunicacaoFailure,
  //getComunicacaoProductSuccess,
  //getComunicacaoProductFailure,
  sendComunicacaoSucess,
  sendComunicacaoFailure,
  getComunicacaoRequest,
  sendComunicacaoRequest,
  clearComunicacao,
  getComunicacaoTypePublicSuccess,
  getComunicacaoTypePublicFailure,
  deleteComunicacaoSucess,
  deleteComunicacaoFailure,
  getdownloadCsvComunicacaoSuccess,
  getdownloadCsvComunicacaoFailure,
  searchCampaignSuccess,
  searchCampaignFailure,
  //Campanhas
  getCampanhaRequest,
  getCampanhaSuccess,
  getCampanhaFailure,
  clearCampanha,
  createCampanhaSuccess,
  createCampanhaFailure,
  updateCampanhaSucess,
  updateCampanhaFailure,
  sendCampanhaRequest,
  sendCampanhaSucess,
  sendCampanhaFailure,
  getCampanhaTypePublicSuccess,
  getCampanhaTypePublicFailure,
  getCampanhaDetailSuccess,
  getCampanhaDetailFailure,
  deleteCampanhaSucess,
  deleteCampanhaFailure,
  spotlightCampanhaSuccess,
  spotlightCampanhaFailure,
  listTagsSucess,
  listTagsFailure,
  listCategoriesSucess,
  listCategoriesFailure,
  searchCampanhaSuccess,
  searchCampanhaFailure,
} from './actions'
import { showToastr } from 'store/toastr/actions'
import { ComunicacaoTypes } from './types'

export function* handlerComunicacaoGetList(action: any) {
  const { body } = action.payload

  let users = body.users ?? ''

  if (users) users = encodeURIComponent(users)
  if (body.dateEnd && !body.dateStart) body.dateStart = new Date().toISOString()
  if (body.dateStart && !body.dateEnd) body.dateEnd = new Date().toISOString()
  if (body.ordination && !body.ascOrDesc) body.ascOrDesc = 'desc'

  const bodyDateStart = `"dateStart":"${body.dateStart}",`
  const bodyDateEnd = `"dateEnd":"${body.dateEnd}",`
  const bodyUser = `"user":"${users}",`
  const bodyStatus = `"status":"${body.status}",`
  const bodyCategory = `"category":"${body.category}"`
  const bodyRadio = `&radio=${body.radio}`
  const bodyPage = `&page=${body.page}`
  const bodySort = `&sort={"${body.ordination}":"${body.ascOrDesc}"}`

  let resFilter = `?filterBy={${users ? bodyUser : ''}${body.dateStart ? bodyDateStart : ''}${
    body.dateEnd ? bodyDateEnd : ''
  }${body.status ? bodyStatus : ''}${body.category ? bodyCategory : ''}}${body.radio ? bodyRadio : ''}${
    body.ordination ? bodySort : ''
  }${body.page ? bodyPage : ''}`

  // Tratativas para conversão de Json e tratativas para URL
  resFilter = resFilter.replace(/,}/g, '}')

  try {
    const res: any = yield call(api.get, `/backoffice/comunicacoes/list${resFilter}`)
    yield put(getComunicacaoSuccess(res))
  } catch (err) {
    yield put(getComunicacaoFailure())
  }
}

export function* handlerCreateComunicacao(action: any) {
  const { body, send, isTest } = action.payload

  try {
    const res: any = yield call(api.post, `/backoffice/comunicacoes/`, body)
    yield put(createComunicacaoSucess(res))

    if (send) {
      yield put(sendComunicacaoRequest(res._id, isTest))
    } else {
      yield put(showToastr('Salvo como rascunho', 'success'))
    }
    yield put(clearComunicacao())
    yield put(getComunicacaoRequest(''))
  } catch (err) {
    yield put(createComunicacaoFailure())
  }
}

export function* handlerGetComunicacaoDetalhes(action: any) {
  const { id } = action.payload

  try {
    const res: any = yield call(api.get, `/backoffice/comunicacoes/${id}`)
    yield put(getUserDetailSuccess(res))
  } catch (err) {
    yield put(getUserDetailFailure())
  }
}

export function* handlerUpdateComunicacao(action: any) {
  const { id, body } = action.payload

  try {
    const res: any = yield call(api.put, `/backoffice/comunicacoes/${id}`, {
      title: body.title,
      subtitle: body.subtitle,
      product: body.products,
      body: body.body,
      link: body.link,
      public: body.public,
      target_public: body.target_public,
      runAt: body.runAt,
    })
    yield put(updateComunicacaoSucess(res))
    yield put(showToastr('Campanha atualizada', 'success'))
    yield put(clearComunicacao())
    yield put(getComunicacaoRequest(''))
  } catch (err: any) {
    yield put(showToastr(err.message, 'error'))
    yield put(updateComunicacaoFailure())
  }
}

/* export function* handlerComunicacaoGetListProducts() {
  try {
    const res: any = yield call(api.get, `/tenants/60d0db2552fcc900beb502d5/products`)
    yield put(getComunicacaoProductSuccess(res))
  } catch (err) {
    yield put(getComunicacaoProductFailure())
  }
} */

// send
export function* handlerSendComunicacao(action: any) {
  const { id, body } = action.payload

  const data = {
    isTest: body,
  }

  try {
    const res: any = yield call(api.post, `/backoffice/comunicacoes/send/${id}`, data)
    yield put(sendComunicacaoSucess(res))
    yield put(showToastr('Notificação enviada', 'success'))
    yield put(getComunicacaoRequest(''))
    yield put(clearComunicacao())
  } catch (err: any) {
    yield put(showToastr(err.message, 'error'))
    yield put(sendComunicacaoFailure())
  }
}

// Busca lista de acordo com a opção escolhida no select - modo do disparo da campanha
export function* handlerGetTypePublicComunicacao(action: any) {
  const { id } = action.payload
  try {
    const res: any = yield call(api.get, `/backoffice/comunicacoes/type-public/${id}`)
    yield put(getComunicacaoTypePublicSuccess(res))
  } catch (err) {
    yield put(getComunicacaoTypePublicFailure())
  }
}

// Deletar a campanha
export function* handlerDeleteComunicacao(action: any) {
  const { body, id } = action.payload

  try {
    const res: any = yield call(api.delete, `/backoffice/comunicacoes/delete/${id}`, { data: { message: body } })
    yield put(deleteComunicacaoSucess(res))
    yield put(showToastr('Operação realizada!', 'success'))
  } catch (err) {
    yield put(deleteComunicacaoFailure())
    yield put(showToastr('Erro ao apagar notificação!', 'error'))
  }
}

export function* handlerListCategories() {
  try {
    const res: any = yield call(api.get, `/backoffice/comunicacoes/categories`)
    yield put(listCategoriesSucess(res))
  } catch (err) {
    yield put(listCategoriesFailure())
  }
}

// Download lista campanhas para CSV

export function* handlerGetDownloadCsvComunicacao() {
  try {
    const res: any = yield call(api.get, `/backoffice/comunicacoes/list?pageSize=9999`)
    yield put(getdownloadCsvComunicacaoSuccess(res))
  } catch (err) {
    yield put(getdownloadCsvComunicacaoFailure())
  }
}

// Filtro pesquisar campanha
export function* handlerFilterCampaign(action: any) {
  const { body } = action.payload
  try {
    const res: any = yield call(api.get, `/backoffice/comunicacoes/list?filterBy={"title":"${body}"}`)
    yield put(searchCampaignSuccess(res))
  } catch (err) {
    yield put(searchCampaignFailure())
  }
}

export function* handlerCampanhaGetList(action: any) {
  const { body } = action.payload

  let users = body.users ?? ''

  if (users) users = encodeURIComponent(users)
  if (body.dateEnd && !body.dateStart) body.dateStart = new Date().toISOString()
  if (body.dateStart && !body.dateEnd) body.dateEnd = new Date().toISOString()
  if (body.ordination && !body.ascOrDesc) body.ascOrDesc = 'desc'

  const bodyDateStart = `"dateStart":"${body.dateStart}",`
  const bodyDateEnd = `"dateEnd":"${body.dateEnd}",`
  const bodyUser = `"user":"${users}",`
  const bodyStatus = `"status":"${body.status}",`
  const bodyCategory = `"category":"${body.category}"`
  const bodyRadio = `&radio=${body.radio}`
  const bodyPage = `&page=${body.page}`
  const bodySort = `&sort={"${body.ordination}":"${body.ascOrDesc}"}`

  let resFilter = `?filterBy={${users ? bodyUser : ''}${body.dateStart ? bodyDateStart : ''}${
    body.dateEnd ? bodyDateEnd : ''
  }${body.status ? bodyStatus : ''}${body.category ? bodyCategory : ''}}${body.radio ? bodyRadio : ''}${
    body.ordination ? bodySort : ''
  }${body.page ? bodyPage : ''}`

  // Tratativas para conversão de Json e tratativas para URL
  resFilter = resFilter.replace(/,}/g, '}')

  try {
    const res: any = yield call(api.get, `/backoffice/campanhas/list${resFilter}`)
    yield put(getCampanhaSuccess(res))
  } catch (err) {
    yield put(getCampanhaFailure())
  }
}

export function* handlerCreateCampanha(action: any) {
  const { body, send, isTest } = action.payload

  try {
    const res: any = yield call(api.post, `/backoffice/campanhas`, body)
    const { uploadImage, uploadThumbnail } = body

    if (uploadImage) {
      const formData = new FormData()
      formData.append('files', uploadImage)
      yield call(api.post, `backoffice/campanhas/${res._id}/upload/image`, formData)
    }

    if (uploadThumbnail) {
      const formData = new FormData()
      formData.append('files', uploadThumbnail)
      yield call(api.post, `backoffice/campanhas/${res._id}/upload/tumbnail`, formData)
    }

    yield put(createCampanhaSuccess(res))

    if (send) {
      yield put(sendCampanhaRequest(res._id, isTest))
    } else {
      yield put(showToastr('Salvo como rascunho', 'success'))
    }
    yield put(clearCampanha())
    yield put(getCampanhaRequest(''))
  } catch (err) {
    yield put(createCampanhaFailure())
  }
}

export function* handlerSendCampanha(action: any) {
  const { id, body } = action.payload

  const data = {
    isTest: body,
  }

  try {
    const res: any = yield call(api.post, `/backoffice/campanhas/send/${id}`, data)
    yield put(sendCampanhaSucess(res))
    yield put(showToastr('Comunicação enviada', 'success'))
    yield put(getCampanhaRequest(''))
    yield put(clearCampanha())
  } catch (err: any) {
    yield put(showToastr(err.message, 'error'))
    yield put(sendCampanhaFailure())
  }
}

export function* handlerGetTypePublicCampanha(action: any) {
  const { id } = action.payload
  try {
    //TODO: Validate
    const res: any = yield call(api.get, `/backoffice/comunicacoes/type-public/${id}`)
    yield put(getCampanhaTypePublicSuccess(res))
  } catch (err) {
    yield put(getCampanhaTypePublicFailure())
  }
}

export function* handlerGetCampanhaDetalhes(action: any) {
  const { id } = action.payload

  try {
    const res: any = yield call(api.get, `/backoffice/campanhas/${id}`)
    yield put(getCampanhaDetailSuccess(res))
  } catch (err) {
    yield put(getCampanhaDetailFailure())
  }
}

export function* handlerUpdateCampanha(action: any) {
  const { id, body } = action.payload

  try {
    const res: any = yield call(api.put, `/backoffice/campanhas/${id}`, {
      title: body.title,
      subtitle: body.subtitle,
      product: body.products,
      body: body.body,
      link: body.link,
      public: body.public,
      target_public: body.target_public,
      runAt: body.runAt,
    })
    const { uploadImage, uploadThumbnail } = body

    if (uploadImage) {
      const formData = new FormData()
      formData.append('files', uploadImage)
      yield call(api.post, `backoffice/campanhas/${id}/upload/image`, formData)
    }

    if (uploadThumbnail) {
      const formData = new FormData()
      formData.append('files', uploadThumbnail)
      yield call(api.post, `backoffice/campanhas/${id}/upload/tumbnail`, formData)
    }
    yield put(updateCampanhaSucess(res))
    yield put(showToastr('Campanha atualizada', 'success'))
    yield put(clearCampanha())
    yield put(getCampanhaRequest(''))
  } catch (err: any) {
    yield put(showToastr(err.message, 'error'))
    yield put(updateCampanhaFailure())
  }
}

export function* handlerDeleteCampanha(action: any) {
  const { body, id } = action.payload

  try {
    const res: any = yield call(api.delete, `/backoffice/campanhas/delete/${id}`, { data: { message: body } })
    yield put(deleteCampanhaSucess(res))
    yield put(showToastr('Operação realizada!', 'success'))
  } catch (err) {
    yield put(deleteCampanhaFailure())
    yield put(showToastr('Erro ao apagar campanha!', 'error'))
  }
}

export function* handlerSpotlightCampanha(action: any) {
  const { id, status } = action.payload
  try {
    yield call(api.put, `/backoffice/campanhas/${id}/spotlight`, { status })
    yield put(spotlightCampanhaSuccess({}))
    yield put(showToastr(status ? 'Campanha adicionada aos destaques!' : 'Campanha retirada dos destaques!', 'success'))
    yield put(getCampanhaRequest(''))
  } catch (err: any) {
    yield put(spotlightCampanhaFailure())
    yield put(showToastr(err.message || 'Erro ao destacar campanha!', 'info'))
  }
}

export function* handlerListTags() {
  try {
    const res: any = yield call(api.get, `/backoffice/campanhas/tags`)
    yield put(listTagsSucess(res))
  } catch (err) {
    yield put(listTagsFailure())
  }
}

export function* handlerFilterCampanha(action: any) {
  const { body } = action.payload
  try {
    const res: any = yield call(api.get, `/backoffice/campanhas/list?filterBy={"title":"${body}"}`)
    yield put(searchCampanhaSuccess(res))
  } catch (err) {
    yield put(searchCampanhaFailure())
  }
}

function* watchSagas(): Generator {
  //yield takeLatest(ComunicacaoTypes.GET_COMUNICACAO_LIST_PRODUCTS_REQUEST, handlerComunicacaoGetListProducts)
  yield takeLatest(ComunicacaoTypes.GET_SEARCH_CAMPAIGN_REQUEST, handlerFilterCampaign)
  yield takeLatest(ComunicacaoTypes.GET_COMUNICACAO_LIST_REQUEST, handlerComunicacaoGetList)
  yield takeLatest(ComunicacaoTypes.GET_COMUNICACAO_DETAIL_REQUEST, handlerGetComunicacaoDetalhes)
  yield takeLatest(ComunicacaoTypes.GET_COMUNICACAO_TYPE_PUBLIC_REQUEST, handlerGetTypePublicComunicacao)
  yield takeLatest(ComunicacaoTypes.GET_DOWNLOAD_CSV_COMUNICACAO_REQUEST, handlerGetDownloadCsvComunicacao)
  yield takeLatest(ComunicacaoTypes.CREATE_COMUNICACAO_REQUEST, handlerCreateComunicacao)
  yield takeLatest(ComunicacaoTypes.UPDATE_COMUNICACAO_REQUEST, handlerUpdateComunicacao)
  yield takeLatest(ComunicacaoTypes.SEND_COMUNICACAO_REQUEST, handlerSendComunicacao)
  yield takeLatest(ComunicacaoTypes.CLEAR_COMUNICACAO, clearComunicacao)
  yield takeLatest(ComunicacaoTypes.DELETE_COMUNICACAO_REQUEST, handlerDeleteComunicacao)
  yield takeLatest(ComunicacaoTypes.LIST_CATEGORIES_REQUEST, handlerListCategories)

  yield takeLatest(ComunicacaoTypes.GET_CAMPANHA_LIST_REQUEST, handlerCampanhaGetList)
  yield takeLatest(ComunicacaoTypes.GET_CAMPANHA_DETAIL_REQUEST, handlerGetCampanhaDetalhes)
  yield takeLatest(ComunicacaoTypes.GET_CAMPANHA_TYPE_PUBLIC_REQUEST, handlerGetTypePublicCampanha)
  yield takeLatest(ComunicacaoTypes.CREATE_CAMPANHA_REQUEST, handlerCreateCampanha)
  yield takeLatest(ComunicacaoTypes.UPDATE_CAMPANHA_REQUEST, handlerUpdateCampanha)
  yield takeLatest(ComunicacaoTypes.SEND_CAMPANHA_REQUEST, handlerSendCampanha)
  yield takeLatest(ComunicacaoTypes.CLEAR_CAMPANHA, clearCampanha)
  yield takeLatest(ComunicacaoTypes.DELETE_CAMPANHA_REQUEST, handlerDeleteCampanha)
  yield takeLatest(ComunicacaoTypes.SPOTLIGHT_CAMPANHA_REQUEST, handlerSpotlightCampanha)
  yield takeLatest(ComunicacaoTypes.LIST_TAGS_REQUEST, handlerListTags)
  yield takeLatest(ComunicacaoTypes.GET_SEARCH_CAMPANHA_REQUEST, handlerFilterCampanha)
}

export default function* comunicacaoSagas() {
  yield all([fork(watchSagas)])
}
