import { getDomainsRequest } from 'store/permissions/actions'
import Api from 'store/utils/api'
import Bifrost from 'store/utils/bifrost'
import { put, takeLatest, all, fork, call } from 'redux-saga/effects'
import { PermissionsTypes } from './types'
import { getPermissionsSuccess, getPermissionsFailure, getPermissionsRequest, getDomainsSuccess } from './actions'

const resource = '/admin/permissions'

export function* handleGetPermissions() {
  try {
    const res: any = yield call(Bifrost.get, `${resource}`)
    yield put(getPermissionsSuccess(res))
  } catch (err) {
    yield put(getPermissionsFailure())
  }
}

export function* handleCreatePermissions(action: any) {
  try {
    yield call(Bifrost.post, `${resource}`, action.payload.data)
    yield put(getPermissionsRequest())
  } catch (err) {
    yield put(getPermissionsFailure())
  }
}

export function* handleUpdatePermissions(action: any) {
  try {
    yield call(Bifrost.put, `${resource}/${action.payload.data._id}`, action.payload.data)
    yield put(getPermissionsRequest())
  } catch (err) {
    yield put(getPermissionsFailure())
  }
}

// DOMAINS
export const resourceDomain = '/admin/domains'

export function* handleGetDomains() {
  try {
    const res: any = yield call(Api.get, `${resourceDomain}`)
    yield put(getDomainsSuccess(res))
  } catch (err) {
    yield put(getPermissionsFailure())
  }
}

export function* handleCreateDomain(action: any) {
  try {
    yield call(Bifrost.post, `${resourceDomain}`, { domain: action.payload.data })
    yield put(getDomainsRequest())
  } catch (err) {
    yield put(getPermissionsFailure())
  }
}

export function* handleUpdateDomain(action: any) {
  try {
    yield call(Bifrost.put, `${resourceDomain}/${action.payload.data._id}`, action.payload.data)
    yield put(getDomainsRequest())
  } catch (err) {
    yield put(getPermissionsFailure())
  }
}

function* watchRequest(): Generator {
  yield takeLatest(PermissionsTypes.GET_PERMISSIONS_REQUEST, handleGetPermissions)
  yield takeLatest(PermissionsTypes.CREATE_PERMISSION_REQUEST, handleCreatePermissions)
  yield takeLatest(PermissionsTypes.UPDATE_PERMISSION_REQUEST, handleUpdatePermissions)

  yield takeLatest(PermissionsTypes.GET_DOMAINS_REQUEST, handleGetDomains)
  yield takeLatest(PermissionsTypes.CREATE_DOMAIN_REQUEST, handleCreateDomain)
  yield takeLatest(PermissionsTypes.UPDATE_DOMAIN_REQUEST, handleUpdateDomain)
}

export default function* permissionsSagas() {
  yield all([fork(watchRequest)])
}
