import apiGrowth from 'store/utils/growth'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { WhatsTypes } from './types'
import { showToastr } from 'store/toastr/actions'
import {
  getWhatsListSuccess,
  getWhatsListFailure,
  getWhatsCreateAccountSuccess,
  getWhatsCreateAccountFailure,
  getWhatsValidateAccountSuccess,
  getWhatsValidateAccountFailure,
  getWhatsAccountSuccess,
  getWhatsAccountFailure,
  getWhatsAccountByIdSuccess,
  getWhatsAccountByIdFailure,
  getWhatsQrCodeSuccess,
  getWhatsQrCodeFailure,
  getWhatsQrCodeStatusSuccess,
  getWhatsQrCodeStatusFailure,
  getWhatsShootByIdSuccess,
  getWhatsShootByIdFailure,
  getWhatsListShootByIdSuccess,
  getWhatsListShootByIdFailure,
  getWhatsQrCodeDeviceSuccess,
  getWhatsQrCodeDeviceFailure,
  getWhatsCancelShootSuccess,
  getWhatsCancelShootFailure,
  getWhatsCreateShootSuccess,
  getWhatsCreateShootFailure,
  getWhatsAccountRequest,
} from './actions'

// Listagem de todos os disparos
export function* listShoot(action: any) {
  try {
    const res: any = yield call(apiGrowth.get, `/api/retencao/disparo`, action.payload)
    yield put(getWhatsListSuccess(res))
  } catch (err) {
    yield put(getWhatsListFailure())
  }
}

//Listagem do todas as contas
export function* listAccount(action: any) {
  try {
    const res: any = yield call(apiGrowth.get, `/api/retencao/contas`, action.payload)
    yield put(getWhatsAccountSuccess(res))
  } catch (error) {
    yield put(getWhatsAccountFailure())
  }
}

// Visualizar informações de uma conta
export function* accountById(action: any) {
  try {
    const id = action.payload.id
    const res: any = yield call(apiGrowth.get, `/api/retencao/contas/${id}`)
    yield put(getWhatsAccountByIdSuccess(res))
  } catch (error) {
    yield put(getWhatsAccountByIdFailure())
  }
}

// Imagem QR CODE
export function* qrCode(action: any) {
  try {
    const id = action.payload.id
    const res: any = yield call(apiGrowth.get, `/api/retencao/qr-code/${id}`)
    yield put(getWhatsQrCodeSuccess(res))
  } catch (error) {
    yield put(getWhatsQrCodeFailure())
  }
}

// Criar uma conta
export function* createAccount(action: any) {
  try {
    const res: any = yield call(apiGrowth.post, `/api/retencao/contas`, action.payload.params)
    yield put(getWhatsCreateAccountSuccess(res))
  } catch (error) {
    yield put(getWhatsCreateAccountFailure())
  }
}

// Validação do ID e Token da instância
export function* validateCreateAccount(action: any) {
  try {
    const res: any = yield call(apiGrowth.post, `/api/retencao/check`, action.payload.params)
    yield put(getWhatsValidateAccountSuccess(res))
  } catch (error) {
    yield put(showToastr('Algo deu errado!', 'error'))
    yield put(getWhatsValidateAccountFailure())
  }
}

// Status de conexão da conta
export function* qrCodeStatus(action: any) {
  try {
    const id = action.payload.id
    const res: any = yield call(apiGrowth.get, `/api/retencao/status/${id}`)
    yield put(getWhatsQrCodeStatusSuccess(res))
  } catch (error) {
    yield put(getWhatsQrCodeStatusFailure())
  }
}

// Visualizar informações de um disparo
export function* shootById(action: any) {
  try {
    const id = action.payload.id
    const res: any = yield call(apiGrowth.get, `/api/retencao/disparo/${id}`)
    yield put(getWhatsShootByIdSuccess(res))
  } catch (error) {
    yield put(getWhatsShootByIdFailure())
  }
}

// Criar um disparo
export function* createShoot(action: any) {
  try {
    const res: any = yield call(apiGrowth.post, `/api/retencao/disparo`, action.payload.params.data)
    yield put(getWhatsCreateShootSuccess(res))
    yield put(showToastr(`Formulário enviado!`, 'success'))
    yield put(getWhatsAccountRequest(action.payload.params.data))

    if (action.payload.params.callback) {
      action.payload.params.callback()
    }
  } catch (error) {
    yield put(showToastr('Algo deu errado!', 'error'))
    yield put(getWhatsCreateShootFailure())
  }
}

//Listagem com todos os disparos de uma lista
export function* listShootById(action: any) {
  const { disparo, limit, numero, pg } = action.payload.params
  try {
    const res: any = yield call(
      apiGrowth.get,
      `https://api.mktademicon.com.br/api/retencao/disparo-lista?pg=${pg}&limit=${limit}&disparo=${disparo}&numero=${numero}`,
    )
    yield put(getWhatsListShootByIdSuccess(res))
  } catch (error) {
    yield put(getWhatsListShootByIdFailure())
  }
}

// Informações da conta que conectada
export function* qrCodeDevice(action: any) {
  try {
    const id = action.payload.id
    const res: any = yield call(apiGrowth.get, `/api/retencao/device/${id}`)
    yield put(getWhatsQrCodeDeviceSuccess(res))
  } catch (error) {
    yield put(getWhatsQrCodeDeviceFailure())
  }
}

// Cancelar disparos
export function* cancelShoot(action: any) {
  try {
    const id = action.payload.id
    const res: any = yield call(apiGrowth.get, `/api/retencao/disparo-cancelar/${id}`)
    yield put(getWhatsCancelShootSuccess(res))
  } catch (error) {
    yield put(getWhatsCancelShootFailure())
  }
}

function* watchSagas(): Generator {
  yield takeLatest(WhatsTypes.GET_WHATS_ACCOUNT_REQUEST, listAccount)
  yield takeLatest(WhatsTypes.GET_WHATS_ACCOUNT_BY_ID_REQUEST, accountById)
  yield takeLatest(WhatsTypes.GET_WHATS_QRCODE_REQUEST, qrCode)
  yield takeLatest(WhatsTypes.GET_WHATS_QRCODE_STATUS_REQUEST, qrCodeStatus)
  yield takeLatest(WhatsTypes.GET_WHATS_QRCODE_DEVICE_REQUEST, qrCodeDevice)
  yield takeLatest(WhatsTypes.GET_WHATS_LIST_REQUEST, listShoot)
  yield takeLatest(WhatsTypes.GET_WHATS_CREATE_SHOOT_REQUEST, createShoot)
  yield takeLatest(WhatsTypes.GET_WHATS_SHOOT_BY_ID_REQUEST, shootById)
  yield takeLatest(WhatsTypes.GET_WHATS_LIST_SHOOT_BY_ID_REQUEST, listShootById)
  yield takeLatest(WhatsTypes.GET_WHATS_CANCEL_SHOOT_REQUEST, cancelShoot)
  yield takeLatest(WhatsTypes.GET_WHATS_CREATE_ACCOUNT_REQUEST, createAccount)
  yield takeLatest(WhatsTypes.GET_WHATS_VALIDATE_ACCOUNT_REQUEST, validateCreateAccount)
}

export default function* previasSagas() {
  yield all([fork(watchSagas)])
}
