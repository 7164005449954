import Pusher from 'pusher-js'

Pusher.logToConsole = process.env.NODE_ENV === 'development' ? false : false

export default () => {
  const key = process.env.REACT_APP_PUSHERKEY ? process.env.REACT_APP_PUSHERKEY : ''
  const pusher = new Pusher(key, { cluster: 'us2' })

  return pusher
}
