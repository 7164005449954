import { makeStyles } from '@material-ui/core'

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isFocused) {
    return '#2196f3'
  }
  return '#eeeeee'
}

export const style = makeStyles(() => ({
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: `${(props) => getColor(props)}`,
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginTop: '1.5rem',
    cursor: 'pointer',
  },
  container: {
    border: '1px solid red',
  },

  dropzoneButton: {
    borderRadius: '1.25rem',
    border: '1px solid #59595B',
    color: '#59595B',
    padding: '0.5rem 1rem',
  },

  dropzoneSubtitle: {
    fontSize: '1rem',
    color: '#6B6B6B',
  },

  fileProgress: {
    borderRadius: '0.25rem',
    border: '1px solid #9E9E9E',
    marginTop: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '1rem',
  },
  fileLoading: {
    padding: '1.3rem',
    borderRight: '1px solid #9E9E9E',
    color: '#B6B6B6',
  },
  fileProgressInfo: {
    display: 'flex',
    color: '#B6B6B6',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingRight: '1rem',
  },
  fileProgressCheck: {
    width: 30,
    height: 30,
    background: '#a2e8ca',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
