export const ModulesConfigTypes = {
  GET_MODULES_REQUEST: '@@modulesconfig/GET_MODULES_REQUEST',
  GET_MODULES_SUCCESS: '@@modulesconfig/GET_MODULES_SUCCESS',
  GET_MODULES_FAILURE: '@@modulesconfig/GET_MODULES_FAILURE',

  GET_MODULE_BYID_REQUEST: '@@modulesconfig/GET_MODULE_BYID_REQUEST',
  GET_MODULE_BYID_SUCCESS: '@@modulesconfig/GET_MODULE_BYID_SUCCESS',
  GET_MODULE_BYID_FAILURE: '@@modulesconfig/GET_MODULE_BYID_FAILURE',

  SAVE_MODULE_REQUEST: '@@modulesconfig/SAVE_MODULE_REQUEST',
  CREATE_MODULE_REQUEST: '@@modulesconfig/CREATE_MODULE_REQUEST',
  SAVE_MODULE_MENU_REQUEST: '@@modulesconfig/SAVE_MODULE_MENU_REQUEST',
  DELETE_MODULE_MENU_REQUEST: '@@modulesconfig/DELETE_MODULE_MENU_REQUEST',
  UPDATE_MODULE_MENU_REQUEST: '@@modulesconfig/UPDATE_MODULE_MENU_REQUEST',

  DELETE_MODULE_REQUEST: '@@modulesconfig/DELETE_MODULE_REQUEST',
  DELETE_MODULE_SUCCESS: '@@modulesconfig/DELETE_MODULE_SUCCESS',
  DELETE_MODULE_FAILURE: '@@modulesconfig/DELETE_MODULE_FAILURE',

  RESET_MODULE_DETAIL: '@@modulesconfig/RESET_MODULE_DETAIL',
}

export interface IMenu {
  _id: string | undefined
  name: string
  path: string
  type: 'internal' | 'external' | string
  iframe?: IiFrameMenu
  module: string
  permissions: string[]
}

export interface IModule {
  _id: string
  enabled: boolean
  name: string
  icon: string
  menus: IMenu[]
}

export interface IiFrameMenu {
  url: string
  name: string
  params?: {
    [key: string]: string
  }
}

export interface IModulesConfigState {
  data: IModule[]
  detail: IModule
  loading: boolean
  error: string
}
