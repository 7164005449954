import { IComunicacaoState, ComunicacaoTypes } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: IComunicacaoState = {
  list: {
    loading: false,
    data: [],
    error: '',
  },
  details: {
    loading: false,
    data: {},
    error: '',
  },
  typePublic: {
    loading: false,
    data: {},
    error: '',
  },
  categoriesState: {
    loading: false,
    data: [],
  },
  /*   products: {
    loading: false,
    data: [],
    error: '',
  }, */
  new: {
    loading: false,
    data: {},
    error: '',
  },
  uploadImage: {
    loading: false,
    loadingSend: false,
    data: [],
    error: '',
  },
  delete: {
    loading: false,
    data: {},
    error: '',
  },
  downloadCsv: {
    loadingCsv: false,
    data: [],
    error: '',
  },
  filterComunicacao: {
    loading: false,
    data: [],
    error: '',
  },
  filterModalComunicacao: {
    loading: false,
    data: [],
    error: '',
  },
  campanhas: {
    loading: false,
    data: [],
    error: '',
  },
  campanhaDetail: {
    loading: false,
    data: {},
    error: '',
  },
  campanhaCreate: {
    loading: false,
    loadingSend: false,
    data: [],
    error: '',
  },
  campanhaDelete: {
    loading: false,
    data: {},
    error: '',
  },
  campaignSpotlight: {
    loading: false,
  },
  tags: {
    list: [],
    loading: false,
  },
  filterCampanha: {
    loading: false,
    data: [],
    error: '',
  },
  filterModalCampanha: {
    loading: false,
    data: [],
    error: '',
  },
}

export const comunicacaoReducer = (
  state: IComunicacaoState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IComunicacaoState => {
  switch (action.type) {
    case ComunicacaoTypes.GET_COMUNICACAO_LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      }
    case ComunicacaoTypes.GET_COMUNICACAO_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          loading: false,
        },
      }
    case ComunicacaoTypes.GET_COMUNICACAO_LIST_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case ComunicacaoTypes.GET_COMUNICACAO_DETAIL_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          loading: true,
        },
      }

    case ComunicacaoTypes.GET_COMUNICACAO_DETAIL_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          data: action.payload.data,
          loading: false,
        },
      }
    case ComunicacaoTypes.GET_COMUNICACAO_DETAIL_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          data: {},
          error: 'Falha ao carregar dados',
          loading: true,
        },
      }
    case ComunicacaoTypes.GET_COMUNICACAO_TYPE_PUBLIC_REQUEST:
      return {
        ...state,
        typePublic: {
          ...state.typePublic,
          loading: true,
        },
      }
    case ComunicacaoTypes.LIST_CATEGORIES_REQUEST:
      return {
        ...state,
        categoriesState: {
          ...state.categoriesState,
          loading: true,
        },
      }
    case ComunicacaoTypes.LIST_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesState: {
          ...state.categoriesState,
          loading: false,
          data: action.payload.data,
        },
      }
    case ComunicacaoTypes.LIST_CATEGORIES_FAILURE:
      return {
        ...state,
        categoriesState: {
          ...state.categoriesState,
          loading: false,
        },
      }
    case ComunicacaoTypes.GET_COMUNICACAO_TYPE_PUBLIC_SUCCESS:
      return {
        ...state,
        typePublic: {
          ...state.typePublic,
          data: action.payload.data,
          loading: false,
        },
      }
    case ComunicacaoTypes.GET_COMUNICACAO_TYPE_PUBLIC_FAILURE:
      return {
        ...state,
        typePublic: {
          ...state.typePublic,
          data: {},
          error: 'Falha ao carregar dados',
          loading: true,
        },
      }
    /*     case ComunicacaoTypes.GET_COMUNICACAO_LIST_PRODUCTS_REQUEST:
      return {
        ...state,
        products: {
          ...state.products,
          loading: true,
        },
      }
    case ComunicacaoTypes.GET_COMUNICACAO_LIST_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          data: action.payload.data,
          loading: false,
        },
      }
    case ComunicacaoTypes.GET_COMUNICACAO_LIST_PRODUCTS_FAILURE:
      return {
        ...state,
        products: {
          ...state.products,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      } */
    case ComunicacaoTypes.CREATE_COMUNICACAO_REQUEST:
      return {
        ...state,
        uploadImage: {
          ...state.uploadImage,
          loading: true,
          loadingSend: false,
        },
      }
    case ComunicacaoTypes.CREATE_COMUNICACAO_SUCCESS:
      return {
        ...state,
        uploadImage: {
          ...state.uploadImage,
          data: action.payload.data,
          loading: false,
          loadingSend: true,
        },
      }
    case ComunicacaoTypes.CREATE_COMUNICACAO_FAILURE:
      return {
        ...state,
        uploadImage: {
          ...state.uploadImage,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case ComunicacaoTypes.SEND_COMUNICACAO_REQUEST:
      return {
        ...state,
        uploadImage: {
          ...state.uploadImage,
          loadingSend: false,
          loading: true,
        },
      }
    case ComunicacaoTypes.SEND_COMUNICACAO_SUCCESS:
      return {
        ...state,
        uploadImage: {
          ...state.uploadImage,
          data: action.payload.data,
          loadingSend: true,
          loading: false,
        },
      }
    case ComunicacaoTypes.SEND_COMUNICACAO_FAILURE:
      return {
        ...state,
        uploadImage: {
          ...state.uploadImage,
          data: [],
          error: 'Falha ao carregar dados',
          loadingSend: false,
        },
      }
    case ComunicacaoTypes.CLEAR_COMUNICACAO:
      return {
        ...state,
        uploadImage: {
          ...state.uploadImage,
          loading: false,
          loadingSend: false,
        },
      }
    case ComunicacaoTypes.DELETE_COMUNICACAO_REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: true,
        },
      }
    case ComunicacaoTypes.DELETE_COMUNICACAO_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          data: action.payload.data,
          loading: false,
        },
      }

    case ComunicacaoTypes.GET_DOWNLOAD_CSV_COMUNICACAO_REQUEST:
      return {
        ...state,
        downloadCsv: {
          ...state.downloadCsv,
          loadingCsv: true,
        },
      }
    case ComunicacaoTypes.GET_DOWNLOAD_CSV_COMUNICACAO_SUCCESS:
      return {
        ...state,
        downloadCsv: {
          ...state.downloadCsv,
          data: action.payload.data,
          loadingCsv: false,
        },
      }
    case ComunicacaoTypes.GET_DOWNLOAD_CSV_COMUNICACAO_FAILURE:
      return {
        ...state,
        downloadCsv: {
          ...state.downloadCsv,
          data: [],
          error: 'Falha ao carregar dados',
        },
      }

    case ComunicacaoTypes.GET_SEARCH_CAMPAIGN_REQUEST:
      return {
        ...state,
        filterComunicacao: {
          ...state.filterComunicacao,
          loading: true,
        },
      }
    case ComunicacaoTypes.GET_SEARCH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        filterComunicacao: {
          ...state.filterComunicacao,
          data: action.payload.data,
          loading: false,
        },
      }
    case ComunicacaoTypes.GET_SEARCH_CAMPAIGN_FAILURE:
      return {
        ...state,
        filterComunicacao: {
          ...state.filterComunicacao,
          data: [],
          error: 'Falha ao carregar dados',
        },
      }

    case ComunicacaoTypes.GET_MODAL_FILTERS_REQUEST:
      return {
        ...state,
        filterModalComunicacao: {
          ...state.filterModalComunicacao,
          loading: true,
        },
      }
    case ComunicacaoTypes.GET_MODAL_FILTERS_SUCCESS:
      return {
        ...state,
        filterModalComunicacao: {
          ...state.filterModalComunicacao,
          data: action.payload.data,
          loading: false,
        },
      }
    case ComunicacaoTypes.GET_MODAL_FILTERS_FAILURE:
      return {
        ...state,
        filterModalComunicacao: {
          ...state.filterModalComunicacao,
          data: [],
          error: 'Falha ao carregar dados',
        },
      }
    case ComunicacaoTypes.GET_CAMPANHA_LIST_REQUEST:
      return {
        ...state,
        campanhas: {
          ...state.campanhas,
          loading: true,
        },
      }
    case ComunicacaoTypes.GET_CAMPANHA_LIST_SUCCESS:
      return {
        ...state,
        campanhas: {
          ...state.campanhas,
          data: action.payload.data,
          loading: false,
        },
      }
    case ComunicacaoTypes.GET_CAMPANHA_LIST_FAILURE:
      return {
        ...state,
        campanhas: {
          ...state.campanhas,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case ComunicacaoTypes.GET_CAMPANHA_DETAIL_REQUEST:
      return {
        ...state,
        campanhaDetail: {
          ...state.campanhaDetail,
          loading: true,
        },
      }
    case ComunicacaoTypes.GET_CAMPANHA_DETAIL_SUCCESS:
      return {
        ...state,
        campanhaDetail: {
          ...state.campanhaDetail,
          data: action.payload.data,
          loading: false,
        },
      }
    case ComunicacaoTypes.GET_CAMPANHA_DETAIL_FAILURE:
      return {
        ...state,
        campanhaDetail: {
          ...state.campanhaDetail,
          data: {},
          error: 'Falha ao carregar dados',
          loading: true,
        },
      }
    case ComunicacaoTypes.CREATE_CAMPANHA_REQUEST:
      return {
        ...state,
        campanhaCreate: {
          ...state.campanhaCreate,
          loading: true,
          loadingSend: false,
        },
      }
    case ComunicacaoTypes.CREATE_CAMPANHA_SUCCESS:
      return {
        ...state,
        campanhaCreate: {
          ...state.campanhaCreate,
          data: action.payload.data,
          loading: false,
          loadingSend: true,
        },
      }
    case ComunicacaoTypes.CREATE_CAMPANHA_FAILURE:
      return {
        ...state,
        campanhaCreate: {
          ...state.campanhaCreate,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case ComunicacaoTypes.CLEAR_CAMPANHA:
      return {
        ...state,
        campanhaCreate: {
          ...state.campanhaCreate,
          loading: false,
          loadingSend: false,
        },
      }
    case ComunicacaoTypes.SEND_CAMPANHA_REQUEST:
      return {
        ...state,
        campanhaCreate: {
          ...state.campanhaCreate,
          loadingSend: false,
          loading: true,
        },
      }
    case ComunicacaoTypes.SEND_CAMPANHA_SUCCESS:
      return {
        ...state,
        campanhaCreate: {
          ...state.campanhaCreate,
          data: action.payload.data,
          loadingSend: true,
          loading: false,
        },
      }
    case ComunicacaoTypes.SEND_CAMPANHA_FAILURE:
      return {
        ...state,
        campanhaCreate: {
          ...state.campanhaCreate,
          data: [],
          error: 'Falha ao carregar dados',
          loadingSend: false,
        },
      }
    case ComunicacaoTypes.DELETE_CAMPANHA_REQUEST:
      return {
        ...state,
        campanhaDelete: {
          ...state.campanhaDelete,
          loading: true,
        },
      }
    case ComunicacaoTypes.DELETE_CAMPANHA_SUCCESS:
      return {
        ...state,
        campanhaDelete: {
          ...state.campanhaDelete,
          data: action.payload.data,
          loading: false,
        },
      }
    case ComunicacaoTypes.SPOTLIGHT_CAMPANHA_REQUEST:
      return {
        ...state,
        campaignSpotlight: {
          ...state.campaignSpotlight,
          loading: true,
        },
      }
    case ComunicacaoTypes.SPOTLIGHT_CAMPANHA_SUCCESS:
    case ComunicacaoTypes.SPOTLIGHT_CAMPANHA_FAILURE:
      return {
        ...state,
        campaignSpotlight: {
          ...state.campaignSpotlight,
          loading: false,
        },
      }
    case ComunicacaoTypes.LIST_TAGS_REQUEST:
      return {
        ...state,
        tags: {
          ...state.tags,
          loading: true,
        },
      }
    case ComunicacaoTypes.LIST_TAGS_SUCCESS:
      return {
        ...state,
        tags: {
          loading: false,
          list: action.payload.data,
        },
      }
    case ComunicacaoTypes.LIST_TAGS_FAILURE:
      return {
        ...state,
        tags: {
          ...state.tags,
          loading: false,
        },
      }
    case ComunicacaoTypes.GET_SEARCH_CAMPANHA_REQUEST:
      return {
        ...state,
        filterCampanha: {
          ...state.filterCampanha,
          loading: true,
        },
      }
    case ComunicacaoTypes.GET_SEARCH_CAMPANHA_SUCCESS:
      return {
        ...state,
        filterCampanha: {
          ...state.filterCampanha,
          data: action.payload.data,
          loading: false,
        },
      }
    case ComunicacaoTypes.GET_SEARCH_CAMPANHA_FAILURE:
      return {
        ...state,
        filterCampanha: {
          ...state.filterCampanha,
          data: [],
          error: 'Falha ao carregar dados',
        },
      }

    case ComunicacaoTypes.GET_MODAL_FILTERS_CAMPANHA_REQUEST:
      return {
        ...state,
        filterModalCampanha: {
          ...state.filterModalCampanha,
          loading: true,
        },
      }
    case ComunicacaoTypes.GET_MODAL_FILTERS_CAMPANHA_SUCCESS:
      return {
        ...state,
        filterModalCampanha: {
          ...state.filterModalCampanha,
          data: action.payload.data,
          loading: false,
        },
      }
    case ComunicacaoTypes.GET_MODAL_FILTERS_CAMPANHA_FAILURE:
      return {
        ...state,
        filterModalCampanha: {
          ...state.filterModalCampanha,
          data: [],
          error: 'Falha ao carregar dados',
        },
      }
    default:
      return state
  }
}
