import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'
import { Store, applyMiddleware, createStore, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { rootReducer } from './rootReducer'
import { rootSaga } from './rootSaga'
import { IUserState } from './user/types'
import { IModulesConfigState } from './modules_config/types'
import { IPermissionsState } from './permissions/types'
import { IToastrState } from './toastr/types'
import { IAuthState } from './auth/types'
import { INegotiationsState } from './repasse/negotiations/types'
import { INotificationState } from './notification/types'
import { IRolesState } from './roles/types'
import { IPreviasState } from './previas/types'
import { IRecorrenciaState } from './recorrencia/types'
import { IRetencaoState } from './inadimplencia/types'
import { IAntecipayState } from './antecipay/types'
import { IComunicacaoState } from './comunicacao/types'
import { IAssemblyState } from './assembly/types'
import { IInsuranceState } from './insurance/types'
import { IInsurancesSalesConstantsState } from './insuranceSaleConsultants/types'
import { IWhatsAppState } from './whats_app/types'
import { ILpUnityState } from './lp_unity/types'
import { ICaasState } from './caas_digital/types'
import { ICotaEquityState } from './cota-equity/types'
import { ISalesMachineState } from './sales_machine/types'
import { IVersionAppState } from './versioningApp/types'
import { IPostSalesState } from './post_sales/types'
import { IAssemblyMotorsState } from './assembly/motors/types'
import { IComtemplayState } from './contemplay/types'

const sagaMiddleware = createSagaMiddleware()
export const history = createBrowserHistory()

export interface IApplicationState {
  user: IUserState
  modulesConfig: IModulesConfigState
  permissions: IPermissionsState
  roles: IRolesState
  router: any
  auth: IAuthState
  toastr: IToastrState
  negotiations: INegotiationsState
  notifications: INotificationState
  previas: IPreviasState
  retencao: IRetencaoState
  antecipay: IAntecipayState
  comunicacao: IComunicacaoState
  assembly: IAssemblyState
  assemblyMotors: IAssemblyMotorsState
  insurance: IInsuranceState
  insuranceSales: IInsurancesSalesConstantsState
  whatsApp: IWhatsAppState
  lpUnity: ILpUnityState
  caasDigital: ICaasState
  cotaEquity: ICotaEquityState
  salesMachine: ISalesMachineState
  versionApp: IVersionAppState
  postSales: IPostSalesState
  recorrencia: IRecorrenciaState
  contemplay: IComtemplayState
}

export default function configureStore(initialState: IApplicationState, showLogger = true): Store<IApplicationState> {
  const includeLogger = showLogger && process.env.NODE_ENV === 'development' ? [logger] : []
  const middlewares = [sagaMiddleware, routerMiddleware(history), ...includeLogger]
  const store = createStore(rootReducer(history), initialState, compose(applyMiddleware(...middlewares)))
  sagaMiddleware.run(rootSaga)
  return store
}
