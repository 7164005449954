import { action } from 'typesafe-actions'
import { VersionAppTypes } from './types'

export const getVersionAvaRequest = (params: any) => action(VersionAppTypes.GET_VERSION_APP_REQUEST, { params })
export const getVersionAvaSuccess = (res: any) => action(VersionAppTypes.GET_VERSION_APP_SUCCESS, { res })
export const getVersionAvaFailure = () => action(VersionAppTypes.GET_VERSION_APP_FAILURE)

export const getLastVersionAvaRequest = (params: any) =>
  action(VersionAppTypes.GET_LAST_VERSION_APP_REQUEST, { params })
export const getLastVersionAvaSuccess = (res: any) => action(VersionAppTypes.GET_LAST_VERSION_APP_SUCCESS, { res })
export const getLastVersionAvaFailure = () => action(VersionAppTypes.GET_LAST_VERSION_APP_FAILURE)

export const postVersionAvaRequest = (data: any, params: any) =>
  action(VersionAppTypes.POST_VERSION_APP_REQUEST, { data, params })
export const postVersionAvaSuccess = (res: any) => action(VersionAppTypes.POST_VERSION_APP_SUCCESS, { res })
export const postVersionAvaFailure = () => action(VersionAppTypes.POST_VERSION_APP_FAILURE)
