import { action } from 'typesafe-actions'
import { IDomain, IPermission, IPermissionRequest, PermissionsTypes } from './types'

export const getPermissionsRequest = () => action(PermissionsTypes.GET_PERMISSIONS_REQUEST)
export const getPermissionsSuccess = (data: IPermission[]) => action(PermissionsTypes.GET_PERMISSIONS_SUCCESS, { data })
export const getPermissionsFailure = () => action(PermissionsTypes.GET_PERMISSIONS_FAILURE)

export const createPermissionRequest = (data: IPermissionRequest) =>
  action(PermissionsTypes.CREATE_PERMISSION_REQUEST, { data })

export const updatePermissionRequest = (data: IPermission) =>
  action(PermissionsTypes.UPDATE_PERMISSION_REQUEST, { data })

export const getDomainsRequest = () => action(PermissionsTypes.GET_DOMAINS_REQUEST)
export const getDomainsSuccess = (data: IDomain[]) => action(PermissionsTypes.GET_DOMAINS_SUCCESS, { data })
export const createDomainRequest = (data: string) => action(PermissionsTypes.CREATE_DOMAIN_REQUEST, { data })
export const updateDomainRequest = (data: IDomain) => action(PermissionsTypes.UPDATE_DOMAIN_REQUEST, { data })
