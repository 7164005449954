import { action } from 'typesafe-actions'
import { INegotiation, NegotiationsTypes, INegotiationDetail, IResendEmailRequest } from './types'

export const getAllNegotiationsRequest = (filter: string) => action(NegotiationsTypes.GET_ALL_REQUEST, { filter })
export const getAllNegotiationsSuccess = (data: INegotiation[]) => action(NegotiationsTypes.GET_ALL_SUCCESS, { data })
export const getAllNegotiationsError = () => action(NegotiationsTypes.GET_ALL_FAILURE)

export const getDetailNegotiationsRequest = (id: string) => action(NegotiationsTypes.GET_DETAIL_REQUEST, { id })
export const getDetailNegotiationsSuccess = (data: INegotiationDetail) =>
  action(NegotiationsTypes.GET_DETAIL_SUCCESS, { data })
export const getDetailNegotiationsError = () => action(NegotiationsTypes.GET_DETAIL_FAILURE)

export const acceptNegotiationRequest = (id: string) => action(NegotiationsTypes.POST_ACCEPT_REQUEST, { id })
export const acceptNegotiationSuccess = () => action(NegotiationsTypes.POST_ACCEPT_SUCCESS)
export const acceptNegotiationFailure = () => action(NegotiationsTypes.POST_ACCEPT_FAILURE)

export const rejectNegotiationRequest = (id: string) => action(NegotiationsTypes.POST_REJECT_REQUEST, { id })
export const rejectNegotiationSuccess = () => action(NegotiationsTypes.POST_REJECT_SUCCESS)
export const rejectNegotiationFailure = () => action(NegotiationsTypes.POST_REJECT_FAILURE)

export const waitingPaymentNegotiationRequest = (id: string) =>
  action(NegotiationsTypes.POST_WAITING_PAYMENT_REQUEST, { id })
export const waitingPaymentNegotiationSuccess = () => action(NegotiationsTypes.POST_WAITING_PAYMENT_SUCCESS)
export const waitingPaymentNegotiationFailure = () => action(NegotiationsTypes.POST_WAITING_PAYMENT_FAILURE)

export const confirmPaymentRequest = (id: string, reason: string) =>
  action(NegotiationsTypes.POST_PAYMENT_CONFIRMATION_REQUEST, { id, reason })
export const confirmPaymentSuccess = () => action(NegotiationsTypes.POST_PAYMENT_CONFIRMATION_SUCCESS)
export const confirmPaymentFailure = () => action(NegotiationsTypes.POST_PAYMENT_CONFIRMATION_FAILURE)

export const rejectPaymentRequest = (id: string, reason: string) =>
  action(NegotiationsTypes.POST_PAYMENT_REJECT_REQUEST, { id, reason })
export const rejectPaymentSuccess = () => action(NegotiationsTypes.POST_PAYMENT_REJECT_SUCCESS)
export const rejectPaymentFailure = () => action(NegotiationsTypes.POST_PAYMENT_REJECT_FAILURE)

export const resendEmailRequest = ({ id, email }: IResendEmailRequest) =>
  action(NegotiationsTypes.RESEND_EMAIL_REQUEST, { id, email })
export const resendEmailSuccess = () => action(NegotiationsTypes.RESEND_EMAIL_SUCCESS)
export const resendEmailFailure = () => action(NegotiationsTypes.RESEND_EMAIL_FAILURE)

export const updateNegotiationRequest = (data: INegotiationDetail) =>
  action(NegotiationsTypes.POST_NEGOTIATION_REQUEST, { data })
export const updateNegotiationSuccess = () => action(NegotiationsTypes.POST_NEGOTIATION_SUCCESS)
export const updateNegotiationFailure = () => action(NegotiationsTypes.POST_NEGOTIATION_FAILURE)

export const resetNegotiationDetail = () => action(NegotiationsTypes.RESET_DETAIL)
