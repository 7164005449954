import { action } from 'typesafe-actions'
import { InsuranceSalesConsultantsTypes } from './types'

export const getInsuranceListSalesConsultantsRequest = (param) =>
  action(InsuranceSalesConsultantsTypes.GET_INSURANCE_SALES_CONSTANTS_REQUEST, { param })
export const getInsuranceListSalesConsultantsSuccess = (data: any) =>
  action(InsuranceSalesConsultantsTypes.GET_INSURANCE_SALES_CONSTANTS_SUCCESS, { data })
export const getInsuranceListSalesConsultantsFailure = () =>
  action(InsuranceSalesConsultantsTypes.GET_INSURANCE_SALES_CONSTANTS_FAILURE)

export const getInsuranceDetailsSalesConsultantsRequest = (param) =>
  action(InsuranceSalesConsultantsTypes.GET_INSURANCE_SALES_CONSTANTS_DETAILS_REQUEST, { param })
export const getInsuranceDetailsSalesConsultantsSuccess = (data: any) =>
  action(InsuranceSalesConsultantsTypes.GET_INSURANCE_SALES_CONSTANTS_DETAILS_SUCCESS, { data })
export const getInsuranceDetailsSalesConsultantsFailure = () =>
  action(InsuranceSalesConsultantsTypes.GET_INSURANCE_SALES_CONSTANTS_DETAILS_FAILURE)

export const postInsuranceSalesConsultantsRequest = (param) =>
  action(InsuranceSalesConsultantsTypes.POST_INSURANCE_SALES_CONSTANTS_REQUEST, { param })
export const postInsuranceSalesConsultantsSuccess = (data: any) =>
  action(InsuranceSalesConsultantsTypes.POST_INSURANCE_SALES_CONSTANTS_SUCCESS, { data })
export const postInsuranceSalesConsultantsFailure = () =>
  action(InsuranceSalesConsultantsTypes.POST_INSURANCE_SALES_CONSTANTS_FAILURE)
