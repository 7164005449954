export const SalesMachineTypes = {
  GET_SALES_MACHINE_REQUEST: '@@SALES_MACHINE/GET_SALES_MACHINE_REQUEST',
  GET_SALES_MACHINE_SUCCESS: '@@SALES_MACHINE/GET_SALES_MACHINE_SUCCESS',
  GET_SALES_MACHINE_FAILURE: '@@SALES_MACHINE/GET_SALES_MACHINE_FAILURE',

  GET_SALES_QR_CODE_MACHINE_REQUEST: '@@SALES_MACHINE/GET_SALES_QR_CODE_MACHINE_REQUEST',
  GET_SALES_QR_CODE_MACHINE_SUCCESS: '@@SALES_MACHINE/GET_SALES_QR_CODE_MACHINE_SUCCESS',
  GET_SALES_QR_CODE_MACHINE_FAILURE: '@@SALES_MACHINE/GET_SALES_QR_CODE_MACHINE_FAILURE',
}

export interface ISalesMachineState {
  salesMachine: Record<string, any>
  restoreQRCoce: Record<string, any>
}
