export const VersionAppTypes = {
  GET_VERSION_APP_REQUEST: '@@VERSION_APP/GET_VERSION_APP_REQUEST',
  GET_VERSION_APP_SUCCESS: '@@VERSION_APP/GET_VERSION_APP_SUCCESS',
  GET_VERSION_APP_FAILURE: '@@VERSION_APP/GET_VERSION_APP_FAILURE',

  GET_LAST_VERSION_APP_REQUEST: '@@VERSION_APP/GET_LAST_VERSION_APP_REQUEST',
  GET_LAST_VERSION_APP_SUCCESS: '@@VERSION_APP/GET_LAST_VERSION_APP_SUCCESS',
  GET_LAST_VERSION_APP_FAILURE: '@@VERSION_APP/GET_LAST_VERSION_APP_FAILURE',

  POST_VERSION_APP_REQUEST: '@@VERSION_APP/POST_VERSION_APP_REQUEST',
  POST_VERSION_APP_SUCCESS: '@@VERSION_APP/POST_VERSION_APP_SUCCESS',
  POST_VERSION_APP_FAILURE: '@@VERSION_APP/POST_VERSION_APP_FAILURE',
}

export interface IVersionAppState {
  list: Record<string, any>
  lastVersion: Record<string, any>
}
