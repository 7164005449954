import { CotaEquityTypes, ICotaEquityState } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: ICotaEquityState = {
  verification: {
    data: [],
    error: '',
  },
}

export const cotaEquityReducer = (
  state: ICotaEquityState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): ICotaEquityState => {
  switch (action.type) {
    case CotaEquityTypes.GET_COTA_EQUITY_SUCCESS:
      return {
        ...state,
        verification: action.payload,
      }

    default:
      return state
  }
}
