import { LpUnityTypes, ILpUnityState } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: ILpUnityState = {
  list: {
    loading: false,
    data: [],
    error: '',
    pg: 0,
    pgs: 0,
  },
  details: {
    loading: false,
  },
  create: {
    loading: false,
    data: [],
    error: '',
    pg: 0,
    pgs: 0,
  },
  update: {
    loading: false,
    data: [],
    error: '',
  },
}

export const lpUnityReducer = (
  state: ILpUnityState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): ILpUnityState => {
  switch (action.type) {
    case LpUnityTypes.GET_LP_UNITY_SUCCESS:
      return {
        ...state,
        list: action.payload,
      }
    case LpUnityTypes.GET_LP_UNITY_DETAILS_REQUEST:
      return {
        ...state,
        details: {
          loading: false,
        },
      }

    case LpUnityTypes.GET_LP_UNITY_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload,
      }

    case LpUnityTypes.GET_LP_UNITY_CREATE_SUCCESS:
      return {
        ...state,
        create: action.payload.res,
      }

    case LpUnityTypes.GET_LP_UNITY_CREATE_FAILURE:
      return {
        ...state,
        create: action.payload.res,
      }

    case LpUnityTypes.PUT_LP_UNITY_SUCCESS:
      return {
        ...state,
        update: action.payload,
      }

    default:
      return state
  }
}
