import { PermissionsTypes, IPermissionsState } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: IPermissionsState = {
  data: [],
  domains: [],
  loading: false,
  error: '',
}

export const permissionsReducer = (
  state: IPermissionsState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IPermissionsState => {
  switch (action.type) {
    case PermissionsTypes.GET_PERMISSIONS_REQUEST:
    case PermissionsTypes.GET_DOMAINS_REQUEST:
    case PermissionsTypes.CREATE_PERMISSION_REQUEST:
    case PermissionsTypes.UPDATE_PERMISSION_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case PermissionsTypes.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      }
    case PermissionsTypes.GET_PERMISSIONS_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: 'Erro ao obter dados.',
      }
    case PermissionsTypes.GET_DOMAINS_SUCCESS:
      return {
        ...state,
        loading: false,
        domains: action.payload.data,
      }
    default:
      return state
  }
}
