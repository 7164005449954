import React from 'react'
import { IconButton, Badge } from '@material-ui/core'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Styled from './NavbarNotification.styled'
import Notifications from 'containers/Notifications'
import { useSelector } from 'react-redux'
import { IApplicationState } from 'store'

const NavbarNotification = () => {
  const [open, setOpen] = React.useState(false)
  const { notifications } = useSelector((state: IApplicationState) => ({
    notifications: state.notifications.data,
  }))

  return (
    <div style={{ position: 'relative' }}>
      <IconButton color="inherit" onClick={() => setOpen(!open)}>
        <Badge badgeContent={notifications.length} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <NoticationMenu open={open}>
        <Notifications />
      </NoticationMenu>
    </div>
  )
}

const NoticationMenu = ({ open, children }: any) => {
  return <Styled.Wrap open={open}>{children}</Styled.Wrap>
}

export default NavbarNotification
