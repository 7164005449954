import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { PostSalesType } from './types'
import  AxiosDL  from '../utils/dl'
// import { action } from 'typesafe-actions';


import {
  getPostSaleSuccess,
  getPostSaleFailure,
  getBlacklistFailure,
  getBlacklistSuccess,
  setBlacklistSuccess,
  setBlacklistFailure,
  removeBlacklistSuccess,
  getPersonSuccess,
  getPersonFailure,
  getConversationSuccess,
  getConversationFailure,
  exportPostSaleListSuccess,
  getDashboardSuccess
} from './actions'

export function* getPostSale(action: any) {
  try {
    let pg = (action.payload?.pg) ? action.payload.pg : 1  
    const { name, cpf_cnpj, grupo, cota } = action.payload || {};
    const res: any = yield call(AxiosDL.get, `resources/after-sale?pg=${pg}&name=${name}&cpf_cnpj=${cpf_cnpj}&grupo=${grupo}&cota=${cota}`)
    yield put(getPostSaleSuccess(res))
  } catch (err) {
    yield put(getPostSaleFailure({}))
  }
}

export function* exportPostSale(action: any) {
  try {
    const res: any = yield call(AxiosDL.get, `resources/export-after-sale`)
    yield put(exportPostSaleListSuccess(res))
  } catch (err) {
    console.log('err', err)
  }
}

export function* getBlacklist(action: any) {
  try { 
    let {pg, pgs} = action.payload.params
    const res: any = yield call(AxiosDL.get, `resources/get-blacklist?pg=${pg}&limit=${pgs}`)
    yield put(getBlacklistSuccess(res))
  } catch (err) {
    yield put(getBlacklistFailure({}))
  }
}

export function* getPerson(action: any) {
  try {
    let id = action.payload.params
    const res: any = yield call(AxiosDL.get, `person/get-by-id?id=${id}`)
    yield put(getPersonSuccess(res))
  } catch (err) {
    yield put(getPersonFailure({}))
  }
}

export function* getConversation(action: any) {
  try {
    let id = action.payload.params
    const res = yield call(AxiosDL.get, `person/get-conversations?sid=${id}`)
    yield put(getConversationSuccess(res))
  } catch (err) {
    yield put(getConversationFailure({}))
  }
}

export function* setBlacklist(values: any) {
const {phone} = values.payload.data
  console.log('sagas',values.payload.data)
  try {
    const res: any = yield call(AxiosDL.post, `resources/blacklist`, {phone})
    yield put(setBlacklistSuccess(res))
    yield put(getBlacklistSuccess(res));

  } catch (err) {
    yield put(setBlacklistFailure({}))
  }
}
export function* removeBlacklist(action: any) {
  try {
    const { phone } = action.payload.data;
    const res: any = yield call(AxiosDL.delete, `resources/blacklist/${phone}`);
    yield put(removeBlacklistSuccess(res));
    yield put(getBlacklistSuccess(res));
  } catch (err) {
    console.log(err)
  }
}

export function* getDashboard(action: any) {
  try {
    const month = action.payload
    const res: any = yield call(AxiosDL.get, `resources/dashboard?month=${month}`)
    yield put(getDashboardSuccess(res))
  } catch (err) {
    yield put(getDashboardFailure({}))
  }
}

function* watchSagas(): Generator {
  yield takeLatest(PostSalesType.GET_POST_SALE_REQUEST, getPostSale)
  yield takeLatest(PostSalesType.GET_BLACKLIST_REQUEST, getBlacklist)
  yield takeLatest(PostSalesType.SET_BLACKLIST_REQUEST, setBlacklist)
  yield takeLatest(PostSalesType.REMOVE_BLACKLIST_REQUEST, removeBlacklist)
  yield takeLatest(PostSalesType.GET_PERSON_REQUEST, getPerson)
  yield takeLatest(PostSalesType.GET_CONVERSATION_REQUEST, getConversation)
  yield takeLatest(PostSalesType.EXPORT_SALE_LIST_REQUEST, exportPostSale)
  yield takeLatest(PostSalesType.GET_DASHBOARD_REQUEST, getDashboard)
}

export default function* previasSagas() {
  yield all([fork(watchSagas)])
}
function getDashboardFailure(arg0: {}): any {
  throw new Error('Function not implemented.')
}

