import styled from 'styled-components'

export default {
  Wrap: styled.div<any>`
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    min-width: 300px;
    min-height: 50px;
    right: 0;
    border-radius: 4px;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: ${({ open }: any) => (open ? 'flex' : 'none')};
  `,
}
