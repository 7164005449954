import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'
import { IComtemplayState, ComtemplayActionsTypes } from './types'

const initialState: IComtemplayState = {
  blacklistData: {
    error: false,
    loading: false,
  },
  changeCategoryData: {
    error: false,
    loading: false,
  },
}

export const contemplayReducer = (
  state: IComtemplayState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IComtemplayState => {
  switch (action.type) {
    case ComtemplayActionsTypes.LIST_BLACKLISTED_QUOTAS_REQUEST: {
      return {
        ...state,
        blacklistData: {
          ...state.blacklistData,
          loading: true,
          error: false,
        },
      }
    }
    case ComtemplayActionsTypes.LIST_BLACKLISTED_QUOTAS_SUCCESS: {
      return {
        ...state,
        blacklistData: {
          ...state.blacklistData,
          blacklist: action.payload.data,
          loading: false,
        },
      }
    }
    case ComtemplayActionsTypes.LIST_BLACKLISTED_QUOTAS_ERROR: {
      return {
        ...state,
        blacklistData: {
          ...state.blacklistData,
          error: true,
          loading: false,
        },
      }
    }
    case ComtemplayActionsTypes.LIST_COTATRADE_ENGINE_REQUEST: {
      return {
        ...state,
        changeCategoryData: {
          ...state.changeCategoryData,
          loading: true,
          error: false,
        },
      }
    }
    case ComtemplayActionsTypes.LIST_COTATRADE_ENGINE_SUCCESS: {
      return {
        ...state,
        changeCategoryData: {
          ...state.changeCategoryData,
          data: action.payload.data,
          loading: false,
        },
      }
    }
    case ComtemplayActionsTypes.LIST_COTATRADE_ENGINE_ERROR: {
      return {
        ...state,
        changeCategoryData: {
          ...state.changeCategoryData,
          error: true,
          loading: false,
        },
      }
    }
    default:
      return state
  }
}
