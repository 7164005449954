import Bifrost from 'store/utils/bifrost'
import { put, takeLatest, all, fork, call, takeEvery } from 'redux-saga/effects'
import { RolesTypes } from './types'
import { getRolesSuccess, getRolesFailure, getRolesRequest } from './actions'

const resource = '/admin/roles'

export function* handleGetRoles() {
  try {
    const res: any = yield call(Bifrost.get, `${resource}`)
    yield put(getRolesSuccess(res))
  } catch (err) {
    yield put(getRolesFailure())
  }
}

export function* handleCreateRoles(action: any) {
  try {
    yield call(Bifrost.post, `${resource}`, action.payload.data)
    yield put(getRolesRequest())
  } catch (err) {
    yield put(getRolesFailure())
  }
}

export function* handleUpdateRoles(action: any) {
  try {
    yield call(Bifrost.put, `${resource}/${action.payload.data._id}`, action.payload.data)
    yield put(getRolesRequest())
  } catch (err) {
    yield put(getRolesFailure())
  }
}

function* watchRequest(): Generator {
  yield takeLatest(RolesTypes.GET_ROLES_REQUEST, handleGetRoles)
  yield takeLatest(RolesTypes.CREATE_ROLE_REQUEST, handleCreateRoles)
  yield takeEvery(RolesTypes.UPDATE_ROLE_REQUEST, handleUpdateRoles)
}

export default function* rolesSagas() {
  yield all([fork(watchRequest)])
}
