import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { AssemblyTypes } from './types'
import {
  serviceGetAssemblySelect,
  serviceGetAssemblyResults,
  serviceNewAssembly,
  serviceUpdateAssembly,
} from './services'
import {
  getAssemblySelectsRequest,
  getAssemblySelectsSuccess,
  getAssemblySelectsFailure,
  getAssemblyDefaultSuccess,
  getAssemblyDefaultFailure,
  createNewAssemblySuccess,
  createNewAssemblyFailure,
  updateAssemblySuccess,
  updateAssemblyFailure,
} from './actions'

export function* getAssemblySelect() {
  try {
    const res: any = yield call(serviceGetAssemblySelect)
    yield put(getAssemblySelectsSuccess(res))
  } catch (err) {
    yield put(getAssemblySelectsFailure())
  }
}

export function* getAssemblyResult(action: any) {
  try {
    const res: any = yield call(serviceGetAssemblyResults, action.payload.month, action.payload.year)
    yield put(getAssemblyDefaultSuccess(res))
  } catch (err) {
    yield put(getAssemblyDefaultFailure())
  }
}

export function* createNewAssembly(action: any) {
  try {
    const res: any = yield call(serviceNewAssembly, action.payload.body)
    yield put(createNewAssemblySuccess(res))
    yield put(getAssemblySelectsRequest())
  } catch (err) {
    yield put(createNewAssemblyFailure(err))
  }
}

export function* updateAssembly(action: any) {
  try {
    const res: any = yield call(serviceUpdateAssembly, action.payload.body)
    yield put(updateAssemblySuccess(res))
    yield put(getAssemblySelectsRequest())
  } catch (err) {
    yield put(updateAssemblyFailure(err))
  }
}

function* watchSagas(): Generator {
  yield takeLatest(AssemblyTypes.GET_ASSEMBLY_SELECTS_REQUEST, getAssemblySelect)
  yield takeLatest(AssemblyTypes.GET_ASSEMBLY_DEFAULT_REQUEST, getAssemblyResult)
  yield takeLatest(AssemblyTypes.CREATE_NEW_ASSEMBLY_REQUEST, createNewAssembly)
  yield takeLatest(AssemblyTypes.UPDATE_ASSEMBLY_REQUEST, updateAssembly)
}

export default function* previasSagas() {
  yield all([fork(watchSagas)])
}
