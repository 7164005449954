import { ISignInResponse, AuthActionTypes, ISignInRequest, IApiUser } from './types'
import { push } from 'connected-react-router'
import { logout, signInError, signInSuccess } from './actions'
import { IMetaAction } from 'store/utils/types'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import Api from 'store/utils/bifrost'
import Helpers from 'shared/helpers'
import { showToastr } from 'store/toastr/actions'

function* handleSignIn(action: IMetaAction<ISignInRequest>): Generator {
  try {
    const { token }: ISignInResponse | any = yield call(Api.post, '/users/v2/login', action.payload)
    Helpers.setTokenLocalStorage(token)
    const userData: IApiUser | any = yield call(Api.get, '/users/me?origin=painel')
    delete userData['a:cpfcnpj']
    Helpers.setUserLocalStorage(userData)
    yield put(signInSuccess({ token, ...userData }))
    yield put(push('/'))
  } catch (err) {
    yield put(logout())
    yield put(signInError('Erro ao verificar dados'))
    yield put(showToastr('Erro ao verificar dados'))
  }
}

function* watchRequest(): Generator {
  yield takeLatest(AuthActionTypes.SIGNIN_REQUEST, handleSignIn)
}

export default function* authSaga() {
  yield all([fork(watchRequest)])
}
