import { toAppUser } from './functions'
import { TOKEN_KEY, USER_KEY } from 'shared/constants'
import Helpers from 'shared/helpers'
import { IAuthState, AuthActionTypes } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: IAuthState = {
  data: null,
  token: undefined,
  loading: false,
  error: '',
}

export const authReducer = (
  state: IAuthState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IAuthState => {
  switch (action.type) {
    case AuthActionTypes.SIGNIN_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case AuthActionTypes.SIGNIN_SUCCESS:
      return {
        ...state,
        data: toAppUser(action.payload.data),
        token: action.payload.data.token,
        loading: false,
        error: '',
      }
    case AuthActionTypes.SIGNIN_ERROR:
      return {
        ...state,
        loading: false,
        token: undefined,
        error: action.payload.data,
      }
    case AuthActionTypes.LOGOUT:
      localStorage.removeItem(TOKEN_KEY)
      localStorage.removeItem(USER_KEY)
      return {
        ...initialState,
      }
    case AuthActionTypes.SET_USER:
      return {
        ...state,
        data: toAppUser(Helpers.getUserLocalstorage()),
        token: Helpers.getToken() || '',
        loading: false,
      }
    default:
      return state
  }
}
