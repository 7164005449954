import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_CLIENT_API,
})

instance.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] =
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmMjU4ZTdiYWNiNGZjMDBiMGYzNGIyOSIsImlhdCI6MTU5NjQ2MjkyMH0.C_lPTqKIwQMOynk8NIYhgsKfmK7P5WWPfGm9Smm-bPo'
    return config
  },
  (error) => Promise.reject(error),
)

instance.interceptors.response.use(
  (res) => {
    return Promise.resolve(res.data)
  },
  (error) => {
    return Promise.reject(error.response.data)
  },
)

export default instance
