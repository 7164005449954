import { action } from 'typesafe-actions'
import { LpUnityTypes } from './types'

export const getLpUnityListRequest = (params) => action(LpUnityTypes.GET_LP_UNITY_REQUEST, { params })
export const getLpUnityListSuccess = (data: any) => action(LpUnityTypes.GET_LP_UNITY_SUCCESS, { data })
export const getLpUnityListFailure = () => action(LpUnityTypes.GET_LP_UNITY_FAILURE)

export const getLpUnityDetailsRequest = (id: any) => action(LpUnityTypes.GET_LP_UNITY_DETAILS_REQUEST, { id })
export const getLpUnityDetailsSuccess = (data: any) => action(LpUnityTypes.GET_LP_UNITY_DETAILS_SUCCESS, { data })
export const getLpUnityDetailsFailure = () => action(LpUnityTypes.GET_LP_UNITY_DETAILS_FAILURE)

export const postLpUnityRequest = (params: any) => action(LpUnityTypes.GET_LP_UNITY_CREATE_REQUEST, { params })
export const postLpUnitySuccess = (data: any) => action(LpUnityTypes.GET_LP_UNITY_CREATE_SUCCESS, { data })
export const postLpUnityFailure = () => action(LpUnityTypes.GET_LP_UNITY_CREATE_FAILURE)

export const putLpUnityRequest = (id: number, params: any) => action(LpUnityTypes.PUT_LP_UNITY_REQUEST, { id, params })
export const putLpUnitySuccess = (data: any) => action(LpUnityTypes.PUT_LP_UNITY_SUCCESS, { data })
export const putLpUnityFailure = () => action(LpUnityTypes.PUT_LP_UNITY_FAILURE)
