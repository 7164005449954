import React from 'react'
import PageTitle from 'components/PageTitle'
import ModuleConfigEdit from 'containers/ModuleConfig/ModuleConfigEdit'
import { useSelector } from 'react-redux'
import { IApplicationState } from 'store'

const ModulesConfigEditPage: React.FC = () => {
  const state = useSelector((selector: IApplicationState) => ({ module: selector.modulesConfig.detail }))

  return (
    <React.Fragment>
      <PageTitle title={`Edição de Módulo ${state.module.name}`} />
      <ModuleConfigEdit />
    </React.Fragment>
  )
}

export default ModulesConfigEditPage
