import React, { lazy } from 'react'

import * as Icons from '@material-ui/icons'

import { MenuItems } from 'routes/Routes'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { MATRICULAS } from 'shared/resources'

const MatriculasList = lazy(() => import('pages/Matriculas/List'))
const DetalhesMatricula = lazy(() => import('pages/Matriculas/Details'))
const EditarMatricula = lazy(() => import('pages/Matriculas/Edit'))
const CriarMatricula = lazy(() => import('pages/Matriculas/Create'))

export const MatriculasRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Matrículas',
    iconComponent: Icons.RecordVoiceOver,
    menus: [
      {
        path: `${MATRICULAS}`,
        name: 'Lista de matrículas',
        component: MatriculasList,
        exact: true,
        menus: [],
      },
      {
        path: `${MATRICULAS}/visualizar/:id`,
        name: 'Matrícula',
        component: DetalhesMatricula,
        exact: true,
        menus: [],
      },
      {
        path: `${MATRICULAS}/editar/:id`,
        name: 'Editar Matrícula',
        component: EditarMatricula,
        exact: true,
        menus: [],
      },
      {
        path: `${MATRICULAS}/criar`,
        name: 'Criar Matrícula',
        component: CriarMatricula,
        exact: true,
        menus: [],
      },
    ],
  },
]

export default function MatriculasRoutesRoutesComponent() {
  return <>{buildRouteAndSubRoute(MatriculasRoutes)}</>
}
