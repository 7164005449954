import { action } from 'typesafe-actions'
import { CaasTypes } from './types'

export const updateCaasSimulatorsRequest = (params) => action(CaasTypes.UPDATE_CAAS_SIMULATORS_REQUEST, { params })
export const updateCaasSimulatorsSuccess = (data: any) => action(CaasTypes.UPDATE_CAAS_SIMULATORS_SUCCESS, { data })
export const updateCaasSimulatorsFailure = () => action(CaasTypes.UPDATE_CAAS_SIMULATORS_FAILURE)

export const getCaasSimulatorsRequest = () => action(CaasTypes.GET_CAAS_SIMULATORS_REQUEST)
export const getCaasSimulatorsSuccess = (data: any) => action(CaasTypes.GET_CAAS_SIMULATORS_SUCCESS, { data })
export const getCaasSimulatorsFailure = () => action(CaasTypes.GET_CAAS_SIMULATORS_FAILURE)

export const getCaasConsorciosRequest = () => action(CaasTypes.GET_CAAS_CONSORTIUMS_REQUEST)
export const getCaasConsorciosSuccess = (data: any) => action(CaasTypes.GET_CAAS_CONSORTIUMS_SUCCESS, { data })
export const getCaasConsorciosFailure = () => action(CaasTypes.GET_CAAS_CONSORTIUMS_FAILURE)

export const getCaasBannerRequest = (params) => action(CaasTypes.GET_CAAS_BANNER_REQUEST, { params })
export const getCaasBannerSuccess = (data: any) => action(CaasTypes.GET_CAAS_BANNER_SUCCESS, { data })
export const getCaasBannerFailure = () => action(CaasTypes.GET_CAAS_BANNER_FAILURE)

export const updateCaasBannerRequest = (params) => action(CaasTypes.UPDATE_CAAS_BANNER_REQUEST, { params })
export const updateCaasBannerSuccess = (data: any) => action(CaasTypes.UPDATE_CAAS_BANNER_SUCCESS, { data })
export const updateCaasBannerFailure = () => action(CaasTypes.UPDATE_CAAS_BANNER_FAILURE)

export const getCaasRegulationRequest = (params) => action(CaasTypes.GET_CAAS_REGULATION_REQUEST, { params })
export const getCaasRegulationSuccess = (data: any) => action(CaasTypes.GET_CAAS_REGULATION_SUCCESS, { data })
export const getCaasRegulationFailure = () => action(CaasTypes.GET_CAAS_REGULATION_FAILURE)

export const getCaasRegulationByIdRequest = (params) => action(CaasTypes.GET_CAAS_REGULATION_BY_ID_REQUEST, { params })
export const getCaasRegulationByIdSuccess = (data: any) => action(CaasTypes.GET_CAAS_REGULATION_BY_ID_SUCCESS, { data })
export const getCaasRegulationByIdFailure = () => action(CaasTypes.GET_CAAS_REGULATION_BY_ID_FAILURE)

export const deleteCaasRegulationByIdRequest = (params) => action(CaasTypes.DELETE_CAAS_REGULATION_BY_ID_REQUEST, { params })
export const deleteCaasRegulationByIdSuccess = (data: any) => action(CaasTypes.DELETE_CAAS_REGULATION_BY_ID_SUCCESS, { data })
export const deleteCaasRegulationByIdFailure = () => action(CaasTypes.DELETE_CAAS_REGULATION_BY_ID_FAILURE)

export const updateCaasRegulationRequest = (data: any) => action(CaasTypes.UPDATE_CAAS_REGULATION_REQUEST, { data })
export const updateCaasRegulationSuccess = (data: any) => action(CaasTypes.UPDATE_CAAS_REGULATION_SUCCESS, { data })
export const updateCaasRegulationFailure = () => action(CaasTypes.UPDATE_CAAS_REGULATION_FAILURE)

export const createCaasRegulationRequest = (data: any) => action(CaasTypes.CREATE_CAAS_REGULATION_REQUEST, { data })
export const createCaasRegulationSuccess = (data: any) => action(CaasTypes.CREATE_CAAS_REGULATION_SUCCESS, { data })
export const createCaasRegulationFailure = () => action(CaasTypes.CREATE_CAAS_REGULATION_FAILURE)

export const deleteCaasRegulationRequest = (data: any) => action(CaasTypes.DELETE_CAAS_REGULATION_REQUEST, { data })
export const deleteCaasRegulationSuccess = (data: any) => action(CaasTypes.DELETE_CAAS_REGULATION_SUCCESS, { data })
export const deleteCaasRegulationFailure = () => action(CaasTypes.DELETE_CAAS_REGULATION_FAILURE)
