import React, { useState } from 'react'
import { Grid, TextField, Select, ListItem, IconButton, Box } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import Helpers from 'shared/helpers'

interface AddMenuParamProps {
  type: 'add' | 'edit'
  param: any
  paramName: string
  onSubmit: (paramObj: any, paramName: string, oldName: string) => void
}

export interface IParamObj {
  [key: string]: {
    value: string
    type: 'static' | 'dynamic'
  }
}

const AddMenuParam: React.FC<AddMenuParamProps> = ({ type, param, paramName, onSubmit }) => {
  const oldName = paramName
  const [myParamName, setMyParamName] = useState<string>(paramName)
  const [params, setParams] = useState(param)
  const [dynamicProps, setDynamicProps] = useState<string[]>([])

  const getObjectAndSubmit = () => {
    const obj = {
      [myParamName]: {
        value: params.value,
        type: params.type,
      },
    }
    onSubmit(obj, myParamName, oldName)
  }

  const handleAddParams = () => {
    if (type === 'edit') {
      onSubmit(null, myParamName, oldName)
      return
    }

    getObjectAndSubmit()
  }

  const handleEdit = () => getObjectAndSubmit()

  const handleChange = (
    e: React.ChangeEvent<{
      name: string
      value: unknown
    }>,
  ) => {
    setParams({
      ...params,
      [e.target.name]: e.target.value,
    })
  }

  const getDyamicProps = () => {
    const storage = Helpers.getUserLocalstorage()
    const keys = Object.keys(storage?.user || {})
    setDynamicProps(keys)
  }

  React.useEffect(() => {
    getDyamicProps()
  }, [])

  const StaticParams = (
    <React.Fragment>
      <Grid item md={4}>
        <TextField
          fullWidth
          variant="outlined"
          value={myParamName}
          onChange={(e) => setMyParamName(e.target.value)}
          onBlur={type === 'edit' ? handleEdit : () => null}
          label="Chave"
        />
      </Grid>
      <Grid item md={4}>
        <TextField
          fullWidth
          variant="outlined"
          value={params.value}
          name="value"
          onChange={handleChange}
          onBlur={type === 'edit' ? handleEdit : () => null}
          label="Valor"
        />
      </Grid>
    </React.Fragment>
  )

  const DynamicParams = (
    <React.Fragment>
      <Grid item md={4}>
        <TextField
          fullWidth
          variant="outlined"
          value={myParamName}
          onChange={(e) => setMyParamName(e.target.value)}
          onBlur={type === 'edit' ? handleEdit : () => null}
          label="Chave"
        />
      </Grid>
      <Grid item md={4}>
        <Select
          fullWidth
          variant="outlined"
          value={params.value}
          name="value"
          onBlur={type === 'edit' ? handleEdit : () => null}
          onChange={(e: React.ChangeEvent<any>) => handleChange(e)}
        >
          {dynamicProps.map((k: string, i: number) => (
            <ListItem value={k} key={i}>
              {k}
            </ListItem>
          ))}
        </Select>
      </Grid>
    </React.Fragment>
  )

  return (
    <Box marginBottom={2}>
      <Grid container spacing={2}>
        {params.type === 'static' && StaticParams}
        {params.type === 'dynamic' && DynamicParams}
        <Grid item md={3}>
          <Select
            fullWidth
            variant="outlined"
            value={params.type}
            name="type"
            onBlur={type === 'edit' ? handleEdit : () => null}
            onChange={(e: React.ChangeEvent<any>) => handleChange(e)}
          >
            <ListItem value="static">Estático</ListItem>
            <ListItem value="dynamic">Dinâmico</ListItem>
          </Select>
        </Grid>
        <Grid item md={1}>
          <IconButton color="primary" onClick={handleAddParams}>
            {type === 'edit' ? <Icons.Remove color="disabled" /> : <Icons.Add />}
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddMenuParam
