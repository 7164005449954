import { action } from 'typesafe-actions'
import { WhatsTypes } from './types'

export const getWhatsListRequest = (params: any) => action(WhatsTypes.GET_WHATS_LIST_REQUEST, { params })
export const getWhatsListSuccess = (res: any) => action(WhatsTypes.GET_WHATS_LIST_SUCCESS, { res })
export const getWhatsListFailure = () => action(WhatsTypes.GET_WHATS_LIST_FAILURE)

export const getWhatsCreateAccountRequest = (params: any) =>
  action(WhatsTypes.GET_WHATS_CREATE_ACCOUNT_REQUEST, { params })
export const getWhatsCreateAccountSuccess = (res: any) => action(WhatsTypes.GET_WHATS_CREATE_ACCOUNT_SUCCESS, { res })
export const getWhatsCreateAccountFailure = () => action(WhatsTypes.GET_WHATS_CREATE_ACCOUNT_FAILURE)

export const getWhatsValidateAccountRequest = (params: any) =>
  action(WhatsTypes.GET_WHATS_VALIDATE_ACCOUNT_REQUEST, { params })
export const getWhatsValidateAccountSuccess = (res: any) =>
  action(WhatsTypes.GET_WHATS_VALIDATE_ACCOUNT_SUCCESS, { res })
export const getWhatsValidateAccountFailure = () => action(WhatsTypes.GET_WHATS_VALIDATE_ACCOUNT_FAILURE)

export const getWhatsAccountRequest = (params: any) => action(WhatsTypes.GET_WHATS_ACCOUNT_REQUEST, { params })
export const getWhatsAccountSuccess = (res: any) => action(WhatsTypes.GET_WHATS_ACCOUNT_SUCCESS, { res })
export const getWhatsAccountFailure = () => action(WhatsTypes.GET_WHATS_ACCOUNT_FAILURE)

export const getWhatsAccountByIdRequest = (id: any) => action(WhatsTypes.GET_WHATS_ACCOUNT_BY_ID_REQUEST, { id })
export const getWhatsAccountByIdSuccess = (res: any) => action(WhatsTypes.GET_WHATS_ACCOUNT_BY_ID_SUCCESS, { res })
export const getWhatsAccountByIdFailure = () => action(WhatsTypes.GET_WHATS_ACCOUNT_BY_ID_FAILURE)

export const getWhatsQrCodeRequest = (id: any) => action(WhatsTypes.GET_WHATS_QRCODE_REQUEST, { id })
export const getWhatsQrCodeSuccess = (res: any) => action(WhatsTypes.GET_WHATS_QRCODE_SUCCESS, { res })
export const getWhatsQrCodeFailure = () => action(WhatsTypes.GET_WHATS_QRCODE_FAILURE)

export const getWhatsQrCodeStatusRequest = (id: any) => action(WhatsTypes.GET_WHATS_QRCODE_STATUS_REQUEST, { id })
export const getWhatsQrCodeStatusSuccess = (res: any) => action(WhatsTypes.GET_WHATS_QRCODE_STATUS_SUCCESS, { res })
export const getWhatsQrCodeStatusFailure = () => action(WhatsTypes.GET_WHATS_QRCODE_STATUS_FAILURE)

export const getWhatsQrCodeDeviceRequest = (id: any) => action(WhatsTypes.GET_WHATS_QRCODE_DEVICE_REQUEST, { id })
export const getWhatsQrCodeDeviceSuccess = (res: any) => action(WhatsTypes.GET_WHATS_QRCODE_DEVICE_SUCCESS, { res })
export const getWhatsQrCodeDeviceFailure = () => action(WhatsTypes.GET_WHATS_QRCODE_DEVICE_FAILURE)

export const getWhatsCreateShootRequest = (params: any) => action(WhatsTypes.GET_WHATS_CREATE_SHOOT_REQUEST, { params })
export const getWhatsCreateShootSuccess = (res: any) => action(WhatsTypes.GET_WHATS_CREATE_SHOOT_SUCCESS, { res })
export const getWhatsCreateShootFailure = () => action(WhatsTypes.GET_WHATS_CREATE_SHOOT_FAILURE)

export const getWhatsShootByIdRequest = (id: any) => action(WhatsTypes.GET_WHATS_SHOOT_BY_ID_REQUEST, { id })
export const getWhatsShootByIdSuccess = (res: any) => action(WhatsTypes.GET_WHATS_SHOOT_BY_ID_SUCCESS, { res })
export const getWhatsShootByIdFailure = () => action(WhatsTypes.GET_WHATS_SHOOT_BY_ID_FAILURE)

export const getWhatsListShootByIdRequest = (params: any) =>
  action(WhatsTypes.GET_WHATS_LIST_SHOOT_BY_ID_REQUEST, { params })
export const getWhatsListShootByIdSuccess = (res: any) => action(WhatsTypes.GET_WHATS_LIST_SHOOT_BY_ID_SUCCESS, { res })
export const getWhatsListShootByIdFailure = () => action(WhatsTypes.GET_WHATS_LIST_SHOOT_BY_ID_FAILURE)

export const getWhatsCancelShootRequest = (id: any) => action(WhatsTypes.GET_WHATS_CANCEL_SHOOT_REQUEST, { id })
export const getWhatsCancelShootSuccess = (res: any) => action(WhatsTypes.GET_WHATS_CANCEL_SHOOT_SUCCESS, { res })
export const getWhatsCancelShootFailure = () => action(WhatsTypes.GET_WHATS_CANCEL_SHOOT_FAILURE)
