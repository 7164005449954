export const InsuranceSalesConsultantsTypes = {
  GET_INSURANCE_SALES_CONSTANTS_REQUEST: '@@insurance/GET_INSURANCE_SALES_CONSTANTS_REQUEST',
  GET_INSURANCE_SALES_CONSTANTS_SUCCESS: '@@insurance/GET_INSURANCE_SALES_CONSTANTS_SUCCESS',
  GET_INSURANCE_SALES_CONSTANTS_FAILURE: '@@insurance/GET_INSURANCE_SALES_CONSTANTS_FAILURE',

  GET_INSURANCE_SALES_CONSTANTS_DETAILS_REQUEST: '@@insurance/GET_INSURANCE_SALES_CONSTANTS_DETAILS_REQUEST',
  GET_INSURANCE_SALES_CONSTANTS_DETAILS_SUCCESS: '@@insurance/GET_INSURANCE_SALES_CONSTANTS_DETAILS_SUCCESS',
  GET_INSURANCE_SALES_CONSTANTS_DETAILS_FAILURE: '@@insurance/GET_INSURANCE_SALES_CONSTANTS_DETAILS_FAILURE',

  POST_INSURANCE_SALES_CONSTANTS_REQUEST: '@@insurance/POST_INSURANCE_SALES_CONSTANTS_REQUEST',
  POST_INSURANCE_SALES_CONSTANTS_SUCCESS: '@@insurance/POST_INSURANCE_SALES_CONSTANTS_SUCCESS',
  POST_INSURANCE_SALES_CONSTANTS_FAILURE: '@@insurance/POST_INSURANCE_SALES_CONSTANTS_FAILURE',
}

export interface IInsurancesSalesConstantsState {
  list: Record<string, any>
  details: Record<string, any>
}
