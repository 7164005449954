/* eslint-disable react/display-name */
import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { COMUNICACAO_RESOURCE } from 'shared/resources'

const ComunicacaoListPage = lazy(() => import('pages/Comunicacao/List'))
const ComunicacaoEditPage = lazy(() => import('pages/Comunicacao/Edit'))
const ComunicacaoCreatePage = lazy(() => import('pages/Comunicacao/Create'))
const ComunicacaoReportPage = lazy(() => import('pages/Comunicacao/Report'))
const ComunicacaoViewPage = lazy(() => import('pages/Comunicacao/Preview'))

const HomeListPage = lazy(() => import('pages/Comunicacao/ListHome'))
const HomeEditPage = lazy(() => import('pages/Comunicacao/EditHome'))
const HomeCreatePage = lazy(() => import('pages/Comunicacao/CreateHome'))
const HomeViewPage = lazy(() => import('pages/Comunicacao/PreviewHome'))

export const ComunicacaoRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Comunicação',
    iconComponent: Icons.RecordVoiceOver,
    menus: [
      {
        path: `${COMUNICACAO_RESOURCE}/notificacoes/p/:page`,
        name: 'Lista de notificações',
        component: ComunicacaoListPage,
        exact: true,
        menus: [],
      },
      {
        path: `${COMUNICACAO_RESOURCE}/notificacao/editar`,
        name: 'Editar notificação',
        component: ComunicacaoEditPage,
        exact: true,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${COMUNICACAO_RESOURCE}/notificacao/editar/:id`,
        name: 'Editar notificação',
        component: ComunicacaoEditPage,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${COMUNICACAO_RESOURCE}/notificacao/criar`,
        name: 'Criar notificação',
        component: ComunicacaoCreatePage,
        exact: true,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${COMUNICACAO_RESOURCE}/notificacao/visualizar/:id`,
        name: 'Visualizar notificação',
        component: ComunicacaoViewPage,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${COMUNICACAO_RESOURCE}/relatorios`,
        name: 'Relatórios',
        component: ComunicacaoReportPage,
        exact: true,
        isSubRoute: true,
        menus: [],
      },
      // Campanhas home
      {
        path: `${COMUNICACAO_RESOURCE}/p/:page`,
        name: 'Lista de Notificações',
        component: HomeListPage,
        exact: true,
        menus: [],
      },
      {
        path: `${COMUNICACAO_RESOURCE}/editar`,
        name: 'Editar notificação',
        component: HomeEditPage,
        exact: true,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${COMUNICACAO_RESOURCE}/editar/:id`,
        name: 'Editar notificação',
        component: HomeEditPage,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${COMUNICACAO_RESOURCE}/criar`,
        name: 'Criar notificação',
        component: HomeCreatePage,
        exact: true,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${COMUNICACAO_RESOURCE}/visualizar/:id`,
        name: 'Visualizar notificação',
        component: HomeViewPage,
        isSubRoute: true,
        menus: [],
      },
    ],
  },
]

export default () => <>{buildRouteAndSubRoute(ComunicacaoRoutes)}</>
