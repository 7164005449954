import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import Helpers from 'shared/helpers'
import BaseContainer from 'containers/BaseContainer'

interface IPrivateRouteProps {
  path: string
  // claim: boolean
  component?: any
  title?: string
  exact?: boolean
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ component: Component, title, ...props }) => {
  return (
    <Route
      {...props}
      render={(props) =>
        Helpers.isAuth() ? (
          <BaseContainer>
            <Component {...{ ...props, title }} />
          </BaseContainer>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
