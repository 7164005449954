import { NegotiationsTypes, INegotiationsState } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: INegotiationsState = {
  data: [],
  detail: null,
  loading: false,
  loadingActions: false,
  error: '',
}

export const negotiationsReducer = (
  state: INegotiationsState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): INegotiationsState => {
  switch (action.type) {
    case NegotiationsTypes.GET_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case NegotiationsTypes.GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      }
    case NegotiationsTypes.GET_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: 'Erro ao obter dados.',
      }
    case NegotiationsTypes.GET_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case NegotiationsTypes.GET_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingActions: false,
        detail: action.payload.data,
      }
    case NegotiationsTypes.GET_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: 'Erro ao obter dados.',
      }
    case NegotiationsTypes.RESEND_EMAIL_REQUEST:
    case NegotiationsTypes.POST_ACCEPT_REQUEST:
    case NegotiationsTypes.POST_REJECT_REQUEST:
    case NegotiationsTypes.POST_WAITING_PAYMENT_REQUEST:
    case NegotiationsTypes.POST_PAYMENT_CONFIRMATION_REQUEST:
    case NegotiationsTypes.POST_PAYMENT_REJECT_REQUEST:
    case NegotiationsTypes.POST_NEGOTIATION_REQUEST:
      return {
        ...state,
        loadingActions: true,
      }
    case NegotiationsTypes.RESEND_EMAIL_SUCCESS:
    case NegotiationsTypes.POST_ACCEPT_SUCCESS:
    case NegotiationsTypes.POST_REJECT_SUCCESS:
    case NegotiationsTypes.POST_WAITING_PAYMENT_SUCCESS:
    case NegotiationsTypes.POST_PAYMENT_REJECT_SUCCESS:
    case NegotiationsTypes.POST_NEGOTIATION_SUCCESS:
      return {
        ...state,
        loadingActions: false,
      }
    case NegotiationsTypes.RESEND_EMAIL_FAILURE:
    case NegotiationsTypes.POST_ACCEPT_FAILURE:
    case NegotiationsTypes.POST_REJECT_FAILURE:
    case NegotiationsTypes.POST_WAITING_PAYMENT_FAILURE:
    case NegotiationsTypes.POST_PAYMENT_CONFIRMATION_FAILURE:
    case NegotiationsTypes.POST_PAYMENT_REJECT_FAILURE:
    case NegotiationsTypes.POST_NEGOTIATION_FAILURE:
      return {
        ...state,
        loadingActions: false,
      }
    case NegotiationsTypes.RESET_DETAIL:
      return {
        ...state,
        detail: initialState.detail,
      }
    default:
      return state
  }
}
