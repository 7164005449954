/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { ANTECIPAY_RESOURCE } from 'shared/resources'

//import ListRoundedIcon from '@mui/icons-material/ListRounded';
//import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';

const AntecipayListPage = lazy(() => import('pages/Antecipay/List'))
const ContratosListPage = lazy(() => import('pages/Antecipay/Contracts'))
const ContractsDetalPage = lazy(() => import('pages/Antecipay/Contract'))
const Blacklist = lazy(() => import('pages/Antecipay/Blacklist'))
const BlacklistDetail = lazy(() => import('pages/Antecipay/Blacklist/Details'))
const UserDetail = lazy(() => import('pages/Previas/UserDetail'))

export const AntecipayRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Antecipay',
    iconComponent: Icons.Assessment,
    menus: [
      {
        path: `${ANTECIPAY_RESOURCE}/lista-solicitacao`,
        name: 'Lista de Solicitação',
        component: AntecipayListPage,
        exact: true,
        menus: [],
      },
      {
        path: `${ANTECIPAY_RESOURCE}/status/:route/:id`,
        name: 'Informações do candidato',
        component: UserDetail,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${ANTECIPAY_RESOURCE}/contracts`,
        name: 'Meus Contratos',
        component: ContratosListPage,
        exact: true,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${ANTECIPAY_RESOURCE}/contracts/:id/:contrato`,
        name: 'Visualizar Contrato',
        component: ContractsDetalPage,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${ANTECIPAY_RESOURCE}/blacklist`,
        name: 'Blacklist',
        component: Blacklist,
        exact: true,
        menus: [],
      },
      {
        path: `${ANTECIPAY_RESOURCE}/blacklist/:id`,
        name: 'Blacklist',
        component: BlacklistDetail,
        isSubRoute: true,
        menus: [],
      },
    ],
  },
]

export default () => <>{buildRouteAndSubRoute(AntecipayRoutes)}</>
