import apiUnidades from 'store/utils/unidades'
import { LpUnityTypes } from './types'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
  getLpUnityListSuccess,
  getLpUnityListFailure,
  getLpUnityDetailsSuccess,
  getLpUnityDetailsFailure,
  postLpUnitySuccess,
  postLpUnityFailure,
  putLpUnitySuccess,
  putLpUnityFailure,
} from './actions'

// Listagem de Unidades
export function* getLpUnityList(action: any) {
  try {
    const res: any = yield call(apiUnidades.get, `/unidades`, action.payload)
    yield put(getLpUnityListSuccess(res))
  } catch (err) {
    yield put(getLpUnityListFailure())
  }
}

// Informações de apenas uma Unidade
export function* getLpUnityDetails(data: any) {
  const id = data.payload.id
  try {
    const res: any = yield call(apiUnidades.get, `/unidades/${id}`)
    yield put(getLpUnityDetailsSuccess(res))
  } catch (err) {
    yield put(getLpUnityDetailsFailure())
  }
}

// Criar unidade
export function* createLpUnity(params: any) {
  try {
    const res: any = yield call(apiUnidades.post, `/unidades`, params.payload.params)
    yield put(postLpUnitySuccess(res))
  } catch (err) {
    yield put(postLpUnityFailure())
  }
}

//Editar unidade
export function* updateLpUnity(params: any) {
  try {
    const id = params.payload.id
    const res: any = yield call(apiUnidades.put, `/unidades/${id}`, params.payload.params)
    yield put(putLpUnitySuccess(res))
  } catch (err) {
    yield put(putLpUnityFailure())
  }
}

function* watchSagas(): Generator {
  yield takeLatest(LpUnityTypes.GET_LP_UNITY_REQUEST, getLpUnityList)
  yield takeLatest(LpUnityTypes.GET_LP_UNITY_DETAILS_REQUEST, getLpUnityDetails)
  yield takeLatest(LpUnityTypes.GET_LP_UNITY_CREATE_REQUEST, createLpUnity)
  yield takeLatest(LpUnityTypes.PUT_LP_UNITY_REQUEST, updateLpUnity)
}

export default function* previasSagas() {
  yield all([fork(watchSagas)])
}
