import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { PERMISSION_RESOURCE } from 'shared/resources'

const PermissionsPage = lazy(() => import('pages/Permissions'))

export const PermissionsRoutes: MenuItems[] = [
  {
    path: `${PERMISSION_RESOURCE}`,
    name: 'Permissões',
    component: PermissionsPage,
    exact: true,
    iconComponent: Icons.Code,
    menus: [],
  },
]

export default function PermissionsRoutesComponent() {
  return <>{buildRouteAndSubRoute(PermissionsRoutes)}</>
}
