import { faUser, faChartUser, faArrowCircleRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import { colorTextStatus } from 'pages/Previas/fragments/searchColorStatus'
import React, { useState } from 'react'
import { style } from '../../fragments/candidateDataFrame/style'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IApplicationState } from 'store'
import moment from 'moment'
import RemoveBlacklistModal from './RemoveBlacklistModal'
import AddBlacklistModal from './AddBlacklistModal'
import AddCommentModal from './AddCommentModal'

const Dataframe: React.FC<any> = () => {
  const classes = style()
  const { blacklistDetail } = useSelector((state: IApplicationState) => state.previas)
  const { data } = blacklistDetail
  const [open, setOpen] = useState(false)
  const [openAdd, setOpenAdd] = useState(false)
  const [openComment, setOpenComment] = useState(false)
  return (
    <>
      <RemoveBlacklistModal open={open} setOpen={setOpen} />
      <AddBlacklistModal isNew={false} open={openAdd} setOpen={setOpenAdd} />
      <AddCommentModal open={openComment} setOpen={setOpenComment} />
      <Grid className={classes.candidateDataAlternative}>
        <Box className={classes.candidateDataTitle}>
          <FontAwesomeIcon size="lg" icon={faUser} style={{ width: '1.7rem' }} />
          <Typography variant="h5">Dados pessoais</Typography>
        </Box>
        <Box marginBottom="15px">
          <Typography
            variant="h5"
            className={classes.textOverflow}
            style={{ fontSize: '1.5rem', fontWeight: 700, textTransform: 'uppercase', marginBottom: '1rem' }}
          >
            {data.blacklist.name}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '15px' }}>
          <Box>
            <Typography variant="subtitle1" className={classes.candidateDataSubtitleAlternative}>
              Documento:
            </Typography>
            <Typography variant="body1">{data.blacklist.cpfCnpj}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" className={classes.candidateDataSubtitleAlternative}>
              Data do registro:
            </Typography>
            <Typography variant="body1">{moment(data.blacklist.createdAt).format('DD/MM/YYYY')}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '15px' }}>
          <Box>
            <Typography variant="subtitle1" className={classes.candidateDataSubtitleAlternative}>
              Registrado por:
            </Typography>
            <Typography variant="body1">{data.blacklist.user}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '15px' }}>
          <Box>
            <Typography variant="subtitle1" className={classes.candidateDataSubtitleAlternative}>
              Status:
            </Typography>
            <Typography variant="body1" className={classes.textOverflow}>
              {data.blacklist.active ? 'Bloqueado' : 'Desbloqueado'}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.candidateDataTitle}>
          <FontAwesomeIcon size="lg" icon={faChartUser} style={{ width: '1.7rem' }} />
          <Typography variant="h5">Prévias</Typography>
        </Box>
        <Box>
          {data.previas.map((item, index) => (
            <Box
              key={index}
              style={{
                backgroundColor: '#F1F1F1',
                padding: '15px',
                borderRadius: '6px',
                display: 'flex', // Ensure the items are aligned in a row
                alignItems: 'center', // Vertically align items
                justifyContent: 'space-between', // Space out items
              }}
            >
              <Box width="100%">
                <Typography variant="subtitle1" style={{ fontSize: '18px', fontWeight: 'lighter' }}>
                  Data de registro: {moment(item.createdAt).utc().format('DD/MM/YYYY')}
                </Typography>
                <Box width="100%">
                  <Typography variant="subtitle1" className={classes.candidateDataSubtitle}>
                    Último status:
                  </Typography>
                  <div style={{ background: colorTextStatus(item.status.nome) }} className={classes.colorTextStatus}>
                    {item.status.nome}
                  </div>
                </Box>
              </Box>
              <Box>
                <FontAwesomeIcon
                  cursor="pointer"
                  size="4x"
                  icon={faArrowCircleRight}
                  style={{ width: '1.7rem' }}
                  onClick={() => window.open(`/previas/candidato/4/${item._id}`, '_blank')}
                />
              </Box>
            </Box>
          ))}
        </Box>
        <Box>
          <Button
            onClick={() => {
              blacklistDetail.data.blacklist.active ? setOpen(true) : setOpenAdd(true)
            }}
            variant="text"
            style={{
              backgroundColor: 'whitesmoke',
              width: '100%',
              borderRadius: 30,
              color: 'black',
              textTransform: 'none',
              marginTop: 16,
            }}
          >
            {blacklistDetail.data.blacklist.active ? 'Remover da blacklist' : 'Adicionar à blacklist'}
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => {
              setOpenComment(true)
            }}
            variant="text"
            style={{
              backgroundColor: '#cd260a',
              width: '100%',
              borderRadius: 30,
              textTransform: 'none',
              color: 'white',
              marginTop: 16,
            }}
          >
            Adicionar comentário
          </Button>
        </Box>
      </Grid>
    </>
  )
}

export default withRouter(Dataframe)
