/* eslint-disable react/display-name */
import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { HOME_EQUITY_RESOURCE } from 'shared/resources'

const HomeEquityOfferList = lazy(() => import('pages/HomeEquity/Offer/List'))
const HomeEquityOfferDetail = lazy(() => import('pages/HomeEquity/Offer/Details'))

export const HomeEquityRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Home Equity',
    iconComponent: Icons.Build,
    menus: [
      {
        path: `${HOME_EQUITY_RESOURCE}`,
        name: 'Lista de Propostas',
        component: HomeEquityOfferList,
        exact: true,
        menus: [],
      },
      {
        path: `${HOME_EQUITY_RESOURCE}/:id`,
        name: 'Detalhe da Proposta',
        component: HomeEquityOfferDetail,
        isSubRoute: true,
        menus: [],
      },
    ],
  },
]

export default () => <>{buildRouteAndSubRoute(HomeEquityRoutes)}</>
