export const WhatsTypes = {
  GET_WHATS_LIST_REQUEST: '@@assembly/GET_WHATS_LIST_REQUEST',
  GET_WHATS_LIST_SUCCESS: '@@assembly/GET_WHATS_LIST_SUCCESS',
  GET_WHATS_LIST_FAILURE: '@@assembly/GET_WHATS_LIST_FAILURE',

  GET_WHATS_CREATE_ACCOUNT_REQUEST: '@@assembly/GET_WHATS_CREATE_ACCOUNT_REQUEST',
  GET_WHATS_CREATE_ACCOUNT_SUCCESS: '@@assembly/GET_WHATS_CREATE_ACCOUNT_SUCCESS',
  GET_WHATS_CREATE_ACCOUNT_FAILURE: '@@assembly/GET_WHATS_CREATE_ACCOUNT_FAILURE',

  GET_WHATS_VALIDATE_ACCOUNT_REQUEST: '@@assembly/GET_WHATS_VALIDATE_ACCOUNT_REQUEST',
  GET_WHATS_VALIDATE_ACCOUNT_SUCCESS: '@@assembly/GET_WHATS_VALIDATE_ACCOUNT_SUCCESS',
  GET_WHATS_VALIDATE_ACCOUNT_FAILURE: '@@assembly/GET_WHATS_VALIDATE_ACCOUNT_FAILURE',

  GET_WHATS_ACCOUNT_REQUEST: '@@assembly/GET_WHATS_ACCOUNT_REQUEST',
  GET_WHATS_ACCOUNT_SUCCESS: '@@assembly/GET_WHATS_ACCOUNT_SUCCESS',
  GET_WHATS_ACCOUNT_FAILURE: '@@assembly/GET_WHATS_ACCOUNT_FAILURE',

  GET_WHATS_ACCOUNT_BY_ID_REQUEST: '@@assembly/GET_WHATS_ACCOUNT_BY_ID_REQUEST',
  GET_WHATS_ACCOUNT_BY_ID_SUCCESS: '@@assembly/GET_WHATS_ACCOUNT_BY_ID_SUCCESS',
  GET_WHATS_ACCOUNT_BY_ID_FAILURE: '@@assembly/GET_WHATS_ACCOUNT_BY_ID_FAILURE',

  GET_WHATS_QRCODE_REQUEST: '@@assembly/GET_WHATS_QRCODE_REQUEST',
  GET_WHATS_QRCODE_SUCCESS: '@@assembly/GET_WHATS_QRCODE_SUCCESS',
  GET_WHATS_QRCODE_FAILURE: '@@assembly/GET_WHATS_QRCODE_FAILURE',

  GET_WHATS_QRCODE_STATUS_REQUEST: '@@assembly/GET_WHATS_QRCODE_STATUS_REQUEST',
  GET_WHATS_QRCODE_STATUS_SUCCESS: '@@assembly/GET_WHATS_QRCODE_STATUS_SUCCESS',
  GET_WHATS_QRCODE_STATUS_FAILURE: '@@assembly/GET_WHATS_QRCODE_STATUS_FAILURE',

  GET_WHATS_CREATE_SHOOT_REQUEST: '@@assembly/GET_WHATS_CREATE_SHOOT_REQUEST',
  GET_WHATS_CREATE_SHOOT_SUCCESS: '@@assembly/GET_WHATS_CREATE_SHOOT_SUCCESS',
  GET_WHATS_CREATE_SHOOT_FAILURE: '@@assembly/GET_WHATS_CREATE_SHOOT_FAILURE',

  GET_WHATS_SHOOT_BY_ID_REQUEST: '@@assembly/GET_WHATS_SHOOT_BY_ID_REQUEST',
  GET_WHATS_SHOOT_BY_ID_SUCCESS: '@@assembly/GET_WHATS_SHOOT_BY_ID_SUCCESS',
  GET_WHATS_SHOOT_BY_ID_FAILURE: '@@assembly/GET_WHATS_SHOOT_BY_ID_FAILURE',

  GET_WHATS_LIST_SHOOT_BY_ID_REQUEST: '@@assembly/GET_WHATS_LIST_SHOOT_BY_ID_REQUEST',
  GET_WHATS_LIST_SHOOT_BY_ID_SUCCESS: '@@assembly/GET_WHATS_LIST_SHOOT_BY_ID_SUCCESS',
  GET_WHATS_LIST_SHOOT_BY_ID_FAILURE: '@@assembly/GET_WHATS_LIST_SHOOT_BY_ID_FAILURE',

  GET_WHATS_CANCEL_SHOOT_REQUEST: '@@assembly/GET_WHATS_CANCEL_SHOOT_REQUEST',
  GET_WHATS_CANCEL_SHOOT_SUCCESS: '@@assembly/GET_WHATS_CANCEL_SHOOT_SUCCESS',
  GET_WHATS_CANCEL_SHOOT_FAILURE: '@@assembly/GET_WHATS_CANCEL_SHOOT_FAILURE',

  GET_WHATS_QRCODE_DEVICE_REQUEST: '@@assembly/GET_WHATS_QRCODE_DEVICE_REQUEST',
  GET_WHATS_QRCODE_DEVICE_SUCCESS: '@@assembly/GET_WHATS_QRCODE_DEVICE_SUCCESS',
  GET_WHATS_QRCODE_DEVICE_FAILURE: '@@assembly/GET_WHATS_QRCODE_DEVICE_FAILURE',
}

export interface IWhatsAppState {
  list: Record<string, any>
  account: Record<string, any>
  accountById: Record<string, any>
  qrCode: Record<string, any>
  qrCodeStatus: Record<string, any>
  shootById: Record<string, any>
  listShootById: Record<string, any>
  qrCodeDevice: Record<string, any>
  cancelShoot: Record<string, any>
  createShoot: Record<string, any>
  createAccount: Record<string, any>
  validAccount: Record<string, any>
}

export function getSignsStatus(status: string): string {
  switch (status) {
    case 'C':
      return 'Conectada'
    case 'D':
      return 'Desconectado'
    case 'I':
      return 'Inativo'
    default:
      return status
  }
}

export function getSignsStatusShoot(status: string): string {
  switch (status) {
    case 'N':
      return 'Novo'
    case 'E':
      return 'Em Andamento'
    case 'A':
      return 'Em Andamento'
    case 'F':
      return 'Finalizado'
    case 'C':
      return 'Cancelado'
    default:
      return status
  }
}
