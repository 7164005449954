import { BlacklistData } from './interface'

export const ComtemplayActionsTypes = {
  LIST_BLACKLISTED_QUOTAS_REQUEST: '@@contemplay/LIST_BLACKLISTED_QUOTAS_REQUEST',
  LIST_BLACKLISTED_QUOTAS_SUCCESS: '@@contemplay/LIST_BLACKLISTED_QUOTAS_SUCCESS',
  LIST_BLACKLISTED_QUOTAS_ERROR: '@@contemplay/LIST_BLACKLISTED_QUOTAS_ERROR',

  LIST_COTATRADE_ENGINE_REQUEST: '@@contemplay/LIST_COTATRADE_ENGINE_REQUEST',
  LIST_COTATRADE_ENGINE_SUCCESS: '@@contemplay/LIST_COTATRADE_ENGINE_SUCCESS',
  LIST_COTATRADE_ENGINE_ERROR: '@@contemplay/LIST_COTATRADE_ENGINE_ERROR',
}

export interface IComtemplayState {
  blacklistData: {
    blacklist?: BlacklistData
    loading: boolean
    error: boolean
  }
  changeCategoryData: {
    data?: BlacklistData
    loading: boolean
    error: boolean
  }
}
