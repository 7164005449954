import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { IApplicationState } from 'store'
import { tableStyle } from '../List/style'
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { faArrowLeft, faPaperclipVertical } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import TableCellItem from '../Blacklist/fragments/TableCellItem'
import { setBlacklistByIdRequest } from 'store/previas/actions'
import Dataframe from './fragments/Dataframe'

// eslint-disable-next-line
const Details: React.FC<RouteComponentProps<any>> = ({ match, history }) => {
  const classes = tableStyle()
  const { blacklistDetail } = useSelector((state: IApplicationState) => state.previas)
  const dispatch = useDispatch()
  const { id } = match?.params

  const [ascOrDesc, setAscOrDesc] = useState({
    date: 'desc',
  })

  const [sortedHistoric, setSortedHistoric] = useState<any>([])

  useEffect(() => {
    if (blacklistDetail.data.blacklist.historic) {
      setSortedHistoric([...blacklistDetail.data.blacklist.historic])
    }
    // eslint-disable-next-line
  }, [blacklistDetail])

  useEffect(() => {
    setSortedHistoric(
      blacklistDetail.data.blacklist.historic.sort((a: any, b: any) => {
        if (ascOrDesc.date === 'asc') {
          return new Date(a.date).getTime() - new Date(b.date).getTime()
        } else {
          return new Date(b.date).getTime() - new Date(a.date).getTime()
        }
      }),
    )
    // eslint-disable-next-line
  }, [ascOrDesc])

  const handleDownload = (file: string) => {
    window.open(file, '_blank')
  }

  useEffect(() => {
    if (id) dispatch(setBlacklistByIdRequest(id))
    // eslint-disable-next-line
  }, [id])
  return blacklistDetail.loading ? (
    <>Carregando...</>
  ) : (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
        <div style={{ padding: '20px', width: '70%' }}>
          <Typography variant="h3">
            <IconButton onClick={() => history.push('/previas/blacklist')}>
              <FontAwesomeIcon icon={faArrowLeft} />
              Voltar
            </IconButton>
          </Typography>
          <Typography variant="subtitle1" className={classes.titleList}>
            Detalhes da Blacklist
          </Typography>

          <TableContainer className={classes.containerStyled} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellItem
                    setAscOrDesc={setAscOrDesc}
                    ascOrDesc={ascOrDesc.date}
                    identification="date"
                    title="Registro"
                  />
                  <TableCellItem title="Usuário" />
                  <TableCellItem title="Status" />
                  <TableCellItem hasMinWidth={400} title="Descrição" />
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedHistoric.map((item: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography>{moment(item.date).utc().format('DD/MM/YYYY')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{item.user}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        <div className={classes.rowAlternative}>
                          <div
                            style={{
                              backgroundColor: item.blacklist ? '#CB2D1B' : '#377837',
                            }}
                            className={classes.status}
                          />
                          {item.blacklist ? ' Bloqueado' : ' Desbloqueado'}
                        </div>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography>{item.description}</Typography>
                        {item.file && (
                          <IconButton onClick={() => handleDownload(item.file)}>
                            <FontAwesomeIcon icon={faPaperclipVertical} />
                          </IconButton>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Dataframe />
      </div>
    </>
  )
}

export default Details
