import { NotificationTypes, INotificationState } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: INotificationState = {
  data: [],
}

export const notificationReducer = (
  state: INotificationState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): INotificationState => {
  switch (action.type) {
    case NotificationTypes.ADD_NOTIFICATION:
      return {
        data: [...state.data, action.payload.notification],
      }
    case NotificationTypes.REMOVE_NOTIFICATION:
      return {
        data: [...state.data.filter((e, i) => i !== action.payload.index)],
      }
    default:
      return state
  }
}
