import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import PrivateRoute from 'routes/PrivateRoute'
import { MenuItems } from 'routes/Routes'
import Loading from 'components/Loading'
import { IiFrameMenu } from 'store/modules_config/types'
import Helpers from 'shared/helpers'

interface DynamicComponentProps extends RouteComponentProps<any, any, IiFrameMenu> {
  title: string
}

const DynamicComponent: React.FC<DynamicComponentProps> = ({
  history: {
    location: { state },
  },
}) => {
  const [isLoading, setIsLoading] = useState(true)

  const getUrlWithParams = () => {
    const { params } = state

    if (params) {
      const keys = Object.keys(params)

      const qs = keys.map((s: any) => {
        const obj = params[s] as any

        if (obj.type === 'dynamic') {
          const user = Helpers.getUserLocalstorage()?.user || ({} as any)

          return `${s}=${user[obj.value]}`
        }

        return `${s}=${obj.value}`
      })

      console.log(`${state?.url}&${qs.join('&')}`)

      return `${state?.url}&${qs.join('&')}`
    }

    return state?.url
  }

  useEffect(() => {
    setIsLoading(true)
  }, [state.url])

  return (
    <Box>
      {isLoading && <Loading color="primary" />}
      <iframe
        title={' '}
        width="100%"
        height="780"
        src={getUrlWithParams()}
        frameBorder="0"
        allowFullScreen
        style={{ display: isLoading ? 'none' : 'block' }}
        onLoad={() => setIsLoading(false)}
      ></iframe>
    </Box>
  )
}

export const buildRouteAndSubRoute = (menu: MenuItems[]) => {
  const getPrivateRouteWithComponent = (items: MenuItems[]) => {
    return items
      .map((e, index: number) => (e.component !== undefined ? <PrivateRoute key={index} {...e} /> : undefined))
      .filter((e) => e !== undefined)
  }

  const main = getPrivateRouteWithComponent(menu)

  const subMenus = menu.map((e) => e.menus).length > 0 ? menu.map((e) => getPrivateRouteWithComponent(e.menus)) : []

  return [...main, ...subMenus]
}

export default withRouter(DynamicComponent)
