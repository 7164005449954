import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_DL,
})

instance.interceptors.request.use(

  (config) => {
    config.headers['Authorization'] = 'Basic YWRlbWljb246QGRlbWlsYXIyMDEwLg=='
    return config
  },
  (error) => Promise.reject(error)
)

instance.interceptors.response.use(
  (res) => {
    return Promise.resolve(res.data)
  },
  (error) => {
    return Promise.reject(error.response.data)
  },
)

export default instance
