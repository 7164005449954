import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'
import { PostSalesType, IPostSalesState } from './types'

export const initialState: IPostSalesState = {
    list: {
      loading: false,
      data: [],
      error: 'error loading list',
    },
    person: {
      loading: false,
      data: [],
      error: 'error loading list',
    },
    blacklist: {
      loading: false,
      data: [],
      error: '',
      pg: 0,
      pgs: 0,
    },
    conversation: {
      loading: false,
      data: [],
      error: '',
    },
    fullList: { loading: false, data: [], error: '' },
    dashboard: { loading: false, data: [], error: '' },
}

export const postSalesReducer = (
  state: IPostSalesState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IPostSalesState => {
  switch (action.type) {
    case PostSalesType.GET_POST_SALE_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          data: [],
          loading: true,
          error: false
        },
      }

    case PostSalesType.GET_POST_SALE_SUCCESS:  
    return {
        ...state,
        list: {
          loading: false,
          data: action.payload.data,
        }
      }

    case PostSalesType.GET_POST_SALE_FAILURE:
      return {
        ...state,
        list: {
          loading: false,
          error: true,
          data: []
        }
      }
    case PostSalesType.GET_BLACKLIST_REQUEST:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          data: [],
          loading: true,
          error: false
        },
      }
    case PostSalesType.GET_BLACKLIST_SUCCESS:
    return {
        ...state,
        blacklist: {
          loading: false,
          data: action.payload.data,
        }
      }
    case PostSalesType.GET_BLACKLIST_FAILURE:
      return {
        ...state,
        blacklist: {
          loading: false,
          error: true,
          data: []
        }
      }
    case PostSalesType.SET_BLACKLIST_REQUEST:
      return {
        ...state,
        blacklist: {
          loading: false,
          data: action.payload.data.phone,
        }
      }
    case PostSalesType.SET_BLACKLIST_SUCCESS:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          data: action.payload.data,
        }
      }

      case PostSalesType.GET_PERSON_REQUEST:
        return {
        ...state,
        person: {
          ...state.person,
          loading: true,
          error: false,
        },
        }
      case PostSalesType.GET_PERSON_SUCCESS:
        return {
        ...state,
        person: {
          loading: false,
          data: action.payload.data,
        },
        }
      case PostSalesType.GET_PERSON_FAILURE:
        return {
        ...state,
        person: {
          loading: false,
          error: true,
          data: [],
        },
        }
      case PostSalesType.GET_CONVERSATION_REQUEST:
        return {
        ...state,
        conversation: {
          ...state.conversation,
          loading: true,
          error: false,
        },
        }
      case PostSalesType.GET_CONVERSATION_SUCCESS:
        return {
        ...state,
        conversation: {
          loading: false,
          data: action.payload.data,
        },
        }
      case PostSalesType.GET_CONVERSATION_FAILURE:
        return {
        ...state,
        conversation: {
          loading: false,
          error: true,
          data: [],
        },
        }
      case PostSalesType.EXPORT_SALE_LIST_REQUEST:
        return {
        ...state,
        fullList: {
          ...state.fullList,
          loading: true,
          error: false,
        },
        }
        case PostSalesType.EXPORT_SALE_LIST_SUCCESS:
        return {
        ...state,
        fullList: {
          loading: false,
          data: action.payload.data,
        },
        }
        case PostSalesType.GET_DASHBOARD_REQUEST:
        return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: true,
          error: false,
        },
        }
        case PostSalesType.GET_DASHBOARD_SUCCESS:
        return {
        ...state,
        dashboard: {
          loading: false,
          data: action.payload.data,
        },
        }

      default:
        return state
    }
}
