import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'

const SignIn = lazy(() => import('pages/SignIn'))
const webView = lazy(() => import('pages/WebView'))

export default function AuthRoutes() {
  return (
    <Suspense fallback={''}>
      <Switch>
        <Route path="/login" component={SignIn} />
        <Route path="/web-view/:id" component={webView} />
      </Switch>
    </Suspense>
  )
}
