import { action } from 'typesafe-actions'
import { IRole, RolesTypes } from './types'

export const getRolesRequest = () => action(RolesTypes.GET_ROLES_REQUEST)
export const getRolesSuccess = (data: IRole[]) => action(RolesTypes.GET_ROLES_SUCCESS, { data })
export const getRolesFailure = () => action(RolesTypes.GET_ROLES_FAILURE)

export const createRoleRequest = (data: any) => action(RolesTypes.CREATE_ROLE_REQUEST, { data })

export const updateRoleRequest = (data: IRole) => action(RolesTypes.UPDATE_ROLE_REQUEST, { data })
