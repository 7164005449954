/* eslint-disable react/display-name */
import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { COTACASH_RESOURCE } from 'shared/resources'

const CotaCashList = lazy(() => import('pages/CotaCash/List/'))

export const CotaCashRoutes: MenuItems[] = [
  {
    path: '',
    name: 'CotaCash',
    iconComponent: Icons.Build,
    menus: [
      {
        path: `${COTACASH_RESOURCE}`,
        name: 'Carteira',
        component: CotaCashList,
        exact: true,
        menus: [],
      },
    ],
  },
]

export default () => <>{buildRouteAndSubRoute(CotaCashRoutes)}</>
