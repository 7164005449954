import * as serviceWorker from './serviceWorker'
import React from 'react'
import App from 'App'
import ReactDOM from 'react-dom'
import { CssBaseline } from '@material-ui/core'

ReactDOM.render(
  <React.Fragment>
    <CssBaseline />
    <App />
  </React.Fragment>,
  document.getElementById('root'),
)

serviceWorker.unregister()
