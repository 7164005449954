import { ModulesConfigTypes, IModulesConfigState } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: IModulesConfigState = {
  data: [],
  detail: {
    _id: '',
    icon: '',
    enabled: false,
    menus: [],
    name: '',
  },
  loading: false,
  error: '',
}

export const modulesConfigReducer = (
  state: IModulesConfigState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IModulesConfigState => {
  switch (action.type) {
    case ModulesConfigTypes.GET_MODULES_REQUEST:
    case ModulesConfigTypes.SAVE_MODULE_REQUEST:
    case ModulesConfigTypes.CREATE_MODULE_REQUEST:
    case ModulesConfigTypes.GET_MODULE_BYID_REQUEST:
    case ModulesConfigTypes.SAVE_MODULE_MENU_REQUEST:
    case ModulesConfigTypes.DELETE_MODULE_MENU_REQUEST:
    case ModulesConfigTypes.UPDATE_MODULE_MENU_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ModulesConfigTypes.GET_MODULES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      }
    case ModulesConfigTypes.GET_MODULES_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: 'Erro ao obter módulos.',
      }
    case ModulesConfigTypes.GET_MODULE_BYID_SUCCESS:
      return {
        ...state,
        detail: action.payload.data,
        loading: false,
      }
    case ModulesConfigTypes.GET_MODULE_BYID_FAILURE:
      return {
        ...state,
        loading: false,
        detail: { ...initialState.detail },
        error: 'Erro ao obter módulo.',
      }
    case ModulesConfigTypes.RESET_MODULE_DETAIL:
      return {
        ...state,
        detail: { ...initialState.detail },
      }
    default:
      return state
  }
}
