import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#cd260a',
    },
    secondary: {
      light: '#0066ff',
      main: '#A50B00',
      contrastText: '#ffcc00',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
})

export const themeDefaults = {
  palette: {
    primary: {
      main: '#cd260a',
    },
    secondary: {
      light: '#0066ff',
      main: '#A50B00',
      contrastText: '#ffcc00',
    },
    default: {
      light: '#44444a',
      main: '#59595b',
      contrastText: '#fff',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
}

export default theme
