import api from 'store/utils/apiv2'

import { put, call, takeLatest, all, fork } from 'redux-saga/effects'
import { getListSuccess, getListFailure } from './actions'
import { RecorrenciaTypes } from './types'
import { showToastr } from 'store/toastr/actions'

export function* handlerGetTransactions(action: any) {
  const { body } = action.payload

  try {
    const response = yield call(api.post, `hkl/consultapagamento`, { body })

    yield put(getListSuccess(response))
  } catch (err) {
    yield put(getListFailure())
    yield put(showToastr('Algo deu errado', 'error'))
  }
}

function* watchSagas(): Generator {
  yield takeLatest(RecorrenciaTypes.GET_LIST_REQUEST, handlerGetTransactions)
}

export default function* recorrenciaSagas() {
  yield all([fork(watchSagas)])
}
