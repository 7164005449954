import React from 'react'
import Navbar from 'components/Navbar'
import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: 'auto',
    overflow: 'auto',
  },
  container: {
    padding: theme.spacing(4),
  },
}))

const BaseContainer: React.FC = ({ children }) => {
  const classes = styles()

  return (
    <div className={classes.root}>
      <Navbar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.container}>{children}</div>
      </main>
    </div>
  )
}

export default BaseContainer
