import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { VERSIONING_APP_RESOURCE } from 'shared/resources'

const VersioningAppPage = lazy(() => import('pages/VersioningAppPage/versionApp'))

export const VersioningRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Versionamento APP',
    iconComponent: Icons.CalendarToday,
    menus: [
      {
        path: `${VERSIONING_APP_RESOURCE}/lista/:app`,
        name: 'Versionamento APP',
        component: VersioningAppPage,
        exact: true,
        menus: [],
      },
    ],
  },
]

export default function VersioningRoutesComponent() {
  return <>{buildRouteAndSubRoute(VersioningRoutes)}</>
}
