import { action } from 'typesafe-actions'
import { SalesMachineTypes } from './types'

export const getSalesMachineListRequest = (params) => action(SalesMachineTypes.GET_SALES_MACHINE_REQUEST, { params })
export const getSalesMachineListSuccess = (data: any) => action(SalesMachineTypes.GET_SALES_MACHINE_SUCCESS, { data })
export const getSalesMachineListFailure = () => action(SalesMachineTypes.GET_SALES_MACHINE_FAILURE)

export const getSalesMachineQRCodeRestoreRequest = (params) =>
  action(SalesMachineTypes.GET_SALES_QR_CODE_MACHINE_REQUEST, { params })
export const getSalesMachineQRCodeRestoreSuccess = (data: any) =>
  action(SalesMachineTypes.GET_SALES_QR_CODE_MACHINE_SUCCESS, { data })
export const getSalesMachineQRCodeRestoreFailure = () => action(SalesMachineTypes.GET_SALES_QR_CODE_MACHINE_FAILURE)
