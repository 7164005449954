import apiGrowth from 'store/utils/growth'
import { InsuranceSalesConsultantsTypes } from './types'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
  getInsuranceListSalesConsultantsSuccess,
  getInsuranceListSalesConsultantsFailure,
  getInsuranceDetailsSalesConsultantsSuccess,
  getInsuranceDetailsSalesConsultantsFailure,
  postInsuranceSalesConsultantsSuccess,
  postInsuranceSalesConsultantsFailure,
} from './actions'

// Listagem de operações de seguros
export function* getInsuranceSalesConstantsList(params) {
  const { pg, pgs, status, nome, grupo, cota } = params.payload.param
  try {
    const res: any = yield call(
      apiGrowth.get,
      `/seguro/v1/listagem?pg=${pg}&limit=${pgs}&status=${status}&nome=${nome}&grupo=${grupo}&cota=${cota}`,
    )
    yield put(getInsuranceListSalesConsultantsSuccess(res))
  } catch (err) {
    yield put(getInsuranceListSalesConsultantsFailure())
  }
}

// Operações de seguros: operação e id
export function* getInsuranceSalesConstantsDetails(params) {
  const { id, operation } = params.payload.param
  try {
    const res: any = yield call(apiGrowth.get, `/seguro/v1/get/${operation}/${id}`)
    yield put(getInsuranceDetailsSalesConsultantsSuccess(res))
  } catch (err) {
    yield put(getInsuranceDetailsSalesConsultantsFailure())
  }
}

// Edição das informações de uma operação
export function* postInsuranceSalesConstants(params) {
  const data = params.payload.param.params
  const body = params.payload.param.values

  try {
    const res: any = yield call(apiGrowth.post, `/seguro/v1/save/${data.operation}/${data.id}`, body)
    yield put(postInsuranceSalesConsultantsSuccess(res))
  } catch (err) {
    yield put(postInsuranceSalesConsultantsFailure())
  }
}

function* watchSagas(): Generator {
  yield takeLatest(InsuranceSalesConsultantsTypes.GET_INSURANCE_SALES_CONSTANTS_REQUEST, getInsuranceSalesConstantsList)
  yield takeLatest(
    InsuranceSalesConsultantsTypes.GET_INSURANCE_SALES_CONSTANTS_DETAILS_REQUEST,
    getInsuranceSalesConstantsDetails,
  )

  yield takeLatest(InsuranceSalesConsultantsTypes.POST_INSURANCE_SALES_CONSTANTS_REQUEST, postInsuranceSalesConstants)
}

export default function* insuranceSalesConstantsSagas() {
  yield all([fork(watchSagas)])
}
