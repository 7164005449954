import Bifrost from 'store/utils/bifrost'
import { put, call, takeLatest, all, fork } from 'redux-saga/effects'
import { UserTypes } from './types'
import { getUsersSuccess, getUsersFailure, getUsersRequest } from './actions'

const resource = '/users'

export function* handleGetUsers(action: any) {
  try {
    const res: any = yield call(Bifrost.get, `${resource}?source=${action.payload.origin}`)
    yield put(getUsersSuccess(res))
  } catch (err) {
    yield put(getUsersFailure())
  }
}

export function* handleUpdateUserRole(action: any) {
  const body = { funcao: action.payload.role }
  try {
    yield call(Bifrost.put, `${resource}/${action.payload.userId}`, body)
    yield put(getUsersRequest('google'))
  } catch (err) {
    yield put(getUsersFailure())
  }
}

function* watchRequest(): Generator {
  yield takeLatest(UserTypes.GET_USERS_REQUEST, handleGetUsers)
  yield takeLatest(UserTypes.UPDATE_USER_ROLE_REQUEST, handleUpdateUserRole)
}

export default function* userSagas() {
  yield all([fork(watchRequest)])
}
