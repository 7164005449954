export const PermissionsTypes = {
  GET_PERMISSIONS_REQUEST: '@@permissions/GET_PERMISSIONS_REQUEST',
  GET_PERMISSIONS_SUCCESS: '@@permissions/GET_PERMISSIONS_SUCCESS',
  GET_PERMISSIONS_FAILURE: '@@permissions/GET_PERMISSIONS_FAILURE',

  CREATE_PERMISSION_REQUEST: '@@permissions/CREATE_PERMISSION_REQUEST',
  UPDATE_PERMISSION_REQUEST: '@@permissions/UPDATE_PERMISSION_REQUEST',

  GET_DOMAINS_REQUEST: '@@permissions/GET_DOMAINS_REQUEST',
  GET_DOMAINS_SUCCESS: '@@permissions/GET_DOMAINS_SUCCESS',
  CREATE_DOMAIN_REQUEST: '@@permissions/CREATE_DOMAIN_REQUEST',
  UPDATE_DOMAIN_REQUEST: '@@permissions/UPDATE_DOMAIN_REQUEST',
}

export interface IRole {
  _id: string
  name: string
  enabled: boolean
  permissions: string[]
}

export interface IDomain {
  _id: string
  domain: string
  enabled: boolean
}

export interface IPermission {
  _id: string
  name: string
  enabled: boolean
  modules: string[]
}

export interface IPermissionRequest {
  name: string
  modules: string[]
}

export interface IPermissionsState {
  data: IPermission[]
  domains: IDomain[]
  loading: boolean
  error: string
}
