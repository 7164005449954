import {
  ListState,
  DetailState,
  LoadingImage,
  TypePublicState,
  DeleteState,
  DownloadCsvState,
  FilterModalCampaignState as FilterModalState,
  TagState,
  CategoriesState,
  FilterState,
} from './interface'

export const ComunicacaoTypes = {
  GET_COMUNICACAO_LIST_REQUEST: '@@comunicacao/GET_COMUNICACAO_GET_LIST_REQUEST',
  GET_COMUNICACAO_LIST_SUCCESS: '@@comunicacao/GET_COMUNICACAO_GET_LIST_SUCCESS',
  GET_COMUNICACAO_LIST_FAILURE: '@@comunicacao/GET_COMUNICACAO_GET_LIST_FAILURE',

  CREATE_COMUNICACAO_REQUEST: '@@comunicacao/CREATE_COMUNICACAO_REQUEST',
  CREATE_COMUNICACAO_SUCCESS: '@@comunicacao/CREATE_COMUNICACAO_SUCCESS',
  CREATE_COMUNICACAO_FAILURE: '@@comunicacao/CREATE_COMUNICACAO_FAILURE',

  GET_COMUNICACAO_DETAIL_REQUEST: '@@comunicacao/GET_COMUNICACAO_DETAIL_REQUEST',
  GET_COMUNICACAO_DETAIL_SUCCESS: '@@comunicacao/GET_COMUNICACAO_DETAIL_SUCCESS',
  GET_COMUNICACAO_DETAIL_FAILURE: '@@comunicacao/GET_COMUNICACAO_DETAIL_FAILURE',

  GET_COMUNICACAO_TYPE_PUBLIC_REQUEST: '@@comunicacao/GET_COMUNICACAO_TYPE_PUBLIC_REQUEST',
  GET_COMUNICACAO_TYPE_PUBLIC_SUCCESS: '@@comunicacao/GET_COMUNICACAO_TYPE_PUBLIC_SUCCESS',
  GET_COMUNICACAO_TYPE_PUBLIC_FAILURE: '@@comunicacao/GET_COMUNICACAO_TYPE_PUBLIC_FAILURE',

  UPDATE_COMUNICACAO_REQUEST: '@@comunicacao/UPDATE_COMUNICACAO_REQUEST',
  UPDATE_COMUNICACAO_SUCCESS: '@@comunicacao/UPDATE_COMUNICACAO_SUCCESS',
  UPDATE_COMUNICACAO_FAILURE: '@@comunicacao/UPDATE_COMUNICACAO_FAILURE',

  DELETE_COMUNICACAO_REQUEST: '@@comunicacao/DELETE_COMUNICACAO_REQUEST',
  DELETE_COMUNICACAO_SUCCESS: '@@comunicacao/DELETE_COMUNICACAO_SUCCESS',
  DELETE_COMUNICACAO_FAILURE: '@@comunicacao/DELETE_COMUNICACAO_FAILURE',

  LIST_CATEGORIES_REQUEST: '@@comunicacao/LIST_CATEGORIES_REQUEST',
  LIST_CATEGORIES_SUCCESS: '@@comunicacao/LIST_CATEGORIES_SUCCESS',
  LIST_CATEGORIES_FAILURE: '@@comunicacao/LIST_CATEGORIES_FAILURE',

  /*   GET_COMUNICACAO_LIST_PRODUCTS_REQUEST: '@@comunicacao/GET_COMUNICACAO_GET_LIST_PRODUCTS_REQUEST',
  GET_COMUNICACAO_LIST_PRODUCTS_SUCCESS: '@@comunicacao/GET_COMUNICACAO_GET_LIST_PRODUCTS_SUCCESS',
  GET_COMUNICACAO_LIST_PRODUCTS_FAILURE: '@@comunicacao/GET_COMUNICACAO_GET_LIST_PRODUCTS_FAILURE', */

  SEND_COMUNICACAO_REQUEST: '@@comunicacao/SEND_COMUNICACAO_REQUEST',
  SEND_COMUNICACAO_SUCCESS: '@@comunicacao/SEND_COMUNICACAO_SUCCESS',
  SEND_COMUNICACAO_FAILURE: '@@comunicacao/SEND_COMUNICACAO_FAILURE',

  CLEAR_COMUNICACAO: '@@comunicacao/CLEAR_COMUNICACAO',

  GET_DOWNLOAD_CSV_COMUNICACAO_REQUEST: '@@comunicacao/GET_DOWNLOAD_CSV_COMUNICACAO_REQUEST',
  GET_DOWNLOAD_CSV_COMUNICACAO_SUCCESS: '@@comunicacao/GET_DOWNLOAD_CSV_COMUNICACAO_SUCCESS',
  GET_DOWNLOAD_CSV_COMUNICACAO_FAILURE: '@@comunicacao/GET_DOWNLOAD_CSV_COMUNICACAO_FAILURE',

  GET_SEARCH_CAMPAIGN_REQUEST: '@@comunicacao/GET_SEARCH_CAMPAIGN_REQUEST',
  GET_SEARCH_CAMPAIGN_SUCCESS: '@@comunicacao/GET_SEARCH_CAMPAIGN_SUCCESS',
  GET_SEARCH_CAMPAIGN_FAILURE: '@@comunicacao/GET_SEARCH_CAMPAIGN_FAILURE',

  GET_MODAL_FILTERS_REQUEST: '@@comunicacao/GET_MODAL_FILTERS_REQUEST',
  GET_MODAL_FILTERS_SUCCESS: '@@comunicacao/GET_MODAL_FILTERS_SUCCESS',
  GET_MODAL_FILTERS_FAILURE: '@@comunicacao/GET_MODAL_FILTERS_FAILURE',

  GET_CAMPANHA_LIST_REQUEST: '@@comunicacao/GET_CAMPANHA_GET_LIST_REQUEST',
  GET_CAMPANHA_LIST_SUCCESS: '@@comunicacao/GET_CAMPANHA_GET_LIST_SUCCESS',
  GET_CAMPANHA_LIST_FAILURE: '@@comunicacao/GET_CAMPANHA_GET_LIST_FAILURE',

  GET_CAMPANHA_DETAIL_REQUEST: '@@comunicacao/GET_CAMPANHA_DETAIL_REQUEST',
  GET_CAMPANHA_DETAIL_SUCCESS: '@@comunicacao/GET_CAMPANHA_DETAIL_SUCCESS',
  GET_CAMPANHA_DETAIL_FAILURE: '@@comunicacao/GET_CAMPANHA_DETAIL_FAILURE',

  CREATE_CAMPANHA_REQUEST: '@@comunicacao/CREATE_CAMPANHA_REQUEST',
  CREATE_CAMPANHA_SUCCESS: '@@comunicacao/CREATE_CAMPANHA_SUCCESS',
  CREATE_CAMPANHA_FAILURE: '@@comunicacao/CREATE_CAMPANHA_FAILURE',

  SEND_CAMPANHA_REQUEST: '@@comunicacao/SEND_CAMPANHA_REQUEST',
  SEND_CAMPANHA_SUCCESS: '@@comunicacao/SEND_CAMPANHA_SUCCESS',
  SEND_CAMPANHA_FAILURE: '@@comunicacao/SEND_CAMPANHA_FAILURE',

  GET_CAMPANHA_TYPE_PUBLIC_REQUEST: '@@comunicacao/GET_CAMPANHA_TYPE_PUBLIC_REQUEST',
  GET_CAMPANHA_TYPE_PUBLIC_SUCCESS: '@@comunicacao/GET_CAMPANHA_TYPE_PUBLIC_SUCCESS',
  GET_CAMPANHA_TYPE_PUBLIC_FAILURE: '@@comunicacao/GET_CAMPANHA_TYPE_PUBLIC_FAILURE',

  DELETE_CAMPANHA_REQUEST: '@@comunicacao/DELETE_CAMPANHA_REQUEST',
  DELETE_CAMPANHA_SUCCESS: '@@comunicacao/DELETE_CAMPANHA_SUCCESS',
  DELETE_CAMPANHA_FAILURE: '@@comunicacao/DELETE_CAMPANHA_FAILURE',

  SPOTLIGHT_CAMPANHA_REQUEST: '@@comunicacao/SPOTLIGHT_CAMPANHA_REQUEST',
  SPOTLIGHT_CAMPANHA_SUCCESS: '@@comunicacao/SPOTLIGHT_CAMPANHA_SUCCESS',
  SPOTLIGHT_CAMPANHA_FAILURE: '@@comunicacao/SPOTLIGHT_CAMPANHA_FAILURE',

  UPDATE_CAMPANHA_REQUEST: '@@comunicacao/UPDATE_CAMPANHA_REQUEST',
  UPDATE_CAMPANHA_SUCCESS: '@@comunicacao/UPDATE_CAMPANHA_SUCCESS',
  UPDATE_CAMPANHA_FAILURE: '@@comunicacao/UPDATE_CAMPANHA_FAILURE',

  LIST_TAGS_REQUEST: '@@comunicacao/LIST_TAGS_REQUEST',
  LIST_TAGS_SUCCESS: '@@comunicacao/LIST_TAGS_SUCCESS',
  LIST_TAGS_FAILURE: '@@comunicacao/LIST_TAGS_FAILURE',

  CLEAR_CAMPANHA: '@@comunicacao/CLEAR_CAMPANHA',

  GET_SEARCH_CAMPANHA_REQUEST: '@@comunicacao/GET_SEARCH_CAMPANHA_REQUEST',
  GET_SEARCH_CAMPANHA_SUCCESS: '@@comunicacao/GET_SEARCH_CAMPANHA_SUCCESS',
  GET_SEARCH_CAMPANHA_FAILURE: '@@comunicacao/GET_SEARCH_CAMPANHA_FAILURE',

  GET_MODAL_FILTERS_CAMPANHA_REQUEST: '@@comunicacao/GET_MODAL_FILTERS_CAMPANHA_REQUEST',
  GET_MODAL_FILTERS_CAMPANHA_SUCCESS: '@@comunicacao/GET_MODAL_FILTERS_CAMPANHA_SUCCESS',
  GET_MODAL_FILTERS_CAMPANHA_FAILURE: '@@comunicacao/GET_MODAL_FILTERS_CAMPANHA_FAILURE',
}

export interface IComunicacaoState {
  // Notificação
  list: ListState
  details: DetailState
  new: DetailState
  uploadImage: LoadingImage
  typePublic: TypePublicState
  delete: DeleteState
  downloadCsv: DownloadCsvState
  filterComunicacao: FilterState
  filterModalComunicacao: FilterModalState
  categoriesState: CategoriesState

  // Campanhas
  campanhas: ListState
  campanhaDetail: DetailState
  campanhaCreate: LoadingImage
  campanhaDelete: DeleteState
  campaignSpotlight: {
    loading: boolean
  }
  tags: TagState
  filterCampanha: FilterState
  filterModalCampanha: FilterModalState
}
