import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'
import { AssemblyTypes, IAssemblyState } from './types'

export const initialState: IAssemblyState = {
  selects: {
    years: [],
    currentYear: '',
    currentonth: '',
  },
  results: {
    loading: false,
    data: [],
    error: '',
  },
  lines: {
    loading: false,
    data: [],
    error: '',
  },
  lotteries: {
    loading: false,
    data: [],
    error: '',
  },
  datatime: '',
  new: {
    loading: false,
    success: '',
    error: '',
  },
  edit: {
    loading: false,
    success: '',
    error: '',
  },
}

export const assemblyReducer = (
  state: IAssemblyState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IAssemblyState => {
  switch (action.type) {
    case AssemblyTypes.GET_ASSEMBLY_SELECTS_SUCCESS:
      //console.log(action.payload.res.years,'aqui')
      return {
        ...state,
        selects: {
          ...state.selects,
          years: action.payload.res.years,
        },
      }

    case AssemblyTypes.GET_ASSEMBLY_SELECTS_CURRENT:
      console.log(action)
      return {
        ...state,
        selects: {
          ...state.selects,
          currentMonth: action.payload.month,
          currentYear: action.payload.year,
        },
      }

    case AssemblyTypes.GET_ASSEMBLY_DEFAULT_REQUEST:
      return {
        ...state,
        results: { loading: true },
        lines: { loading: true },
        lotteries: { loading: true },
      }

    case AssemblyTypes.GET_ASSEMBLY_DEFAULT_SUCCESS:
      return {
        ...state,
        results: {
          loading: false,
          data: action.payload.res.results,
        },
        lines: {
          data: action.payload.res.lines,
        },
        lotteries: {
          data: action.payload.res.lotteries,
        },
        datatime: action.payload.res.data,
      }

    case AssemblyTypes.GET_ASSEMBLY_DEFAULT_FAILURE:
      return {
        ...state,
        results: { error: 'error' },
        lines: { error: 'error' },
        lotteries: { error: 'error' },
      }

    case AssemblyTypes.CREATE_NEW_ASSEMBLY_REQUEST:
      return {
        ...state,
        new: {
          loading: true,
          success: false,
          error: false,
        },
      }

    case AssemblyTypes.CREATE_NEW_ASSEMBLY_SUCCESS:
      return {
        ...state,
        new: {
          loading: false,
          success: action.payload.res.message,
          error: '',
        },
      }

    case AssemblyTypes.CREATE_NEW_ASSEMBLY_FAILURE:
      return {
        ...state,
        new: {
          loading: false,
          success: '',
          error: action.payload.res.response.data.message,
        },
      }

    case AssemblyTypes.UPDATE_ASSEMBLY_REQUEST:
      return {
        ...state,
        edit: {
          loading: true,
          success: false,
          error: false,
        },
      }

    case AssemblyTypes.UPDATE_ASSEMBLY_CLEAR:
      return {
        ...state,
        edit: {
          loading: false,
          success: '',
          error: '',
        },
      }

    case AssemblyTypes.UPDATE_ASSEMBLY_SUCCESS:
      return {
        ...state,
        edit: {
          loading: false,
          success: action.payload.res.message,
          error: '',
        },
      }

    case AssemblyTypes.UPDATE_ASSEMBLY_FAILURE:
      return {
        ...state,
        edit: {
          loading: false,
          success: '',
          error: action.payload.res.response.data.message,
        },
      }

    default:
      return state
  }
}
