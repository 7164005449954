import api from 'store/utils/apiv2'
import clientApi from 'store/utils/clienteApi'
import { showToastr } from 'store/toastr/actions'
import { VersionAppTypes } from './types'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
  getVersionAvaSuccess,
  getVersionAvaFailure,
  getLastVersionAvaSuccess,
  getLastVersionAvaFailure,
  postVersionAvaSuccess,
  postVersionAvaFailure,
} from './actions'

// Listagem de todos as versões
export function* listVersions(action: any) {
  const ademicon_tenent = process.env.REACT_APP_ADEMICON_TENANT

  try {
    switch (action?.payload?.params?.app) {
      case 'ava':
        const res: any = yield call(api.get, `/v2/versions?tenant=${ademicon_tenent}`)
        yield put(getVersionAvaSuccess(res))
        break
      case 'cliente':
        const res1: any = yield call(clientApi.get, `/versions`)
        yield put(getVersionAvaSuccess(res1))
        break
      default:
        yield put(showToastr('Ocorreu um erro!', 'error'))
    }
  } catch (err) {
    yield put(getVersionAvaFailure())
  }
}

// Listagem da última versão
export function* listLastVersions(action: any) {
  const ademicon_tenent = process.env.REACT_APP_ADEMICON_TENANT
  const plataforma = action.payload.params.plataforma

  try {
    switch (action?.payload?.params?.app) {
      case 'ava':
        const res: any = yield call(api.get, `/v2/versions/last?tenant=${ademicon_tenent}&plataform=${plataforma}`)
        yield put(getLastVersionAvaSuccess(res))
        break
      case 'cliente':
        const res1: any = yield call(clientApi.get, `/versions/last?plataform=${plataforma}`)
        yield put(getLastVersionAvaSuccess(res1))
        break
      default:
        yield put(showToastr('Ocorreu um erro!', 'error'))
    }
  } catch (err) {
    yield put(getLastVersionAvaFailure())
  }
}

// POST nova versão
export function* postVersionAva(action: any) {
  const ademicon_tenent = process.env.REACT_APP_ADEMICON_TENANT
  const data = action?.payload?.data

  try {
    switch (action?.payload?.params) {
      case 'ava':
        const res: any = yield call(api.post, `/v2/versions?tenant=${ademicon_tenent}`, data)
        yield put(postVersionAvaSuccess(res))
        yield put(showToastr('Nova versão cadastrada!', 'success'))
        break
      case 'cliente':
        const res1: any = yield call(clientApi.post, `/versions`, data)
        yield put(postVersionAvaSuccess(res1))
        yield put(showToastr('Nova versão cadastrada!', 'success'))
        break
      default:
        yield put(showToastr('Ocorreu um erro!', 'error'))
    }
  } catch (err) {
    yield put(postVersionAvaFailure())
  }
}

function* watchSagas(): Generator {
  yield takeLatest(VersionAppTypes.GET_VERSION_APP_REQUEST, listVersions)
  yield takeLatest(VersionAppTypes.GET_LAST_VERSION_APP_REQUEST, listLastVersions)
  yield takeLatest(VersionAppTypes.POST_VERSION_APP_REQUEST, postVersionAva)
}

export default function* versionAppSagas() {
  yield all([fork(watchSagas)])
}
