import { faEnvelopeOpenText, faUserMinus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearBlacklistFile, setBlacklistByIdRequest, setRemoveBlacklistRequest } from 'store/previas/actions'
import ConfirmationModal from 'components/ConfirmationModal'
import Dropzone from './Dropzone'
import { IApplicationState } from 'store'

const RemoveBlacklistModal: React.FC<any> = ({ open, setOpen }) => {
  const dispatch = useDispatch()
  const { blacklistDetail, blacklist } = useSelector((state: IApplicationState) => state.previas)
  const [data, setData] = useState({
    reason: '',
    file: '',
  })

  const [errors, setErrors] = useState({
    reason: false,
    file: false,
  })

  useEffect(() => {
    setData({
      reason: '',
      file: '',
    })
    dispatch(clearBlacklistFile())
    // eslint-disable-next-line
  }, [open])

  const handleConfirm = () => {
    if (data.reason === '' || data.file === '') {
      setErrors({
        ...errors,
        reason: data.reason === '',
        file: data.file === '',
      })
      return
    }
    setErrors({
      reason: false,
      file: false,
    })
    dispatch(
      setRemoveBlacklistRequest({
        cpfCnpj: blacklistDetail.data.blacklist.cpfCnpj,
        description: data.reason,
        file: data.file,
      }),
    )
    dispatch(setBlacklistByIdRequest(blacklistDetail.data.blacklist._id))
    dispatch(clearBlacklistFile())
    setOpen(false)
  }

  const handleData = (key: string, value: string) => {
    if (errors[key]) setErrors({ ...errors, [key]: false })
    setData({ ...data, [key]: value })
  }

  useEffect(() => {
    if (blacklist.file) {
      setErrors({
        ...errors,
        file: false,
      })
      setData({ ...data, file: blacklist.file.location })
    }
    // eslint-disable-next-line
  }, [blacklist.file])

  return (
    <ConfirmationModal
      hasTop
      width="30%"
      open={open}
      onCancel={() => setOpen(false)}
      onClose={() => setOpen(false)}
      onConfirm={() => handleConfirm()}
      cancelText="Cancelar"
      confirmText="Adicionar"
      loading={blacklist.loading}
    >
      <Typography variant="h6">
        <FontAwesomeIcon icon={faUserMinus} /> Remover da Blacklist
      </Typography>
      <Typography variant="subtitle1">
        Deseja remover <b>{blacklistDetail.data.blacklist.name}</b> da Blacklist? Ele poderá se tornar um candidato e
        iniciar um novo processo em prévias.
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '15px' }}>
        <Typography>
          <FontAwesomeIcon icon={faEnvelopeOpenText} /> Motivo
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          id="reason"
          name="reason"
          autoComplete="reason"
          label="Motivo"
          value={data.reason ?? ''}
          onChange={(e) => handleData('reason', e.target.value)}
          size="small"
          multiline
          minRows={4}
          maxRows={4}
        />
      </div>
      <Dropzone hasError={errors.file} />
    </ConfirmationModal>
  )
}

export default withRouter(RemoveBlacklistModal)
