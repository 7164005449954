import { ListState, ListContractsState } from './interface'

export const AntecipayTypes = {
  GET_LIST_REQUEST: '@@antecipay/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: '@@antecipay/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: '@@antecipay/GET_LIST_FAILURE',
  UPDATE_LIST_REQUEST: '@@antecipay/UPDATE_LIST_REQUEST',
  UPDATE_LIST_SUCCESS: '@@antecipay/UPDATE_LIST_SUCCESS',
  UPDATE_LIST_FAILURE: '@@antecipay/UPDATE_LIST_FAILURE',

  GET_LIST_CONTRACTS_REQUEST: '@@antecipay/GET_LIST_CONTRACTS_REQUEST',
  GET_LIST_CONTRACTS_SUCCESS: '@@antecipay/GET_LIST_CONTRACTS_SUCCESS',
  GET_LIST_CONTRACTS_FAILURE: '@@antecipay/GET_LIST_CONTRACTS_FAILURE',

  GET_LIST_CONTRACTS_TYPES_REQUEST: '@@antecipay/GET_LIST_CONTRACTS_TYPES_REQUEST',
  GET_LIST_CONTRACTS_TYPES_SUCCESS: '@@antecipay/GET_LIST_CONTRACTS_TYPES_SUCCESS',
  GET_LIST_CONTRACTS_TYPES_FAILURE: '@@antecipay/GET_LIST_CONTRACTS_TYPES_FAILURE',

  GET_CONTRACT_REQUEST: '@@antecipay/GET_CONTRACT_REQUEST',
  GET_CONTRACT_SUCCESS: '@@antecipay/GET_CONTRACT_SUCCESS',
  GET_CONTRACT_FAILURE: '@@antecipay/GET_CONTRACT_FAILURE',
}

export interface IAntecipayState {
  list: ListState
  item: ContratoSigns
  contratos: ListContractsState
}

export interface ContratoSigns {
  created_at: string
  email: string
  id: string
  envelope: string
  tipo: string
  status: string
  download: string
  contrato: string
  assinaturas: Assinaturas[]
}

export interface Assinaturas {
  id: number
  envelope: string
  status: string
  referencia: string
  email: string
  notifica: string
}

export interface ContratoDetals {
  nome: string
  email: string
}

export function getStatusLabel(status: string): string {
  switch (status.toLowerCase()) {
    case 'new':
      return 'Novo'
    case 'in progress':
      return 'Em Progresso'
    case 'completed':
      return 'Concluído'
    default:
      return status
  }
}

export function getTipoLabel(tipo: string): string {
  switch (tipo.toLowerCase()) {
    case 'cartafianca':
      return 'Carta Fiança'
    case 'cessao':
      return 'Termo de Cessão'
    case 'mae':
      return 'Termo de Securitização'
    default:
      return tipo
  }
}

export function getSignsStatus(status: string): string {
  switch (status.toLowerCase()) {
    case 'p':
      return 'Aguardando'
    case 'c':
      return 'Assinado'
    default:
      return status
  }
}
