import { action } from 'typesafe-actions'
import { ComtemplayActionsTypes } from './types'

export const getBlacklistRequest = (body: any) =>
  action(ComtemplayActionsTypes.LIST_BLACKLISTED_QUOTAS_REQUEST, { body })

export const getBlacklistSuccess = (data: any) =>
  action(ComtemplayActionsTypes.LIST_BLACKLISTED_QUOTAS_SUCCESS, { data })

export const getBlacklistFailure = () => action(ComtemplayActionsTypes.LIST_BLACKLISTED_QUOTAS_ERROR)

export const getCotatradeEngineQuotas = (body: any) =>
  action(ComtemplayActionsTypes.LIST_COTATRADE_ENGINE_REQUEST, { body })

export const getCotatradeEngineQuotasSuccess = (data: any) =>
  action(ComtemplayActionsTypes.LIST_COTATRADE_ENGINE_SUCCESS, { data })

export const getCotatradeEngineQuotasFailure = () => action(ComtemplayActionsTypes.LIST_COTATRADE_ENGINE_ERROR)
