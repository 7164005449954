import React, { useEffect, useState } from 'react'
import { Button, Box, TextField, Grid, Typography, FormGroup, FormControlLabel, Switch, Link } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { IApplicationState } from 'store'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
  getModuleByIdRequest,
  resetModuleDetail,
  saveModuleRequest,
  createModuleRequest,
  saveModuleMenuRequest,
  deleteModuleMenuRequest,
  updateModuleMenuRequest,
} from 'store/modules_config/actions'
import { IMenu, IModule } from 'store/modules_config/types'
import Loading from 'components/Loading'
import Icon from '@material-ui/core/Icon'
import { getPermissionsRequest } from 'store/permissions/actions'
import AddMenu from './AddMenu'

const initialMenu: IMenu = {
  _id: undefined,
  name: '',
  path: '',
  type: 'internal',
  permissions: [],
  module: '',
}

const ModuleConfigEdit: React.FC<RouteComponentProps<any>> = ({ match }) => {
  const dispatch = useDispatch()
  const state = useSelector((selector: IApplicationState) => ({
    detail: selector.modulesConfig.detail,
    loading: selector.modulesConfig.loading,
    permissions: selector.permissions.data,
  }))

  const [module, setModule] = useState<IModule>(state.detail)
  const [newSubMenu, setNewSubMenu] = useState<IMenu>({ ...initialMenu })

  const handleOnChangeModuleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModule({
      ...module,
      name: e.target.value,
    })
  }

  const handleOnChangeModuleIcon = (e: React.ChangeEvent<any>) => {
    setModule({
      ...module,
      icon: e.target.value,
    })
  }

  const handleAddSubMenu = (menu: IMenu) => {
    if (menu.name !== '' && menu.path !== '') {
      const moduleMenus = module.menus || []
      setModule({
        ...module,
        menus: [...moduleMenus, menu],
      })
      dispatch(saveModuleMenuRequest(menu, module._id))
      setNewSubMenu({ ...initialMenu })
    }
  }

  const handleRemoveSubMenu = (menu: IMenu) => {
    dispatch(deleteModuleMenuRequest(menu, module._id))
  }

  const handleOnMenuUpdate = (updatedSubMenu: IMenu) => {
    dispatch(updateModuleMenuRequest(updatedSubMenu, module._id))
  }

  const handleEnableModule = (e: React.ChangeEvent<HTMLInputElement>) =>
    setModule({ ...module, enabled: e.target.checked })

  const handleOnSaveModule = () => dispatch(saveModuleRequest(module))

  const handleOnCreateModule = () => dispatch(createModuleRequest(module))

  useEffect(() => {
    if (match.params.id) {
      dispatch(getModuleByIdRequest(match.params.id))
      dispatch(getPermissionsRequest())
    }

    return () => {
      dispatch(resetModuleDetail())
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    setModule(state.detail)
  }, [state.detail])

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Box marginBottom={4}>
            <Grid container spacing={2} alignItems="center">
              <Grid item md={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Nome Módulo"
                  value={module.name}
                  onChange={handleOnChangeModuleName}
                  helperText={' '}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Icone"
                  value={module.icon}
                  onChange={handleOnChangeModuleIcon}
                  helperText={
                    <Link href="https://material-ui.com/pt/components/material-icons/" target="_blank">
                      Lista de icones
                    </Link>
                  }
                />
              </Grid>
              <Grid item>
                <Typography align="center">
                  <Icon>{module.icon}</Icon>
                </Typography>
              </Grid>
              <Grid item>
                <FormGroup row>
                  <FormControlLabel
                    control={<Switch checked={module.enabled} onChange={handleEnableModule} name="enabled" />}
                    label="Ativo"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Box>

          <Box marginBottom={2}>
            <Typography variant="h6">Sub Menus</Typography>
          </Box>

          {module.menus?.map((menu, i) => {
            return (
              <AddMenu
                key={i}
                menu={menu}
                onPermissionChange={(menu: IMenu) => handleOnMenuUpdate(menu)}
                updateSubMenu={handleOnMenuUpdate}
                onSubmit={() => handleRemoveSubMenu(menu)}
                type="edit"
              />
            )
          })}

          <AddMenu
            menu={newSubMenu}
            updateSubMenu={() => null}
            onPermissionChange={() => null}
            onSubmit={handleAddSubMenu}
            type="add"
          />
        </Grid>
      </Grid>

      <Box marginTop={4}>
        <Button
          disabled={state.loading}
          variant="contained"
          color="primary"
          size="large"
          onClick={match.params.id ? handleOnSaveModule : handleOnCreateModule}
        >
          {state.loading ? <Loading /> : 'Salvar'}
        </Button>
      </Box>
    </React.Fragment>
  )
}

export default withRouter(ModuleConfigEdit)
