import { action } from 'typesafe-actions'
import { AssemblyMotorsTypes } from './types'

export const getAssemblySelectsRequest = () => action(AssemblyMotorsTypes.GET_ASSEMBLY_MOTORS_SELECTS_REQUEST)
export const getAssemblySelectsSuccess = (res: any) => action(AssemblyMotorsTypes.GET_ASSEMBLY_MOTORS_SELECTS_SUCCESS, { res })
export const getAssemblySelectsFailure = () => action(AssemblyMotorsTypes.GET_ASSEMBLY_MOTORS_SELECTS_SUCCESS)
export const getAssemblySelectsCurrent = (month: string, year: string) =>
  action(AssemblyMotorsTypes.GET_ASSEMBLY_MOTORS_SELECTS_CURRENT, { month, year })

export const getAssemblyDefaultRequest = (month: string, year: string) =>
  action(AssemblyMotorsTypes.GET_ASSEMBLY_MOTORS_DEFAULT_REQUEST, { month, year })
export const getAssemblyDefaultSuccess = (res: any) => action(AssemblyMotorsTypes.GET_ASSEMBLY_MOTORS_DEFAULT_SUCCESS, { res })
export const getAssemblyDefaultFailure = () => action(AssemblyMotorsTypes.GET_ASSEMBLY_MOTORS_DEFAULT_SUCCESS)

export const createNewAssemblyRequest = (body: any) => action(AssemblyMotorsTypes.CREATE_NEW_ASSEMBLY_MOTORS_REQUEST, { body })
export const createNewAssemblySuccess = (res: any) => action(AssemblyMotorsTypes.CREATE_NEW_ASSEMBLY_MOTORS_SUCCESS, { res })
export const createNewAssemblyFailure = (res: any) => action(AssemblyMotorsTypes.CREATE_NEW_ASSEMBLY_MOTORS_FAILURE, { res })

export const updateAssemblyRequest = (body: any) => action(AssemblyMotorsTypes.UPDATE_ASSEMBLY_MOTORS_REQUEST, { body })
export const updateAssemblySuccess = (res: any) => action(AssemblyMotorsTypes.UPDATE_ASSEMBLY_MOTORS_SUCCESS, { res })
export const updateAssemblyFailure = (res: any) => action(AssemblyMotorsTypes.UPDATE_ASSEMBLY_MOTORS_FAILURE, { res })
export const updateAssemblyClear = () => action(AssemblyMotorsTypes.UPDATE_ASSEMBLY_MOTORS_CLEAR)
