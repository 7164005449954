export const RolesTypes = {
  GET_ROLES_REQUEST: '@@permissions/GET_ROLES_REQUEST',
  GET_ROLES_SUCCESS: '@@permissions/GET_ROLES_SUCCESS',
  GET_ROLES_FAILURE: '@@permissions/GET_ROLES_FAILURE',

  CREATE_ROLE_REQUEST: '@@permissions/CREATE_ROLE_REQUEST',
  UPDATE_ROLE_REQUEST: '@@permissions/UPDATE_ROLE_REQUEST',
}

export interface IRole {
  _id: string
  name: string
  enabled: boolean
  permissions: string[]
}

export interface IRolesState {
  data: IRole[]
  loading: boolean
  error: string
}
