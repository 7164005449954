import api from 'store/utils/apiv2'
import { put, call, takeLatest, all, fork } from 'redux-saga/effects'
import {
  getListSuccess,
  getListFailure,
  getUserDetailFailure,
  getUserDetailSuccess,
  getUserDetailRequest,
  getListRequest,
  updateListSuccess,
  updateListFailure,
} from './actions'

import { RetencaoTypes } from './types'
import { showToastr } from 'store/toastr/actions'

export function* handlerGetList() {
  try {
    const res: any = yield call(api.get, `/backoffice/retencao`)
    yield put(getListSuccess(res))
  } catch (err) {
    yield put(getListFailure())
  }
}

export function* handlerGetUserDetail(action: any) {
  const { id } = action.payload

  try {
    const res: any = yield call(api.get, `/backoffice/retencao/${id}`)
    yield put(getUserDetailSuccess(res))
  } catch (err) {
    yield put(getUserDetailFailure())
  }
}

export function* handlerUpdateList(action: any) {
  const { retencaoid, body } = action.payload
  try {
    const res: any = yield call(api.put, `/backoffice/retencao/${retencaoid}`, body) ///backoffice/retencao
    yield put(updateListSuccess(res))
    yield put(getListRequest())
    yield put(getUserDetailRequest(retencaoid))
    yield put(showToastr('Status atualizado!', 'success'))
  } catch (err) {
    yield put(updateListFailure())
  }
}

function* watchSagas(): Generator {
  yield takeLatest(RetencaoTypes.GET_LIST_REQUEST, handlerGetList)
  yield takeLatest(RetencaoTypes.GET_USER_DETAIL_REQUEST, handlerGetUserDetail)
  //yield takeLatest(RetencaoTypes.GET_HISTORY_LIST_REQUEST, handlerGetHistoryList)
  yield takeLatest(RetencaoTypes.UPDATE_LIST_REQUEST, handlerUpdateList)
}
export default function* retencaoSagas() {
  yield all([fork(watchSagas)])
}
