import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { removeNotification } from 'store/notification/actions'
import { IApplicationState } from 'store'
import Styled from './Notifications.styled'
import { Typography, Grow } from '@material-ui/core'
import { RouteComponentProps, withRouter } from 'react-router-dom'

const Notifications: React.FC<RouteComponentProps<any>> = () => {
  const dispatch = useDispatch()
  const { notifications } = useSelector((state: IApplicationState) => ({
    notifications: state.notifications.data,
  }))

  return (
    <Styled.Container>
      {notifications.map((item, i) => (
        <Grow in={true} key={i}>
          <Styled.Wrap
            key={i}
            onClick={() => {
              if (item.action != null) item.action()
              dispatch(removeNotification(i))
            }}
          >
            <Typography variant="body2" gutterBottom>
              <strong>{item.message}</strong>
            </Typography>
            <Typography style={{ color: '#9ca4a4' }} variant="subtitle1">
              <small>{item.subMessage}</small>
            </Typography>
          </Styled.Wrap>
        </Grow>
      ))}
      {notifications.length === 0 && (
        <Styled.Wrap empty={true}>
          <Typography variant="body2">
            <strong>Nenhuma notificação no momento</strong>
          </Typography>
        </Styled.Wrap>
      )}
    </Styled.Container>
  )
}

export default withRouter(Notifications)
