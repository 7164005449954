export const UserTypes = {
  GET_USERS_REQUEST: '@@user/GET_USERS_REQUEST',
  GET_USERS_SUCCESS: '@@user/GET_USERS_SUCCESS',
  GET_USERS_FAILURE: '@@user/GET_USERS_FAILURE',

  UPDATE_USER_ROLE_REQUEST: '@@user/UPDATE_USER_ROLE_REQUEST',
}

export interface IUserState {
  data: any[]
  list: any[]
  loading: boolean
  error: string
}
