import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { UNIDADE_VENDAS_RESOURCE } from 'shared/resources'

const UnidadeVendasListPage = lazy(() => import('pages/UnidadeVendas/List'))

export const UnidadeVendasRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Unidade de Leads',
    iconComponent: Icons.Money,
    menus: [
      {
        path: `${UNIDADE_VENDAS_RESOURCE}/lista`,
        name: 'Lista',
        component: UnidadeVendasListPage,
        exact: true,
        menus: [],
      },
    ],
  },
]

export default function UnidadeVendasRoutesComponent() {
  return <>{buildRouteAndSubRoute(UnidadeVendasRoutes)}</>
}
