/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { INADIMPLENCIA_RETENCAO } from 'shared/resources'

const InadimplenciaListPage = lazy(() => import('pages/Inadimplencia/List'))

const UserDetail = lazy(() => import('pages/Inadimplencia/UserDetail'))

const WhatsAppPage = lazy(() => import('pages/WhatsApp'))
const WhatsAppAccount = lazy(() => import('pages/WhatsApp/account'))
const WhatsAppNewShoot = lazy(() => import('pages/WhatsApp/newShoot'))
const WhatsAppNewAccount = lazy(() => import('pages/WhatsApp/newAccount'))
const WhatsAppViewAccount = lazy(() => import('pages/WhatsApp/viewAccount'))
const WhatsAppViewShoot = lazy(() => import('pages/WhatsApp/viewShoot'))

const EligibilityCotaEquity = lazy(() => import('pages/CotaEquity/Verification'))

export const InadimpleciaRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Inadimplência',
    iconComponent: Icons.Assessment,
    menus: [
      {
        path: `${INADIMPLENCIA_RETENCAO}/retencao`,
        name: 'Retencao',
        component: InadimplenciaListPage,
        exact: true,
        menus: [],
      },
      {
        path: `${INADIMPLENCIA_RETENCAO}/retencao/whatsapp`,
        name: 'Whatsapp',
        exact: true,
        component: WhatsAppPage,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${INADIMPLENCIA_RETENCAO}/retencao/whatsapp/account`,
        name: 'Whatsapp',
        component: WhatsAppAccount,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${INADIMPLENCIA_RETENCAO}/retencao/whatsapp/newShoot`,
        name: 'Whatsapp',
        component: WhatsAppNewShoot,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${INADIMPLENCIA_RETENCAO}/retencao/whatsapp/viewShoot/:id`,
        name: 'Whatsapp',
        component: WhatsAppViewShoot,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${INADIMPLENCIA_RETENCAO}/retencao/whatsapp/newAccount`,
        name: 'Whatsapp',
        component: WhatsAppNewAccount,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${INADIMPLENCIA_RETENCAO}/retencao/whatsapp/viewAccount/:id`,
        name: 'Whatsapp',
        component: WhatsAppViewAccount,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${INADIMPLENCIA_RETENCAO}/historico/:route/:id`,
        name: 'Detail',
        component: UserDetail,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${INADIMPLENCIA_RETENCAO}/retencao/cota-equity`,
        name: 'CotaEquity',
        component: EligibilityCotaEquity,
        isSubRoute: true,
        menus: [],
      },
    ],
  },
]

export default () => <>{buildRouteAndSubRoute(InadimpleciaRoutes)}</>
