import { CotaEquityTypes } from './types'
import { all, fork, takeLatest } from 'redux-saga/effects'

// Listagem de Unidades
export function* postVerificationCpf(action: any) {
  yield null
}

function* watchSagas(): Generator {
  yield takeLatest(CotaEquityTypes.GET_COTA_EQUITY_REQUEST, postVerificationCpf)
}

export default function* previasSagas() {
  yield all([fork(watchSagas)])
}
