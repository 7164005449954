import { action } from 'typesafe-actions'
import { InsuranceTypes } from './types'

export const getInsuranceListRequest = (param) => action(InsuranceTypes.GET_INSURANCE_REQUEST, param)
export const getInsuranceListSuccess = (data: any) => action(InsuranceTypes.GET_INSURANCE_SUCCESS, { data })
export const getInsuranceListFailure = () => action(InsuranceTypes.GET_INSURANCE_FAILURE)

export const getInsuranceListByIdRequest = (id: any) => action(InsuranceTypes.GET_INSURANCE_BY_ID_REQUEST, { id })
export const getInsuranceListByIdSuccess = (data: any) => action(InsuranceTypes.GET_INSURANCE_BY_ID_SUCCESS, { data })
export const getInsuranceListByIdFailure = () => action(InsuranceTypes.GET_INSURANCE_BY_ID_FAILURE)

export const postAcceptOrDeclineDpsRequest = (params: any) =>
  action(InsuranceTypes.POST_ACCEPT_OR_DECLINE_REQUEST, { params })
export const postAcceptOrDeclineDpsSuccess = (data: any) =>
  action(InsuranceTypes.POST_ACCEPT_OR_DECLINE_SUCCESS, { data })
export const postAcceptOrDeclineDpsFailure = () => action(InsuranceTypes.POST_ACCEPT_OR_DECLINE_FAILURE)

export const getInsuranceExportRequest = () => action(InsuranceTypes.GET_INSURANCE_EXPORT_REQUEST)
export const getInsuranceExportSuccess = (data: any) => action(InsuranceTypes.GET_INSURANCE_EXPORT_SUCCESS, { data })
export const getInsuranceExportFailure = () => action(InsuranceTypes.GET_INSURANCE_EXPORT_FAILURE)
