import React from 'react'
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core'
import clsx from 'clsx'
import MenuIcon from '@material-ui/icons/Menu'
import Drawer from '@material-ui/core/Drawer'
import styles from './Navbar.styles'
import NavbarItems from 'components/NavbarItems'
import Logo from 'components/Logo'
import NavbarNotification from 'components/NavbarNotification'
import { Close } from '@material-ui/icons'
// import DarkTheme from '@material-ui/icons/Brightness4Outlined'
// import { ThemeCtx } from 'App'

const Navbar = () => {
  const classes = styles()
  const [open, setOpen] = React.useState(true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <>
      <AppBar position="absolute" elevation={0} className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Admin
          </Typography>
          {/* <ThemeCtx.Consumer>
            {({ toggleTheme }) => (
              <IconButton color="inherit" onClick={toggleTheme}>
                <DarkTheme />
              </IconButton>
            )}
          </ThemeCtx.Consumer> */}
          <NavbarNotification />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <Logo size={170} />
          <IconButton onClick={handleDrawerClose}>
            <Close />
          </IconButton>
        </div>
        <div className={classes.widthFull}>
          <div className={classes.separator} />
        </div>
        <NavbarItems />
      </Drawer>
    </>
  )
}

export default Navbar
