import React from 'react'
import styled from 'styled-components'
import logo from 'assets/images/Logobkstg.png'

interface ILogoProps {
  size?: number
}

const Logo = styled.img`
  width: 100%;
  max-width: ${(props: ILogoProps) => `${props.size}px` || '300px'};
`

export default function LogoComponent({ size = 300 }: ILogoProps) {
  return <Logo src={logo} {...{ size }} />
}
