import { CaasTypes, ICaasState } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: ICaasState = {
  updateSimulators: {
    loading: false,
    data: [],
    error: '',
  },
  getSimulators: {
    loading: false,
    data: [],
    error: '',
  },
  getConsortiums: {
    loading: false,
    data: [],
    error: '',
  },
  getBanners: {
    loading: false,
    data: [],
    error: '',
  },
  updateBanners: {
    loading: false,
    data: [],
    error: '',
  },
  getRegulation: {
    loading: false,
    data: [],
    error: '',
  },
  getRegulationById: {
    loading: false,
    data: [],
    error: '',
  },
  updateRegulation: {
    loading: false,
    data: [],
    error: '',
  },
  createRegulation: {
    loading: false,
    data: [],
    error: '',
  },
  deleteRegulation: {
    loading: false,
    data: [],
    error: '',
  },
}

export const caasReducer = (
  state: ICaasState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): ICaasState => {
  switch (action.type) {
    case CaasTypes.UPDATE_CAAS_SIMULATORS_SUCCESS:
      return {
        ...state,
        updateSimulators: action.payload,
      }

    case CaasTypes.GET_CAAS_SIMULATORS_SUCCESS:
      return {
        ...state,
        getSimulators: action.payload,
      }

    case CaasTypes.GET_CAAS_CONSORTIUMS_SUCCESS:
      return {
        ...state,
        getConsortiums: action.payload,
      }

    case CaasTypes.GET_CAAS_BANNER_SUCCESS:
      return {
        ...state,
        getBanners: action.payload,
      }

    case CaasTypes.UPDATE_CAAS_BANNER_SUCCESS:
      return {
        ...state,
        updateBanners: action.payload,
      }
    case CaasTypes.GET_CAAS_REGULATION_SUCCESS:
      return {
        ...state,
        getRegulation: action.payload,
      }
    case CaasTypes.GET_CAAS_REGULATION_BY_ID_REQUEST:
      return {
        ...state,
        getRegulationById: {
          loading: true,
          data: [],
          error: '',
        },
      }
    case CaasTypes.GET_CAAS_REGULATION_BY_ID_SUCCESS:
      return {
        ...state,
        getRegulationById: {
          loading: false,
          data: action.payload.data,
          error: '',
        },
      }
    case CaasTypes.DELETE_CAAS_REGULATION_BY_ID_SUCCESS:
      return {
        ...state,
        getRegulationById: {
          loading: false,
          data: action.payload.data,
          error: '',
        },
      }
    case CaasTypes.DELETE_CAAS_REGULATION_BY_ID_REQUEST:
      return {
        ...state,
        getRegulationById: {
          loading: false,
          data: action.payload.data,
          error: '',
        },
      }
    case CaasTypes.DELETE_CAAS_REGULATION_BY_ID_FAILURE:
      return {
        ...state,
        getRegulationById: {
          loading: false,
          data: action.payload.data,
          error: '',
        },
      }
    case CaasTypes.UPDATE_CAAS_REGULATION_REQUEST:
      return {
        ...state,
        updateRegulation: {
          loading: false,
          data: [],
          error: '',
        },
      }
    case CaasTypes.UPDATE_CAAS_REGULATION_SUCCESS:
      return {
        ...state,
        updateRegulation: action.payload,
      }
    case CaasTypes.CREATE_CAAS_REGULATION_SUCCESS:
      return {
        ...state,
        createRegulation: action.payload,
      }
    default:
      return state
  }
}
