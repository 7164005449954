import { SalesMachineTypes, ISalesMachineState } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: ISalesMachineState = {
  salesMachine: {
    loading: false,
    data: [],
    error: '',
    pg: 0,
    pgs: 0,
  },
  restoreQRCoce: {
    loading: false,
    data: [],
    error: '',
    pg: 0,
    pgs: 0,
  },
}

export const salesMachineReducer = (
  state: ISalesMachineState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): ISalesMachineState => {
  switch (action.type) {
    case SalesMachineTypes.GET_SALES_MACHINE_REQUEST:
      return {
        ...state,
        salesMachine: {
          loading: true,
        },
      }
    case SalesMachineTypes.GET_SALES_MACHINE_SUCCESS:
      return {
        ...state,
        salesMachine: action.payload,
      }
    case SalesMachineTypes.GET_SALES_QR_CODE_MACHINE_REQUEST:
      return {
        ...state,
        restoreQRCoce: {
          loading: true,
        },
      }
    case SalesMachineTypes.GET_SALES_QR_CODE_MACHINE_SUCCESS:
      return {
        ...state,
        restoreQRCoce: action.payload,
      }

    default:
      return state
  }
}
