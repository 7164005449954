import { action } from 'typesafe-actions'
import { AntecipayTypes } from './types'

export const getListRequest = () => action(AntecipayTypes.GET_LIST_REQUEST)
export const getListSuccess = (data: any) => action(AntecipayTypes.GET_LIST_SUCCESS, { data })
export const getListFailure = () => action(AntecipayTypes.GET_LIST_FAILURE)

export const updateListRequest = (antecipayid: string, status: string) =>
  action(AntecipayTypes.UPDATE_LIST_REQUEST, { antecipayid, status })
export const updateListSuccess = (data: any) => action(AntecipayTypes.UPDATE_LIST_SUCCESS, { data })
export const updateListFailure = () => action(AntecipayTypes.UPDATE_LIST_FAILURE)

// LIST CONTRACTS
export const getListContractsRequest = (param: any) => action(AntecipayTypes.GET_LIST_CONTRACTS_REQUEST, param)
export const getListContractsSuccess = (data: any) => action(AntecipayTypes.GET_LIST_CONTRACTS_SUCCESS, { data })
export const getListContractsFailure = () => action(AntecipayTypes.GET_LIST_CONTRACTS_FAILURE)

// GET ALL CONTRACTS TYPES
export const getListContractsTypesRequest = () => action(AntecipayTypes.GET_LIST_CONTRACTS_TYPES_REQUEST)
export const getListContractsTypesSuccess = (data: any) =>
  action(AntecipayTypes.GET_LIST_CONTRACTS_TYPES_SUCCESS, { data })
export const getListContractsTypesFailure = () => action(AntecipayTypes.GET_LIST_CONTRACTS_TYPES_FAILURE)

export const getContractRequest = (contrato: string) =>
  action(AntecipayTypes.GET_CONTRACT_REQUEST, { contrato: contrato })
export const getContractSuccess = (data: any) => action(AntecipayTypes.GET_CONTRACT_SUCCESS, { data })
export const getContractFailure = () => action(AntecipayTypes.GET_CONTRACT_FAILURE)
