import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

interface PageTitleProps {
  title: string | undefined
}

const style = makeStyles((theme) => ({
  group: {
    marginBottom: theme.spacing(4),
  },
}))

const PageTitle: React.FC<PageTitleProps> = ({ title = '' }) => {
  const classes = style()

  return (
    <div className={classes.group}>
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
    </div>
  )
}

export default PageTitle
