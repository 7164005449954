import apiSalesMachine from 'store/utils/salesMachine'
import { SalesMachineTypes } from './types'
import { showToastr } from 'store/toastr/actions'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { getSalesMachineListSuccess, getSalesMachineListFailure } from './actions'

// Listagem dos usuários
export function* getSalesMachineList(action: any) {
  const { pg, nome, email, matricula } = action.payload.params

  try {
    const res: any = yield call(
      apiSalesMachine.get,
      `/users?pg=${pg}&nome=${nome}&email=${email}&matricula=${matricula}`,
    )
    yield put(getSalesMachineListSuccess(res))
  } catch (err) {
    yield put(getSalesMachineListFailure())
  }
}

// Restore QR Code
export function* getSalesMachineQRCodeRestoreRequest(action: any) {
  try {
    const res: any = yield call(apiSalesMachine.post, `/tokens`, { id_user: action.payload.params })
    yield put(getSalesMachineListSuccess(res))
    yield put(showToastr('QR Code atualizado!', 'success'))
  } catch (err) {
    yield put(getSalesMachineListFailure())
  }
}

function* watchSagas(): Generator {
  yield takeLatest(SalesMachineTypes.GET_SALES_MACHINE_REQUEST, getSalesMachineList)
  yield takeLatest(SalesMachineTypes.GET_SALES_QR_CODE_MACHINE_REQUEST, getSalesMachineQRCodeRestoreRequest)
}

export default function* previasSagas() {
  yield all([fork(watchSagas)])
}
