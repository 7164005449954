import React from 'react'
import { Modal, Card, CardContent, makeStyles, CardHeader, CardActions, Box } from '@material-ui/core'
import Button from 'components/Button'
import Loading from 'components/Loading'

interface IConfirmationModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  onCancel: () => void
  title?: string
  cancelText?: string
  confirmText?: string
  width?: string
  hideCancelButton?: boolean
  hideYesButton?: boolean
  hasTop?: boolean
  loading?: boolean
}

const style = makeStyles((theme) => ({
  wrap: {
    position: 'absolute',
    width: 400,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  end: {
    justifyContent: 'end',
    paddingBottom: theme.spacing(3),
  },
  refresh: {
    '& button': {
      width: '12.5rem',
      cursor: 'pointer',
      height: 40,
      display: 'flex',
      color: 'white',
      alignItems: 'center',
      borderColor: 'transparent',
      borderRadius: '0.25rem',
      backgroundColor: '#CB2D1B',
    },
    '& button:hover': {
      backgroundColor: '#ed2a14',
    },
  },
  buttomPreview: {
    '& button': {
      color: '#525151',
      width: '10.4375rem',
      cursor: 'pointer',
      height: 40,
      display: 'flex',
      fontSize: 17,
      wordWrap: 'break-word',
      alignItems: 'center',
      marginRight: 6,
      justifyContent: 'center',
    },
    '& button:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
}))

const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
  open,
  onCancel,
  onClose,
  onConfirm,
  children,
  title,
  cancelText,
  confirmText,
  width,
  hideCancelButton,
  hasTop,
  hideYesButton,
  loading,
}) => {
  const classes = style()

  return (
    <Modal {...{ open, onClose }}>
      <div className={classes.wrap} style={{ width: width, top: hasTop ? '10%' : '25%' }}>
        <Card>
          {title && <CardHeader title={title} />}
          <CardContent>{children}</CardContent>
          <div>
            <CardActions className={classes.end}>
              {loading ? (
                <Loading color="primary" />
              ) : (
                <>
                  {hideCancelButton !== true && (
                    <Box marginRight={2} className={classes.buttomPreview}>
                      <Button variant="text" onClick={onCancel}>
                        {!cancelText ? 'Não' : cancelText}
                      </Button>
                    </Box>
                  )}
                  {hideYesButton !== true && (
                    <Box marginRight={2} className={classes.refresh}>
                      <Button variant="outlined" onClick={onConfirm} type="positive">
                        {!confirmText ? 'Sim' : confirmText}
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </CardActions>
          </div>
        </Card>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
