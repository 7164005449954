import Axios from 'axios'

export async function serviceGetAssemblySelect(): Promise<any> {
  const res: any = await Axios.get(
    `${process.env.REACT_APP_API_GROWTH}/api/assembleias/?act=assembleias_selects`,
  )
  return res && res.data ? res.data : 'error'
}

export async function serviceGetAssemblyResults(month: string, year: string): Promise<any> {
  const res: any = await Axios.get(
    `${process.env.REACT_APP_API_GROWTH}/api/assembleias/?act=assembleias_persist&param=${month}/${year}`,
  )
  return res && res.data ? res.data : 'error'
}

export async function serviceNewAssembly(body: any) {
  const res: any = await Axios.post(
    `${process.env.REACT_APP_API_GROWTH}/api/assembleias/?act=assembleias_persist`,
    JSON.stringify(body),
  )
  return res && res.data ? res.data : 'error'
}

export async function serviceUpdateAssembly(body: any) {
  const res: any = await Axios.put(
    `${process.env.REACT_APP_API_GROWTH}/api/assembleias/?act=assembleias_persist`,
    JSON.stringify(body),
  )
  return res && res.data ? res.data : 'error'
}
