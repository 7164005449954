import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { POST_SALES_RESOUCE } from 'shared/resources'

const PostSalesListPage = lazy(() => import('pages/PostSales/List'))
const PostSalesDetailsPage = lazy(() => import('pages/PostSales/Details'))
const BlacklistPage = lazy(() => import('pages/PostSales/Blacklist'))
const Dashboard = lazy(() => import('pages/PostSales/Dashboard'))


export const PosVendasRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Pos Vendas',
    iconComponent: Icons.AttachMoney,
    menus: [
      {
        path: `${POST_SALES_RESOUCE}/lista`,
        name: 'Lista',
        component: PostSalesListPage,
        exact: true,
        menus: [],
      },
      {
        path: `${POST_SALES_RESOUCE}/details/:id`,
        name: 'Visualizar detalhes',
        component: PostSalesDetailsPage,
        exact: true,
        menus: [],
      },
      {
        path: `${POST_SALES_RESOUCE}/blacklist`,
        name: 'Blacklist',
        component: BlacklistPage,
        exact: true,
        menus: [],
      },
      {
        path: `${POST_SALES_RESOUCE}/dashboard`,
        name: 'Dashboard',
        component: Dashboard,
        exact: true,
        menus: [],
      }
    ]
  }
]

export default function PosVendasRoutesComponent() {
  return <>{buildRouteAndSubRoute(PosVendasRoutes)}</>
}
