export const NotificationTypes = {
  ADD_NOTIFICATION: '@@notification/ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: '@@notification/REMOVE_NOTIFICATION',
}

export interface INotification {
  action: (() => void) | null
  message: string
  subMessage?: string
}

export interface INotificationState {
  data: INotification[]
}
