import { IModule } from 'store/modules_config/types'

export const AuthActionTypes = {
  SIGNIN_REQUEST: '@@auth/SIGNIN_REQUEST',
  SIGNIN_SUCCESS: '@@auth/SIGNIN_SUCCESS',
  SIGNIN_ERROR: '@@auth/SIGNIN_ERROR',
  LOGOUT: '@@auth/LOGOUT',
  SET_USER: '@@auth/SET_USER',
}

export interface IAuthState {
  readonly data: IAuth | null
  readonly token: string | undefined
  readonly loading: boolean
  readonly error: string
}

export interface ISignInRequest {
  identifier: string | undefined
  password: string | undefined
  google?: string
  microsoft?: string
}

export interface ISignInResponse {
  token: string
}

export type IOrigin = 'newcon' | 'google' | 'microsoft'

export interface IApiUser {
  user: {
    _id: string
    name: string
    email: string
    funcao: string
    enabled: boolean
    source: string
    cdUsuario: string
    cdUsuarioExterno: string
    // 'a:cdusuario': string
    // 'a:cdusuarioexterno': string
    // 'a:cpfcnpj': string
    // 'a:dataexpiracao': string
    // 'a:datahorapenultimoacesso': string
    // 'a:datahoraultimoacesso': string
    // 'a:datanascimentofundacao': string
    // 'a:idempresa': string
    // 'a:idusuario': string
    // 'a:nomefuncao': string
    // 'a:nomeusuario': string
    // 'a:prazoexpiracao': string
  }
  modules: any[]
}

export interface IAuth {
  name: string
  role: string
  modules: IModule[]
}

export interface IUser {
  _id: string
  identifier: string
  name: string
  funcao: string
  cdUsuario: string
  cdUsuarioExterno: string
}
