import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  item: {
    paddingLeft: 5,
  },
  wrap: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
  color: {
    backgroundColor: '#BEBEBE',
    boxShadow: ' 0 7px 7px inset #A9A9A9',
  },
  widthFull: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  separator: {
    width: '87%',
    height: 1,
    backgroundColor: '#D1D3D4',
    marginBottom: 16,
  },
}))
