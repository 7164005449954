import { InsuranceSalesConsultantsTypes, IInsurancesSalesConstantsState } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: IInsurancesSalesConstantsState = {
  list: {
    loading: false,
    data: [],
    error: '',
    pg: 0,
    pgs: 0,
  },
  details: {
    loading: false,
    data: [],
    error: '',
    pg: 0,
    pgs: 0,
  },
}

export const insuranceSaleConsultantsReducer = (
  state: IInsurancesSalesConstantsState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IInsurancesSalesConstantsState => {
  switch (action.type) {
    case InsuranceSalesConsultantsTypes.GET_INSURANCE_SALES_CONSTANTS_REQUEST:
      return {
        ...state,
        list: {
          loading: true,
          data: [],
          error: '',
        },
      }
    case InsuranceSalesConsultantsTypes.GET_INSURANCE_SALES_CONSTANTS_SUCCESS:
      return {
        ...state,
        list: {
          loading: false,
          data: action.payload,
          error: '',
        },
      }
    case InsuranceSalesConsultantsTypes.GET_INSURANCE_SALES_CONSTANTS_FAILURE:
      return {
        ...state,
        list: {
          loading: false,
          data: action.payload,
          error: 'Erro ao obter dados.',
        },
      }

    case InsuranceSalesConsultantsTypes.GET_INSURANCE_SALES_CONSTANTS_DETAILS_REQUEST:
      return {
        ...state,
        details: {
          loading: true,
          data: [],
          error: '',
        },
      }
    case InsuranceSalesConsultantsTypes.GET_INSURANCE_SALES_CONSTANTS_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          loading: false,
          data: action.payload,
          error: '',
        },
      }
    case InsuranceSalesConsultantsTypes.GET_INSURANCE_SALES_CONSTANTS_DETAILS_FAILURE:
      return {
        ...state,
        details: {
          loading: false,
          data: action.payload,
          error: 'Erro ao obter dados.',
        },
      }
    default:
      return state
  }
}
