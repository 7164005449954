import { InsuranceTypes, IInsuranceState } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: IInsuranceState = {
  list: {
    loading: false,
    data: [],
    error: '',
    pg: 0,
    pgs: 0,
  },
  details: {
    loading: false,
    data: [],
    error: '',
    pg: 0,
    pgs: 0,
    message: false,
  },
  actionInsurance: {
    loading: false,
    data: [],
    error: '',
  },
  export: {
    loading: false,
    data: [],
    error: '',
  },
}

export const insuranceReducer = (
  state: IInsuranceState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IInsuranceState => {
  switch (action.type) {
    case InsuranceTypes.GET_INSURANCE_REQUEST:
      return {
        ...state,
        list: {
          loading: true,
          data: [],
          error: '',
        },
      }
    case InsuranceTypes.GET_INSURANCE_SUCCESS:
      return {
        ...state,
        list: {
          loading: false,
          data: action.payload.data,
          error: '',
        },
      }
    case InsuranceTypes.GET_INSURANCE_BY_ID_REQUEST:
      return {
        ...state,
        details: {
          loading: false,
          data: action.payload,
          error: '',
          message: false,
        },
      }
    case InsuranceTypes.GET_INSURANCE_BY_ID_SUCCESS:
      return {
        ...state,
        details: {
          loading: false,
          data: action.payload,
          error: '',
        },
      }
    case InsuranceTypes.POST_ACCEPT_OR_DECLINE_SUCCESS:
      return {
        ...state,
        actionInsurance: {
          loading: false,
          data: action.payload.data,
          error: '',
        },
      }
    case InsuranceTypes.GET_INSURANCE_EXPORT_SUCCESS:
      return {
        ...state,
        export: {
          loading: false,
          data: action.payload,
          error: '',
        },
      }
    default:
      return state
  }
}
