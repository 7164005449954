import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import ModulesConfigEditPage from 'pages/ModulesConfig/Edit'
import { MODULESCONFIG_RESOURCE } from 'shared/resources'

const ModulesConfigListPage = lazy(() => import('pages/ModulesConfig/List'))

export const ModulesConfigRoutes: MenuItems[] = [
  {
    path: `${MODULESCONFIG_RESOURCE}`,
    name: 'Módulos',
    exact: true,
    component: ModulesConfigListPage,
    iconComponent: Icons.ViewModule,
    menus: [],
  },
  {
    path: `${MODULESCONFIG_RESOURCE}/editar`,
    name: 'Edição de Módulos',
    component: ModulesConfigEditPage,
    exact: true,
    isSubRoute: true,
    menus: [],
  },
  {
    path: `${MODULESCONFIG_RESOURCE}/editar/:id`,
    name: 'Edição de Módulos',
    component: ModulesConfigEditPage,
    isSubRoute: true,
    menus: [],
  },
]

export default function ModulesConfigRoutesComponent() {
  return <>{buildRouteAndSubRoute(ModulesConfigRoutes)}</>
}
