import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { PREVIAS_RESOURCE } from 'shared/resources'
import Details from 'pages/Previas/Blacklist/Details'

const PreviasListPage = lazy(() => import('pages/Previas/List'))
const UserDetail = lazy(() => import('pages/Previas/UserDetail'))
const PreviasMotivosPadrao = lazy(() => import('pages/Previas/MotivosPadrao'))
const Blacklist = lazy(() => import('pages/Previas/Blacklist'))

export const PreviasRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Previas',
    iconComponent: Icons.Money,
    menus: [
      {
        path: `${PREVIAS_RESOURCE}/lista-candidatos`,
        name: 'Lista',
        component: PreviasListPage,
        exact: true,
        menus: [],
      },
      {
        path: `${PREVIAS_RESOURCE}/candidato/:route/:id`,
        name: 'Informações do candidato',
        component: UserDetail,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${PREVIAS_RESOURCE}/motivospadrao`,
        name: 'Painel de configuração dos Motivos Padrões',
        component: PreviasMotivosPadrao,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${PREVIAS_RESOURCE}/blacklist`,
        name: 'Listagem de blacklist',
        component: Blacklist,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${PREVIAS_RESOURCE}/blacklist-historic/:id`,
        name: 'Detalhes da Blacklist',
        component: Details,
        isSubRoute: true,
        menus: [],
      },
    ],
  },
]

export default function PreviasRoutesComponent() {
  return <>{buildRouteAndSubRoute(PreviasRoutes)}</>
}
