export const LpUnityTypes = {
  GET_LP_UNITY_REQUEST: '@@LP_UNITY/GET_LP_UNITY_REQUEST',
  GET_LP_UNITY_SUCCESS: '@@LP_UNITY/GET_LP_UNITY_SUCCESS',
  GET_LP_UNITY_FAILURE: '@@LP_UNITY/GET_LP_UNITY_FAILURE',

  GET_LP_UNITY_DETAILS_REQUEST: '@@LP_UNITY/GET_LP_UNITY_DETAILS_REQUEST',
  GET_LP_UNITY_DETAILS_SUCCESS: '@@LP_UNITY/GET_LP_UNITY_DETAILS_SUCCESS',
  GET_LP_UNITY_DETAILS_FAILURE: '@@LP_UNITY/GET_LP_UNITY_DETAILS_FAILURE',

  GET_LP_UNITY_CREATE_REQUEST: '@@LP_UNITY/GET_LP_UNITY_CREATE_REQUEST',
  GET_LP_UNITY_CREATE_SUCCESS: '@@LP_UNITY/GET_LP_UNITY_CREATE_SUCCESS',
  GET_LP_UNITY_CREATE_FAILURE: '@@LP_UNITY/GET_LP_UNITY_CREATE_FAILURE',

  PUT_LP_UNITY_REQUEST: '@@LP_UNITY/PUT_LP_UNITY_REQUEST',
  PUT_LP_UNITY_SUCCESS: '@@LP_UNITY/PUT_LP_UNITY_SUCCESS',
  PUT_LP_UNITY_FAILURE: '@@LP_UNITY/PUT_LP_UNITY_FAILURE',
}

export interface ILpUnityState {
  list: Record<string, any>
  details: Record<string, any>
  create: Record<string, any>
  update: Record<string, any>
}
