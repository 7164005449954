import { action } from 'typesafe-actions'
import { AssemblyTypes } from './types'

export const getAssemblySelectsRequest = () => action(AssemblyTypes.GET_ASSEMBLY_SELECTS_REQUEST)
export const getAssemblySelectsSuccess = (res: any) => action(AssemblyTypes.GET_ASSEMBLY_SELECTS_SUCCESS, { res })
export const getAssemblySelectsFailure = () => action(AssemblyTypes.GET_ASSEMBLY_SELECTS_SUCCESS)
export const getAssemblySelectsCurrent = (month: string, year: string) =>
  action(AssemblyTypes.GET_ASSEMBLY_SELECTS_CURRENT, { month, year })

export const getAssemblyDefaultRequest = (month: string, year: string) =>
  action(AssemblyTypes.GET_ASSEMBLY_DEFAULT_REQUEST, { month, year })
export const getAssemblyDefaultSuccess = (res: any) => action(AssemblyTypes.GET_ASSEMBLY_DEFAULT_SUCCESS, { res })
export const getAssemblyDefaultFailure = () => action(AssemblyTypes.GET_ASSEMBLY_DEFAULT_SUCCESS)

export const createNewAssemblyRequest = (body: any) => action(AssemblyTypes.CREATE_NEW_ASSEMBLY_REQUEST, { body })
export const createNewAssemblySuccess = (res: any) => action(AssemblyTypes.CREATE_NEW_ASSEMBLY_SUCCESS, { res })
export const createNewAssemblyFailure = (res: any) => action(AssemblyTypes.CREATE_NEW_ASSEMBLY_FAILURE, { res })

export const updateAssemblyRequest = (body: any) => action(AssemblyTypes.UPDATE_ASSEMBLY_REQUEST, { body })
export const updateAssemblySuccess = (res: any) => action(AssemblyTypes.UPDATE_ASSEMBLY_SUCCESS, { res })
export const updateAssemblyFailure = (res: any) => action(AssemblyTypes.UPDATE_ASSEMBLY_FAILURE, { res })
export const updateAssemblyClear = () => action(AssemblyTypes.UPDATE_ASSEMBLY_CLEAR)
