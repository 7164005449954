import React from 'react'
import { useDispatch } from 'react-redux'
import AuthRoutes from './Auth'
import DashboardRoutes from './Dashboard'
import { setUserState } from 'store/auth/actions'
import Helpers from 'shared/helpers'

import { PREVIAS_RESOURCE, REPASSE_RESOURCE } from 'shared/resources'
import { addNotification } from 'store/notification/actions'
import { showToastr } from 'store/toastr/actions'
import moment from 'moment'
import { channel } from 'App'
import { withRouter } from 'react-router-dom'

const Routes: React.FC = ({ history }: any) => {
  const dispatch = useDispatch()

  const handleRepasseNotification = (id: string) => history.push(`${REPASSE_RESOURCE}/negociacoes/${id}`)
  const handlePreviasNotification = (id: string) => history.push(`${PREVIAS_RESOURCE}/candidato/0/${id}`)

  React.useEffect(() => {
    channel.bind('negotiation', (data: any) => {
      const action = data.id ? () => handleRepasseNotification(data.id) : null
      dispatch(addNotification({ action, message: data.message, subMessage: moment().format('DD/MM/YYYY HH:mm:ss') }))
      dispatch(showToastr(`${data.message}. Clique no menu de notificações para ir até a negociação!`, 'info'))
    })

    channel.bind('previas', (data: any) => {
      const action = data.id ? () => handlePreviasNotification(data.id) : null
      dispatch(addNotification({ action, message: data.message, subMessage: moment().format('DD/MM/YYYY HH:mm:ss') }))
      dispatch(showToastr(`${data.message}. Clique no menu de notificações para ir até a negociação!`, 'info'))
    })

    if (Helpers.isAuth()) {
      dispatch(setUserState())
    }
    // eslint-disable-next-line
  }, [])

  return (
    <React.Suspense fallback={''}>
      <AuthRoutes />
      <DashboardRoutes />
    </React.Suspense>
  )
}

export default withRouter(Routes)
