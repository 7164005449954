import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_GROWTH,
})

instance.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = 'Basic B@CST@GE2022'
    return config
  },
  (error) => Promise.reject(error),
)

instance.interceptors.response.use(
  (res) => {
    return Promise.resolve(res.data)
  },
  (error) => {
    return Promise.reject(error.response.data)
  },
)

export default instance
