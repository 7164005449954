import { action } from 'typesafe-actions'
import { RetencaoTypes } from './types'

export const getListRequest = () => action(RetencaoTypes.GET_LIST_REQUEST)
export const getListSuccess = (data: any) => action(RetencaoTypes.GET_LIST_SUCCESS, { data })
export const getListFailure = () => action(RetencaoTypes.GET_LIST_FAILURE)

export const getUserDetailRequest = (id: any) => action(RetencaoTypes.GET_USER_DETAIL_REQUEST, { id })
export const getUserDetailSuccess = (data: any) => action(RetencaoTypes.GET_USER_DETAIL_SUCCESS, { data })
export const getUserDetailFailure = () => action(RetencaoTypes.GET_USER_DETAIL_FAILURE)

/* export const getHistoricListRequest = () => action(RetencaoTypes.GET_HISTORY_LIST_REQUEST)
export const getHistoricListSuccess = (data: any) => action(RetencaoTypes.GET_HISTORY_LIST_SUCCESS, { data })
export const getHistoricListFailure = () => action(RetencaoTypes.GET_HISTORY_LIST_FAILURE)
*/

export const updateListRequest = (retencaoid: string, body: any) =>
  action(RetencaoTypes.UPDATE_LIST_REQUEST, { retencaoid, body })
export const updateListSuccess = (data: any) => action(RetencaoTypes.UPDATE_LIST_SUCCESS, { data })
export const updateListFailure = () => action(RetencaoTypes.UPDATE_LIST_FAILURE)
