import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { APP_CLIENTE_RESOURCE } from 'shared/resources'

const AppCliente = lazy(() => import('pages/AppCliente'))

export const AppClienteRoute: MenuItems[] = [
    {
        path: '',
        name: 'Tokens App Cliente',
        iconComponent: Icons.CalendarToday,
        menus: [
            {
                path: `${APP_CLIENTE_RESOURCE}`,
                name: 'tOKENS',
                component: AppCliente,
                exact: true,
                menus: [],
            }
        ],
    },
]

export default function AppClienteRoutes() {
    return <>{buildRouteAndSubRoute(AppClienteRoute)}</>
}
