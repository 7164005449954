import styled from 'styled-components'

export default {
  Container: styled.div`
    max-height: 420px;
    overflow: auto;
    & > div:nth-child(2n + 1) {
      background: white;
    }
    & > div {
      background: #fff6f6;
    }
  `,
  Wrap: styled.div<any>`
    color: ${({ empty }: any) => (empty ? '#9ca4a4' : '#535757')};
    text-align: ${({ empty }: any) => (empty ? 'center' : 'left')};
    padding: 20px 20px;
    cursor: ${({ empty, onClick }: any) => (empty || !onClick ? 'default' : 'pointer')};
  `,
}
