import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { INSURANCE_RESOURCE } from 'shared/resources'

const InsuranceListPage = lazy(() => import('pages/Insurance/List'))
const InsuranceDetailPage = lazy(() => import('pages/Insurance/Details'))
const InsuranceSaleConsultantsListPage = lazy(() => import('pages/InsuranceSaleConsultants/List'))
const InsuranceSaleConsultantsDetailsPage = lazy(() => import('pages/InsuranceSaleConsultants/Details'))

export const InsuranceRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Seguros',
    iconComponent: Icons.CalendarToday,
    menus: [
      {
        path: `${INSURANCE_RESOURCE}/lista`,
        name: 'Lista seguros',
        component: InsuranceListPage,
        exact: true,
        menus: [],
      },
      {
        path: `${INSURANCE_RESOURCE}/details/:id`,
        name: 'Visualizar DPS',
        component: InsuranceDetailPage,
        isSubRoute: true,
        menus: [],
      },

      {
        path: `${INSURANCE_RESOURCE}/vendaconsultor`,
        name: 'Gerenciar operações de seguro',
        component: InsuranceSaleConsultantsListPage,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${INSURANCE_RESOURCE}/detail/:id/:operation`,
        name: 'Visualizar operação',
        component: InsuranceSaleConsultantsDetailsPage,
        isSubRoute: true,
        menus: [],
      },
    ],
  },
]

export default function SegurosRoutesComponent() {
  return <>{buildRouteAndSubRoute(InsuranceRoutes)}</>
}
