import { IOrigin } from 'store/auth/types'
import { action } from 'typesafe-actions'
import { UserTypes } from './types'

export const getUsersRequest = (origin: IOrigin) => action(UserTypes.GET_USERS_REQUEST, { origin })
export const getUsersSuccess = (data: any) => action(UserTypes.GET_USERS_SUCCESS, { data })
export const getUsersFailure = () => action(UserTypes.GET_USERS_FAILURE)

export const updateUserRoleRequest = (role: string, userId: string) =>
  action(UserTypes.UPDATE_USER_ROLE_REQUEST, { role, userId })
