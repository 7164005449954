import { action } from 'typesafe-actions'
import { ComunicacaoTypes } from './types'

export const getComunicacaoRequest = (body: any) => action(ComunicacaoTypes.GET_COMUNICACAO_LIST_REQUEST, { body })
export const getComunicacaoSuccess = (data: any) => action(ComunicacaoTypes.GET_COMUNICACAO_LIST_SUCCESS, { data })
export const getComunicacaoFailure = () => action(ComunicacaoTypes.GET_COMUNICACAO_LIST_FAILURE)

//create comunicacao
export const createComunicacaoRequest = (body: any, send: boolean, isTest: boolean) =>
  action(ComunicacaoTypes.CREATE_COMUNICACAO_REQUEST, { body, send, isTest })
export const createComunicacaoSucess = (data: any) => action(ComunicacaoTypes.CREATE_COMUNICACAO_SUCCESS, { data })
export const createComunicacaoFailure = () => action(ComunicacaoTypes.CREATE_COMUNICACAO_FAILURE)

//editar e atualizar
export const getUserDetailRequest = (id: any) => action(ComunicacaoTypes.GET_COMUNICACAO_DETAIL_REQUEST, { id })
export const getUserDetailSuccess = (data: any) => action(ComunicacaoTypes.GET_COMUNICACAO_DETAIL_SUCCESS, { data })
export const getUserDetailFailure = () => action(ComunicacaoTypes.GET_COMUNICACAO_DETAIL_FAILURE)

export const updateComunicacaoRequest = (id: any, body: any) =>
  action(ComunicacaoTypes.UPDATE_COMUNICACAO_REQUEST, { id, body })
export const updateComunicacaoSucess = (data: any) => action(ComunicacaoTypes.UPDATE_COMUNICACAO_SUCCESS, { data })
export const updateComunicacaoFailure = () => action(ComunicacaoTypes.UPDATE_COMUNICACAO_FAILURE)

//export const getComunicacaoProductRequest = () => action(ComunicacaoTypes.GET_COMUNICACAO_LIST_PRODUCTS_REQUEST)
//export const getComunicacaoProductSuccess = (data: any) => action(ComunicacaoTypes.GET_COMUNICACAO_LIST_PRODUCTS_SUCCESS, { data })
//export const getComunicacaoProductFailure = () => action(ComunicacaoTypes.GET_COMUNICACAO_LIST_PRODUCTS_FAILURE)

export const sendComunicacaoRequest = (id: any, body: any) =>
  action(ComunicacaoTypes.SEND_COMUNICACAO_REQUEST, { id, body })
export const sendComunicacaoSucess = (data: any) => action(ComunicacaoTypes.SEND_COMUNICACAO_SUCCESS, { data })
export const sendComunicacaoFailure = () => action(ComunicacaoTypes.SEND_COMUNICACAO_FAILURE)

export const clearComunicacao = () => action(ComunicacaoTypes.CLEAR_COMUNICACAO)

// pegar informações para select modo de disparo da campanha
export const getComunicacaoTypePublicRequest = (id: any) =>
  action(ComunicacaoTypes.GET_COMUNICACAO_TYPE_PUBLIC_REQUEST, { id })
export const getComunicacaoTypePublicSuccess = (data: any) =>
  action(ComunicacaoTypes.GET_COMUNICACAO_TYPE_PUBLIC_SUCCESS, { data })
export const getComunicacaoTypePublicFailure = () => action(ComunicacaoTypes.GET_COMUNICACAO_TYPE_PUBLIC_FAILURE)

//deletar campanha
export const deleteComunicacaoRequest = (id: any, body: any) =>
  action(ComunicacaoTypes.DELETE_COMUNICACAO_REQUEST, { id, body })
export const deleteComunicacaoSucess = (data: any) => action(ComunicacaoTypes.DELETE_COMUNICACAO_SUCCESS, { data })
export const deleteComunicacaoFailure = () => action(ComunicacaoTypes.DELETE_COMUNICACAO_FAILURE)

// Listar categories
export const listCategoriesRequest = () => action(ComunicacaoTypes.LIST_CATEGORIES_REQUEST)
export const listCategoriesSucess = (data: any) => action(ComunicacaoTypes.LIST_CATEGORIES_SUCCESS, { data })
export const listCategoriesFailure = () => action(ComunicacaoTypes.LIST_CATEGORIES_FAILURE)

// Download da lista para csv
export const getdownloadCsvComunicacaoRequest = () => action(ComunicacaoTypes.GET_DOWNLOAD_CSV_COMUNICACAO_REQUEST)
export const getdownloadCsvComunicacaoSuccess = (data: any) =>
  action(ComunicacaoTypes.GET_DOWNLOAD_CSV_COMUNICACAO_SUCCESS, { data })
export const getdownloadCsvComunicacaoFailure = () => action(ComunicacaoTypes.GET_DOWNLOAD_CSV_COMUNICACAO_FAILURE)

// Filtro pesquisar comunicação
export const searchCampaignRequest = (body: any) => action(ComunicacaoTypes.GET_SEARCH_CAMPAIGN_REQUEST, { body })
export const searchCampaignSuccess = (data: any) => action(ComunicacaoTypes.GET_SEARCH_CAMPAIGN_SUCCESS, { data })
export const searchCampaignFailure = () => action(ComunicacaoTypes.GET_SEARCH_CAMPAIGN_FAILURE)

// Filtro modal comunicação
export const getModalFiltersRequest = (body: any) => action(ComunicacaoTypes.GET_MODAL_FILTERS_REQUEST, { body })
export const getModalFiltersSuccess = (data: any) => action(ComunicacaoTypes.GET_MODAL_FILTERS_SUCCESS, { data })
export const getModalFiltersFailure = () => action(ComunicacaoTypes.GET_MODAL_FILTERS_FAILURE)

// Listar campanhas
export const getCampanhaRequest = (body: any) => action(ComunicacaoTypes.GET_CAMPANHA_LIST_REQUEST, { body })
export const getCampanhaSuccess = (data: any) => action(ComunicacaoTypes.GET_CAMPANHA_LIST_SUCCESS, { data })
export const getCampanhaFailure = () => action(ComunicacaoTypes.GET_CAMPANHA_LIST_FAILURE)

// View campanha
export const getCampanhaDetail = (id: any) => action(ComunicacaoTypes.GET_CAMPANHA_DETAIL_REQUEST, { id })
export const getCampanhaDetailSuccess = (data: any) => action(ComunicacaoTypes.GET_CAMPANHA_DETAIL_SUCCESS, { data })
export const getCampanhaDetailFailure = () => action(ComunicacaoTypes.GET_CAMPANHA_DETAIL_FAILURE)

// Criar campanha
export const createCampanhaRequest = (body: any, send: boolean, isTest: boolean) =>
  action(ComunicacaoTypes.CREATE_CAMPANHA_REQUEST, { body, send, isTest })
export const createCampanhaSuccess = (data: any) => action(ComunicacaoTypes.CREATE_CAMPANHA_SUCCESS, { data })
export const createCampanhaFailure = () => action(ComunicacaoTypes.CREATE_CAMPANHA_FAILURE)

// Enviar campanha
export const sendCampanhaRequest = (id: any, body: any) => action(ComunicacaoTypes.SEND_CAMPANHA_REQUEST, { id, body })
export const sendCampanhaSucess = (data: any) => action(ComunicacaoTypes.SEND_CAMPANHA_SUCCESS, { data })
export const sendCampanhaFailure = () => action(ComunicacaoTypes.SEND_CAMPANHA_FAILURE)

// Lista publico
export const getCampanhaTypePublicRequest = (id: any) =>
  action(ComunicacaoTypes.GET_CAMPANHA_TYPE_PUBLIC_REQUEST, { id })
export const getCampanhaTypePublicSuccess = (data: any) =>
  action(ComunicacaoTypes.GET_CAMPANHA_TYPE_PUBLIC_SUCCESS, { data })
export const getCampanhaTypePublicFailure = () => action(ComunicacaoTypes.GET_CAMPANHA_TYPE_PUBLIC_FAILURE)

// Remover campanha
export const deleteCampanhaRequest = (id: any, body: any) =>
  action(ComunicacaoTypes.DELETE_CAMPANHA_REQUEST, { id, body })
export const deleteCampanhaSucess = (data: any) => action(ComunicacaoTypes.DELETE_CAMPANHA_SUCCESS, { data })
export const deleteCampanhaFailure = () => action(ComunicacaoTypes.DELETE_CAMPANHA_FAILURE)

// Destacar campanha
export const spotlightCampanhaRequest = (id: any, status: boolean) =>
  action(ComunicacaoTypes.SPOTLIGHT_CAMPANHA_REQUEST, { id, status })
export const spotlightCampanhaSuccess = (data: any) => action(ComunicacaoTypes.SPOTLIGHT_CAMPANHA_SUCCESS, { data })
export const spotlightCampanhaFailure = () => action(ComunicacaoTypes.SPOTLIGHT_CAMPANHA_FAILURE)

// Atualizar campanha
export const updateCampanhaRequest = (id: any, body: any) =>
  action(ComunicacaoTypes.UPDATE_CAMPANHA_REQUEST, { id, body })
export const updateCampanhaSucess = (data: any) => action(ComunicacaoTypes.UPDATE_CAMPANHA_SUCCESS, { data })
export const updateCampanhaFailure = () => action(ComunicacaoTypes.UPDATE_CAMPANHA_FAILURE)

// Listar tags
export const listTagsRequest = () => action(ComunicacaoTypes.LIST_TAGS_REQUEST)
export const listTagsSucess = (data: any) => action(ComunicacaoTypes.LIST_TAGS_SUCCESS, { data })
export const listTagsFailure = () => action(ComunicacaoTypes.LIST_TAGS_FAILURE)

export const clearCampanha = () => action(ComunicacaoTypes.CLEAR_CAMPANHA)

// Filtro pesquisar campanha
export const searchCampanhaRequest = (body: any) => action(ComunicacaoTypes.GET_SEARCH_CAMPANHA_REQUEST, { body })
export const searchCampanhaSuccess = (data: any) => action(ComunicacaoTypes.GET_SEARCH_CAMPANHA_SUCCESS, { data })
export const searchCampanhaFailure = () => action(ComunicacaoTypes.GET_SEARCH_CAMPANHA_FAILURE)

// Filtro modal campanha
export const getModalFiltersCampanhaRequest = (body: any) =>
  action(ComunicacaoTypes.GET_MODAL_FILTERS_CAMPANHA_REQUEST, { body })
export const getModalFiltersCampanhaSuccess = (data: any) =>
  action(ComunicacaoTypes.GET_MODAL_FILTERS_CAMPANHA_SUCCESS, { data })
export const getModalFiltersCampanhaFailure = () => action(ComunicacaoTypes.GET_MODAL_FILTERS_CAMPANHA_FAILURE)
