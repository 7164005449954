import React, { useEffect } from 'react'
import { makeStyles, Grow } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { useSelector, useDispatch } from 'react-redux'
import { IApplicationState } from 'store'
import { hideToastr } from 'store/toastr/actions'

const style = makeStyles((theme) => ({
  alertBox: {
    maxWidth: '400px',
    margin: '0 auto',
    position: 'fixed',
    width: '100%',
    transform: 'translateX(-50%)',
    left: '50%',
    bottom: '15px',
    zIndex: 99999,
  },
}))

const Toastr = () => {
  const classes = style()
  const dispatch = useDispatch()
  const state = useSelector((state: IApplicationState) => ({
    toastr: state.toastr,
  }))

  useEffect(() => {
    if (state.toastr.show) {
      setTimeout(() => {
        dispatch(hideToastr())
      }, 5000)
    }
    // eslint-disable-next-line
  }, [state.toastr.show])

  return state.toastr.show ? (
    <div className={classes.alertBox}>
      <Grow in={true}>
        <MuiAlert elevation={6} variant="filled" severity={state.toastr.type}>
          {state.toastr.message}
        </MuiAlert>
      </Grow>
    </div>
  ) : null
}

export default Toastr
