/* eslint-disable @typescript-eslint/camelcase */
import { RetencaoTypes, IRetencaoState } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: IRetencaoState = {
  list: {
    loading: false,
    data: [],
    error: '',
  },
  userDetail: {
    loading: false,
    data: {
      nmPessoa: '',
      user: '',
      cpfCnpj: '',
      telefone: '',
      telefoneDigitado: '',
      createdAt: '',
      status: '',
      userName: '',
      userFuncao: '',
      historic: [],
      contatoConsultor: false,
    },
    error: '',
  },
  historicList: {
    loading: false,
    data: [],
    error: '',
  },
}

export const retencaoReducer = (
  state: IRetencaoState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IRetencaoState => {
  switch (action.type) {
    case RetencaoTypes.GET_LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      }
    case RetencaoTypes.GET_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          loading: false,
        },
      }
    case RetencaoTypes.GET_LIST_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case RetencaoTypes.GET_USER_DETAIL_REQUEST:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          loading: true,
        },
      }
    case RetencaoTypes.GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          data: action.payload.data,
          loading: false,
        },
      }
    case RetencaoTypes.GET_USER_DETAIL_FAILURE:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case RetencaoTypes.GET_HISTORY_LIST_REQUEST:
      return {
        ...state,
        historicList: {
          ...state.historicList,
          loading: true,
        },
      }
    case RetencaoTypes.GET_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        historicList: {
          ...state.historicList,
          data: action.payload.data,
          loading: false,
        },
      }
    case RetencaoTypes.GET_HISTORY_LIST_FAILURE:
      return {
        ...state,
        historicList: {
          ...state.historicList,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    default:
      return state
  }
}
