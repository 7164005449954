import React from 'react'
import { Button as MuiButton, makeStyles } from '@material-ui/core'
import { green, red, blue, orange } from '@material-ui/core/colors'

type ButtonType = 'positive' | 'negative' | 'warning' | 'info' | 'regular'

interface IButtonProps {
  type?: ButtonType
  variant?: 'outlined' | 'contained' | 'text' | undefined
  onClick: () => void
}

const style = (variant: 'outlined' | 'contained' | 'text' | undefined) =>
  makeStyles((theme) => ({
    green: {
      background: variant === 'outlined' ? 'white' : green[700],
      borderColor: green[700],
      color: variant === 'outlined' ? green[500] : 'white',
      '&:hover': {
        background: variant === 'outlined' ? 'white' : green[700],
      },
    },
    red: {
      background: variant === 'outlined' ? 'white' : red[500],
      color: variant === 'outlined' ? red[500] : 'white',
      borderColor: red[500],
      '&:hover': {
        background: variant === 'outlined' ? 'white' : red[500],
      },
    },
    blue: {
      background: variant === 'outlined' ? 'white' : blue[700],
      color: variant === 'outlined' ? blue[500] : 'white',
      borderColor: blue[700],
      '&:hover': {
        background: variant === 'outlined' ? 'white' : blue[700],
      },
    },
    orange: {
      background: variant === 'outlined' ? 'white' : orange[500],
      color: variant === 'outlined' ? orange[500] : 'white',
      borderColor: orange[500],
      '&:hover': {
        background: variant === 'outlined' ? 'white' : orange[500],
      },
    },
  }))

const Button: React.FC<IButtonProps> = ({ type = 'regular', children, onClick, variant }) => {
  const classes = style(variant)()

  switch (type) {
    case 'positive':
      return (
        <MuiButton onClick={onClick} className={classes.green} variant={variant} size="medium">
          {children}
        </MuiButton>
      )
    case 'negative':
      return (
        <MuiButton onClick={onClick} className={classes.red} variant={variant} size="medium">
          {children}
        </MuiButton>
      )
    case 'info':
      return (
        <MuiButton onClick={onClick} className={classes.blue} variant={variant} size="medium">
          {children}
        </MuiButton>
      )
    case 'warning':
      return (
        <MuiButton onClick={onClick} className={classes.orange} variant={variant} size="medium">
          {children}
        </MuiButton>
      )
    case 'regular':
      return (
        <MuiButton {...{ onClick, variant }} color="primary">
          {children}
        </MuiButton>
      )
  }
}

export default Button
