import { TOKEN_KEY, USER_KEY } from './../../shared/constants'
import axios from 'axios'
import Helpers from 'shared/helpers'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BIFROST,
})

instance.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${Helpers.getToken()}`
    return config
  },
  (error) => Promise.reject(error),
)

instance.interceptors.response.use(
  (res) => {
    return Promise.resolve(res.data)
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem(TOKEN_KEY)
      localStorage.removeItem(USER_KEY)
      window.location.pathname = '/'
    }

    return Promise.reject(error.response.data)
  },
)

export default instance
