/* eslint-disable @typescript-eslint/camelcase */
import { RecorrenciaTypes, IRecorrenciaState } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: IRecorrenciaState = {
  data: [],
  error: false,
  loading: false,
}

export const recorrenciaReducer = (
  state: IRecorrenciaState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IRecorrenciaState => {
  switch (action.type) {
    case RecorrenciaTypes.GET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case RecorrenciaTypes.GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      }
    case RecorrenciaTypes.GET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: [],
      }

    default:
      return state
  }
}
