import api from 'store/utils/apiv2'
import apiGrowth from 'store/utils/growth'
import { showToastr } from 'store/toastr/actions'
import { AntecipayTypes } from './types'
import { put, call, takeLatest, all, fork } from 'redux-saga/effects'
import {
  getListSuccess,
  getListFailure,
  getListRequest,
  updateListSuccess,
  updateListFailure,
  getListContractsFailure,
  getListContractsSuccess,
  getListContractsTypesSuccess,
  getListContractsTypesFailure,
  getContractSuccess,
  getContractFailure,
} from './actions'
import { Reasons } from 'pages/Antecipay/Blacklist/fragments/AddBlacklistModal'

export function* handlerGetList() {
  try {
    const res: any = yield call(api.get, `/antecipay/list`)
    yield put(getListSuccess(res))
  } catch (err) {
    yield put(getListFailure())
  }
}
export function* handlerUpdateList(action: any) {
  const { antecipayid, status } = action.payload
  //{console.log(action.payload)}
  try {
    const res: any = yield call(api.post, `/antecipay/${antecipayid}/status`, { status })
    yield put(updateListSuccess(res))
    yield put(getListRequest())
    yield put(showToastr('Status atualizado!', 'success'))
  } catch (err) {
    yield put(updateListFailure())
  }
}

/**
 * LIST CONTRATO
 */
export function* ListContracts(params: any) {
  const { pg, limit, contrato, status, tipo } = params.payload
  try {
    const res: any = yield call(apiGrowth.get, `/api/antecipay/painel/contratos`, {
      params: { pg: pg, limit: limit, contrato: contrato, status: status, tipo: tipo },
    })
    yield put(getListContractsSuccess(res))
  } catch (err) {
    yield put(getListContractsFailure())
  }
}

export function* listContractsTypes() {
  try {
    const res: any = yield call(apiGrowth.get, `/api/antecipay/painel/tipos`)
    yield put(getListContractsTypesSuccess(res))
  } catch (err) {
    yield put(getListContractsTypesFailure())
  }
}

export function* getContract(params) {
  const { contrato } = params.payload
  try {
    const res: any = yield call(apiGrowth.get, `/api/antecipay/painel/contrato/${contrato}`)
    yield put(getContractSuccess(res))
  } catch (err) {
    yield put(getContractFailure())
  }
}

function* watchSagas(): Generator {
  yield takeLatest(AntecipayTypes.GET_LIST_REQUEST, handlerGetList)
  yield takeLatest(AntecipayTypes.UPDATE_LIST_REQUEST, handlerUpdateList)
  yield takeLatest(AntecipayTypes.GET_LIST_CONTRACTS_REQUEST, ListContracts)
  yield takeLatest(AntecipayTypes.GET_LIST_CONTRACTS_TYPES_REQUEST, listContractsTypes)
  yield takeLatest(AntecipayTypes.GET_CONTRACT_REQUEST, getContract)
}

export default function* previasSagas() {
  yield all([fork(watchSagas)])
}

export const getAntecipayBlacklist = async (filters: any) => {
  const searchParams = new URLSearchParams(filters)
  return await api.get(`/antecipay/backstage/blacklist/list?${searchParams.toString()}`)
}

export const getAntecipayBlacklistById = async (id: string) => {
  return await api.get(`/antecipay/backstage/blacklist/details/${id}`)
}

export const createAntecipayBlacklist = async (data: any) => {
  return await api.post(`/antecipay/backstage/blacklist/create`, data)
}

export const getAntecipayBlacklistHistoric = async (id: string) => {
  return await api.get(`/antecipay/backstage/blacklist/details/${id}`)
}

export const addCommentAntecipayBlacklist = async (id: string, description: string) => {
  return await api.put(`/antecipay/backstage/blacklist/addcomment/${id}`, { description })
}

export const removeAntecipayBlacklist = async (id: string, description: string) => {
  return await api.put(`/antecipay/backstage/blacklist/remove/${id}`, { description })
}

export const getAntecipayBlacklistReasons = async (): Promise<Reasons[]> => {
  return await api.get(`/antecipay/backstage/blacklist/reasons`)
}
