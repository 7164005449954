export const PostSalesType = {
  GET_POST_SALE_REQUEST: '@@postsale/GET_POST_SALE_REQUEST',
  GET_POST_SALE_SUCCESS: '@@postsale/GET_POST_SALE_SUCCESS',
  GET_POST_SALE_FAILURE: '@@postsale/GET_POST_SALE_FAILURE',
  
  GET_BLACKLIST_REQUEST: '@@postsale/GET_BLACKLIST_REQUEST',
  GET_BLACKLIST_SUCCESS: '@@postsale/GET_BLACKLIST_SUCCESS',
  GET_BLACKLIST_FAILURE: '@@postsale/GET_BLACKLIST_FAILURE',
  
  SET_BLACKLIST_REQUEST: '@@postsale/SET_BLACKLIST_REQUEST',
  SET_BLACKLIST_SUCCESS: '@@postsale/SET_BLACKLIST_SUCCESS',
  SET_BLACKLIST_FAILURE: '@@postsale/SET_BLACKLIST_FALURE',
  
  REMOVE_BLACKLIST_REQUEST: '@@postsale/REMOVE_BLACKLIST_REQUEST',
  REMOVE_BLACKLIST_SUCCESS: '@@postsale/REMOVE_BLACKLIST_SUCCESS',
  REMOVE_BLACKLIST_FAILURE: '@@postsale/REMOVE_BLACKLIST_FAILURE',

  GET_PERSON_REQUEST: '@@postsale/GET_PERSON_REQUEST',
  GET_PERSON_SUCCESS: '@@postsale/GET_PERSON_SUCCESS',
  GET_PERSON_FAILURE: '@@postsale/GET_PERSON_FAILURE',

  GET_CONVERSATION_REQUEST: '@@postsale/GET_CONVERSATION_REQUEST',
  GET_CONVERSATION_SUCCESS: '@@postsale/GET_CONVERSATION_SUCCESS',
  GET_CONVERSATION_FAILURE: '@@postsale/GET_CONVERSATION_FAILURE',

  EXPORT_SALE_LIST_REQUEST: '@@postsale/EXPORT_SALE_LIST_REQUEST',
  EXPORT_SALE_LIST_SUCCESS: '@@postsale/EXPORT_SALE_LIST_SUCCESS',


  GET_DASHBOARD_REQUEST: '@@postsale/GET_DASHBOARD_REQUEST',
  GET_DASHBOARD_SUCCESS: '@@postsale/GET_DASHBOARD_SUCCESS',
  GET_DASHBOARD_FAILURE: '@@postsale/GET_DASHBOARD_FAILURE',
}

export interface IPostSalesState {
  list: Record<string, any>
  blacklist: Record<string, any>
  person: Record<string, any>
  conversation: Record<string, any>
  fullList: Record<string, any>
  dashboard: Record<string, any>
}
