import { ListState, UserState, HistoricState } from './interface'

export const RetencaoTypes = {
  GET_LIST_REQUEST: '@@retencao/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: '@@retencao/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: '@@retencao/GET_LIST_FAILURE',
  GET_USER_DETAIL_REQUEST: '@@retencao/GET_USER_DETAIL_REQUEST',
  GET_USER_DETAIL_SUCCESS: '@@retencao/GET_USER_DETAIL_SUCCESS',
  GET_USER_DETAIL_FAILURE: '@@retencao/GET_USER_DETAIL_FAILURE',
  GET_HISTORY_LIST_REQUEST: '@@retencao/GET_HISTORY_LIST_REQUEST',
  GET_HISTORY_LIST_SUCCESS: '@@retencao/GET_HISTORY_LIST_SUCCESS',
  GET_HISTORY_LIST_FAILURE: '@@retencao/GET_HISTORY_LIST_FAILURE',
  UPDATE_LIST_REQUEST: '@@retencao/UPDATE_LIST_REQUEST',
  UPDATE_LIST_SUCCESS: '@@retencao/UPDATE_LIST_SUCCESS',
  UPDATE_LIST_FAILURE: '@@retencao/UPDATE_LIST_FAILURE',
}

export interface IRetencaoState {
  list: ListState
  userDetail: UserState
  historicList: HistoricState
}
