import React, { useCallback } from 'react'
import fileIcon from '../../../../assets/images/icon_upload.svg'
import { style } from '../../UserDetail/AnalisePf/fragments/Dropzone/style'
import { faCheck, faTrash } from '@fortawesome/pro-light-svg-icons'
import { Box, Grid } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { IApplicationState } from 'store'
import { clearBlacklistFile, setUploadRequest } from 'store/previas/actions'
import '../../UserDetail/AnalisePf/fragments/Dropzone/dropzone.css'

function Dropzone({ hasError }) {
  const classes = style()
  const { blacklist } = useSelector((state: IApplicationState) => state.previas)
  const dispatch = useDispatch()

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles?.length > 0) {
      dispatch(setUploadRequest(acceptedFiles[0]))
    }
    // eslint-disable-next-line
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <form>
      {hasError && <p style={{ color: 'red' }}>Selecione um arquivo válido</p>}
      <div {...getRootProps({ className: classes.dropzone })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <>
            <div>
              <img alt="Ademicon" src={fileIcon} />
            </div>
            <span className={classes.dropzoneButton}>Selecionar arquivos</span>
            <p className={classes.dropzoneSubtitle}>ou arraste e solte um arquivo aqui</p>
          </>
        ) : (
          <>
            <div>
              <img alt="Ademicon" src={fileIcon} />
            </div>
            <span className={classes.dropzoneButton}>Selecionar arquivos</span>
            <p className={classes.dropzoneSubtitle}>ou arraste e solte um arquivo aqui</p>
          </>
        )}
      </div>
      {blacklist.file && (
        <React.Fragment>
          <Grid className={classes.fileProgress}>
            <Box className={classes.fileLoading}>
              <div className={classes.fileProgressCheck}>
                <FontAwesomeIcon icon={faCheck} style={{ cursor: 'pointer', fontSize: '1.2rem', color: '#336951' }} />
              </div>
            </Box>
            <Box className={classes.fileProgressInfo}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.open(blacklist.file.location)
                }}
              >
                <b style={{ color: '#9E9E9E' }}>{blacklist.file.originalname}</b>
              </div>
              <FontAwesomeIcon
                icon={faTrash}
                style={{ cursor: 'pointer', fontSize: '1.2rem' }}
                onClick={() => dispatch(clearBlacklistFile())}
              />
            </Box>
          </Grid>
        </React.Fragment>
      )}
      {blacklist.uploadLoading && (
        <div className="loader">
          <div className="loaderBar" />
        </div>
      )}
    </form>
  )
}

export default Dropzone
