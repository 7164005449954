import { IApiUser, IAuth } from 'store/auth/types'
const dev_role = process.env.REACT_APP_ROLE

export const toAppUser = (userFromApi: IApiUser | null): IAuth | null => {
  return {
    name: userFromApi?.user ? userFromApi?.user.name : '',
    role: dev_role ? dev_role : userFromApi?.user ? userFromApi?.user.funcao : '',
    modules: userFromApi?.modules || [],
  }
}
