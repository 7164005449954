import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import ConfirmationModal from 'components/ConfirmationModal'
import { useSelector } from 'react-redux'
import { IApplicationState } from 'store'
import { IMenu } from 'store/modules_config/types'
import { IPermission } from 'store/permissions/types'
import * as Icons from '@material-ui/icons'
import AddMenuParam from './AddMenuParams'

interface AddMenuProps {
  menu: IMenu
  updateSubMenu: any
  onPermissionChange: (menu: IMenu) => void
  onSubmit: (menu: IMenu) => void
  type: 'add' | 'edit'
}

const AddMenu: React.FC<AddMenuProps> = ({ menu, onPermissionChange, onSubmit, updateSubMenu, type }) => {
  const state = useSelector((selector: IApplicationState) => ({
    permissions: selector.permissions.data,
  }))
  const [myMenu, setMyMenu] = useState<IMenu>({ ...menu })
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalParamsOpen, setModalParamsOpen] = useState<boolean>(false)
  const [newParamName, setNewParamName] = useState('')
  const [newParamObj, setNewParamObj] = useState({ type: 'static', value: '' })

  const handleOnPermissionChange = (e: React.ChangeEvent<HTMLInputElement>, perm: IPermission) => {
    const { checked } = e.target
    const perms: string[] = myMenu.permissions || []
    const withOutUnchecked = perms.filter((x) => x !== perm._id)
    const updatedMenu: IMenu = {
      ...myMenu,
      permissions: checked ? [...perms, perm._id] : [...withOutUnchecked],
    }
    onPermissionChange(updatedMenu)
  }

  const handleMyMenuBasicInfo = (e: React.ChangeEvent<any>) => {
    setMyMenu({ ...myMenu, [e.target.name]: e.target.value })
  }

  const handleMyMenuIframe = (e: React.ChangeEvent<any>) => {
    setMyMenu({
      ...myMenu,
      iframe: {
        name: myMenu.name,
        url: e.target.value,
      },
    })
  }

  const handleSetMyMenuParams = (params: any, paramName: string, oldName: string) => {
    if (params === null) {
      delete myMenu.iframe?.params![oldName]
      updateSubMenu(myMenu)
      return
    }

    const obj: IMenu = {
      ...myMenu,
      iframe: myMenu.iframe
        ? {
            ...myMenu.iframe,
            params: {
              ...myMenu.iframe.params,
              [paramName]: params[paramName],
            },
          }
        : undefined,
    }

    if (paramName !== oldName || oldName === '') delete obj.iframe?.params![oldName]

    updateSubMenu(obj)
    setNewParamObj({ type: 'static', value: '' })
    setNewParamName('')
  }

  useEffect(() => {
    setMyMenu(menu)
  }, [menu])

  return (
    <Paper elevation={4}>
      <Box padding={2} marginBottom={2}>
        <Box>
          <Grid container spacing={2} alignItems="center">
            <Grid item md={1}>
              <Button
                disabled={myMenu._id === '' || myMenu._id === undefined}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setModalOpen(true)}
              >
                <Icon>lock</Icon>
              </Button>
            </Grid>
            <Grid item md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="Nome Menu"
                value={myMenu.name}
                name="name"
                onChange={handleMyMenuBasicInfo}
                onBlur={() => updateSubMenu(myMenu)}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                placeholder="Ex: /repasse/negociacoes"
                fullWidth
                variant="outlined"
                label="Url"
                value={myMenu.path}
                name="path"
                onChange={handleMyMenuBasicInfo}
                onBlur={() => updateSubMenu(myMenu)}
              />
            </Grid>
            <Grid item md={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="Tipo">Tipo:</InputLabel>
                <Select
                  labelId="Tipo"
                  id="Tipo"
                  name="type"
                  value={myMenu.type}
                  onBlur={() => updateSubMenu(myMenu)}
                  onChange={handleMyMenuBasicInfo}
                  label="Tipo"
                >
                  <MenuItem value={'internal'}>Interno</MenuItem>
                  <MenuItem value={'external'}>Externo</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <IconButton color="primary" onClick={() => onSubmit(myMenu)}>
                {type === 'edit' ? <Icons.Remove color="disabled" /> : <Icons.Add />}
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        {myMenu.type === 'external' && (
          <React.Fragment>
            <Box marginTop={2}>
              <Grid container spacing={2} alignItems="center">
                <Grid item md={1}></Grid>
                <Grid item md={8}>
                  <TextField
                    label="Url Iframe"
                    variant="outlined"
                    fullWidth
                    value={myMenu.iframe?.url}
                    onChange={handleMyMenuIframe}
                    name="url"
                    onBlur={() => updateSubMenu(myMenu)}
                  />
                </Grid>
                <Grid item md={2}>
                  <Button
                    fullWidth
                    size="large"
                    variant="outlined"
                    color="primary"
                    onClick={() => setModalParamsOpen(true)}
                  >
                    Parâmetros
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </React.Fragment>
        )}

        <ConfirmationModal
          onCancel={() => setModalOpen(false)}
          onClose={() => setModalOpen(false)}
          onConfirm={() => setModalOpen(false)}
          confirmText="Salvar"
          cancelText="Cancelar"
          open={modalOpen}
        >
          <Box padding={2}>
            <Typography variant="h6">Permissões</Typography>
          </Box>
          <List style={{ maxHeight: 300, overflow: 'auto' }}>
            {state.permissions.map((perm, i) => {
              return (
                <ListItem key={i} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={myMenu.permissions?.includes(perm._id) || false}
                      tabIndex={-1}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnPermissionChange(e, perm)}
                    />
                  </ListItemIcon>
                  <ListItemText id={perm._id} primary={perm.name} />
                </ListItem>
              )
            })}
          </List>
        </ConfirmationModal>

        <ConfirmationModal
          onCancel={() => setModalParamsOpen(false)}
          onClose={() => setModalParamsOpen(false)}
          onConfirm={() => setModalParamsOpen(false)}
          confirmText="Salvar"
          cancelText="Fechar"
          open={modalParamsOpen}
          width="60%"
        >
          <Box padding={2}>
            <Typography variant="h6">Parâmetros</Typography>
          </Box>
          <Box paddingX={2}>
            {Object.keys(myMenu.iframe?.params || {}).map((iframe, i) => {
              return (
                <AddMenuParam
                  key={i}
                  type="edit"
                  param={myMenu.iframe?.params![iframe]}
                  paramName={iframe}
                  onSubmit={handleSetMyMenuParams}
                />
              )
            })}

            <AddMenuParam type="add" param={newParamObj} paramName={newParamName} onSubmit={handleSetMyMenuParams} />
          </Box>
        </ConfirmationModal>
      </Box>
    </Paper>
  )
}

export default AddMenu
