import { faEnvelopeOpenText, faUser, faUserXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearBlacklistFile, setAddBlacklistRequest } from 'store/previas/actions'
import ConfirmationModal from 'components/ConfirmationModal'
import Dropzone from './Dropzone'
import { IApplicationState } from 'store'

const AddBlacklistModal: React.FC<any> = ({ open, setOpen, isNew }) => {
  const dispatch = useDispatch()
  const { blacklist, blacklistDetail } = useSelector((state: IApplicationState) => state.previas)
  const [data, setData] = useState({
    name: '',
    document: '',
    reason: '',
    file: '',
  })

  const [errors, setErrors] = useState({
    name: false,
    document: false,
    reason: false,
    file: false,
  })

  useEffect(() => {
    setData({
      name: '',
      document: '',
      reason: '',
      file: '',
    })
    dispatch(clearBlacklistFile())
    // eslint-disable-next-line
  }, [open])

  useEffect(() => {
    if (blacklist.file) {
      setData({ ...data, file: blacklist.file.location })
    }
    // eslint-disable-next-line
  }, [blacklist.file])

  const handleConfirm = () => {
    if (isNew && (data.name === '' || data.document === '' || data.document.length < 11)) {
      setErrors({
        ...errors,
        name: data.name === '',
        document: data.document === '' || data.document.length < 11,
      })
      return
    }
    if (data.reason === '' || data.file === '') {
      setErrors({
        ...errors,
        reason: data.reason === '',
        file: data.file === '',
      })
      return
    }
    setErrors({
      name: false,
      document: false,
      reason: false,
      file: false,
    })
    dispatch(
      setAddBlacklistRequest({
        cpfCnpj: !isNew ? blacklistDetail.data.blacklist.cpfCnpj : data.document,
        name: !isNew ? blacklistDetail.data.blacklist.name : data.name,
        description: data.reason,
        file: data.file,
      }),
    )
    setData({
      name: '',
      document: '',
      reason: '',
      file: '',
    });
    dispatch(clearBlacklistFile())
    setOpen(false)
  }

  const handleData = (key: string, value: string) => {
    if (key === 'document') {
      value = value.match(/\d+/g)?.join('') ?? ''
      if (value.length > 14) value = value.slice(0, 14)
    }
    if (errors[key]) setErrors({ ...errors, [key]: false })
    setData({ ...data, [key]: value })
  }
  return (
    <ConfirmationModal
      hasTop
      width="30%"
      open={open}
      onCancel={() => setOpen(false)}
      onClose={() => setOpen(false)}
      onConfirm={() => handleConfirm()}
      cancelText="Cancelar"
      confirmText="Adicionar"
      loading={blacklist.loading}
    >
      <Typography variant="h6">
        <FontAwesomeIcon icon={faUserXmark} /> Adicionar à blacklist
      </Typography>

      {isNew && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '15px' }}>
          <Typography>
            <FontAwesomeIcon icon={faUser} /> Dados Pessoais
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            id="name"
            name="name"
            autoComplete="name"
            label="Nome"
            autoFocus
            value={data.name ?? ''}
            error={errors.name}
            onChange={(e) => handleData('name', e.target.value)}
            size="small"
          />
          <TextField
            variant="outlined"
            fullWidth
            id="document"
            name="document"
            autoComplete="document"
            label="Documento"
            error={errors.document}
            value={data.document ?? ''}
            onChange={(e) => handleData('document', e.target.value)}
            size="small"
          />
        </div>
      )}

      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '15px' }}>
        <Typography>
          <FontAwesomeIcon icon={faEnvelopeOpenText} /> Motivo
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          id="reason"
          name="reason"
          autoComplete="reason"
          label="Motivo"
          value={data.reason ?? ''}
          error={errors.reason}
          onChange={(e) => handleData('reason', e.target.value)}
          size="small"
          multiline
          minRows={4}
          maxRows={4}
        />
      </div>
      <Dropzone hasError={errors.file} />
    </ConfirmationModal>
  )
}

export default withRouter(AddBlacklistModal)
