export const CaasTypes = {
  UPDATE_CAAS_SIMULATORS_REQUEST: '@@CAAS/UPDATE_CAAS_SIMULATORS_REQUEST',
  UPDATE_CAAS_SIMULATORS_SUCCESS: '@@CAAS/UPDATE_CAAS_SIMULATORS_SUCCESS',
  UPDATE_CAAS_SIMULATORS_FAILURE: '@@CAAS/UPDATE_CAAS_SIMULATORS_FAILURE',

  GET_CAAS_SIMULATORS_REQUEST: '@@CAAS/GET_CAAS_SIMULATORS_REQUEST',
  GET_CAAS_SIMULATORS_SUCCESS: '@@CAAS/GET_CAAS_SIMULATORS_SUCCESS',
  GET_CAAS_SIMULATORS_FAILURE: '@@CAAS/GET_CAAS_SIMULATORS_FAILURE',

  GET_CAAS_CONSORTIUMS_REQUEST: '@@CAAS/GET_CAAS_CONSORTIUMS_REQUEST',
  GET_CAAS_CONSORTIUMS_SUCCESS: '@@CAAS/GET_CAAS_CONSORTIUMS_SUCCESS',
  GET_CAAS_CONSORTIUMS_FAILURE: '@@CAAS/GET_CAAS_CONSORTIUMS_FAILURE',

  GET_CAAS_BANNER_REQUEST: '@@CAAS/GET_CAAS_BANNER_REQUEST',
  GET_CAAS_BANNER_SUCCESS: '@@CAAS/GET_CAAS_BANNER_SUCCESS',
  GET_CAAS_BANNER_FAILURE: '@@CAAS/GET_CAAS_BANNER_FAILURE',

  UPDATE_CAAS_BANNER_REQUEST: '@@CAAS/UPDATE_CAAS_BANNER_REQUEST',
  UPDATE_CAAS_BANNER_SUCCESS: '@@CAAS/UPDATE_CAAS_BANNER_SUCCESS',
  UPDATE_CAAS_BANNER_FAILURE: '@@CAAS/UPDATE_CAAS_BANNER_FAILURE',

  GET_CAAS_REGULATION_REQUEST: '@@CAAS/GET_CAAS_REGULATION_REQUEST',
  GET_CAAS_REGULATION_SUCCESS: '@@CAAS/GET_CAAS_REGULATION_SUCCESS',
  GET_CAAS_REGULATION_FAILURE: '@@CAAS/GET_CAAS_REGULATION_FAILURE',

  GET_CAAS_REGULATION_BY_ID_REQUEST: '@@CAAS/GET_CAAS_REGULATION_BY_ID_REQUEST',
  GET_CAAS_REGULATION_BY_ID_SUCCESS: '@@CAAS/GET_CAAS_REGULATION_BY_ID_SUCCESS',
  GET_CAAS_REGULATION_BY_ID_FAILURE: '@@CAAS/GET_CAAS_REGULATION_BY_ID_FAILURE',

  DELETE_CAAS_REGULATION_BY_ID_REQUEST: '@@CAAS/DELETE_CAAS_REGULATION_BY_ID_REQUEST',
  DELETE_CAAS_REGULATION_BY_ID_SUCCESS: '@@CAAS/DELETE_CAAS_REGULATION_BY_ID_SUCCESS',
  DELETE_CAAS_REGULATION_BY_ID_FAILURE: '@@CAAS/_CAAS_REGULATION_BY_ID_FAILURE',

  UPDATE_CAAS_REGULATION_REQUEST: '@@CAAS/UPDATE_CAAS_REGULATION_REQUEST',
  UPDATE_CAAS_REGULATION_SUCCESS: '@@CAAS/UPDATE_CAAS_REGULATION_SUCCESS',
  UPDATE_CAAS_REGULATION_FAILURE: '@@CAAS/UPDATE_CAAS_REGULATION_FAILURE',

  CREATE_CAAS_REGULATION_REQUEST: '@@CAAS/CREATE_CAAS_REGULATION_REQUEST',
  CREATE_CAAS_REGULATION_SUCCESS: '@@CAAS/CREATE_CAAS_REGULATION_SUCCESS',
  CREATE_CAAS_REGULATION_FAILURE: '@@CAAS/CREATE_CAAS_REGULATION_FAILURE',

  DELETE_CAAS_REGULATION_REQUEST: '@@CAAS/DELETE_CAAS_REGULATION_REQUEST',
  DELETE_CAAS_REGULATION_SUCCESS: '@@CAAS/DELETE_CAAS_REGULATION_SUCCESS',
  DELETE_CAAS_REGULATION_FAILURE: '@@CAAS/DELETE_CAAS_REGULATION_FAILURE',
}

export interface ICaasState {
  updateSimulators: Record<string, any>
  getSimulators: Record<string, any>
  getConsortiums: Record<string, any>
  getBanners: Record<string, any>
  updateBanners: Record<string, any>
  getRegulation: Record<string, any>
  getRegulationById: Record<string, any>
  updateRegulation: Record<string, any>
  createRegulation: Record<string, any>
  deleteRegulation: Record<string, any>
}
