import React, { lazy } from 'react'
import { MenuItems } from 'routes/Routes'
import * as Icons from '@material-ui/icons'
import { buildRouteAndSubRoute } from 'core/dynamic-component'
import { ASSEMBLY_RESOURCE } from 'shared/resources'

const AssemblyListPage = lazy(() => import('pages/Assembly/HomeService/List'))
const AssemblyCreatePage = lazy(() => import('pages/Assembly/HomeService/Create'))
const AssemblyEditPage = lazy(() => import('pages/Assembly/HomeService/Edit'))

const AssemblyMotorsListPage = lazy(() => import('pages/Assembly/Motors/List'))
const AssemblyMotorsCreatePage = lazy(() => import('pages/Assembly/Motors/Create'))
const AssemblyMotorsEditPage = lazy(() => import('pages/Assembly/Motors/Edit'))

export const AssembleiaRoutes: MenuItems[] = [
  {
    path: '',
    name: 'Assembleias',
    iconComponent: Icons.CalendarToday,
    menus: [
      {
        path: `${ASSEMBLY_RESOURCE}/lista`,
        name: 'Lista assembleias',
        component: AssemblyListPage,
        exact: true,
        menus: [],
      },
      {
        path: `${ASSEMBLY_RESOURCE}/editar/:datetime`,
        name: 'Editar assembleias',
        component: AssemblyEditPage,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${ASSEMBLY_RESOURCE}/criar`,
        name: 'Criar assembleias',
        component: AssemblyCreatePage,
        exact: true,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${ASSEMBLY_RESOURCE}/motors/`,
        name: 'Lista assembleias',
        component: AssemblyMotorsListPage,
        exact: true,
        menus: [],
      },
      {
        path: `${ASSEMBLY_RESOURCE}/motors/editar/:datetime`,
        name: 'Editar assembleias',
        component: AssemblyMotorsEditPage,
        isSubRoute: true,
        menus: [],
      },
      {
        path: `${ASSEMBLY_RESOURCE}/motors/criar`,
        name: 'Criar assembleias',
        component: AssemblyMotorsCreatePage,
        exact: true,
        isSubRoute: true,
        menus: [],
      },
    ],
  },
]

export default function AssembleiaRoutesComponent() {
  return <>{buildRouteAndSubRoute(AssembleiaRoutes)}</>
}
