export const ToastrTypes = {
  SHOW_TOASTR: '@@toastr/SHOW_TOASTR',
  HIDE_TOASTER: '@@toastr/HIDE_TOASTER',
}

export type ToastrType = 'error' | 'success' | 'warning' | 'info'

export interface IToastrState {
  show: boolean
  message: string
  type: ToastrType
}
