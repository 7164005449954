import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'
import { VersionAppTypes, IVersionAppState } from './types'

export const initialState: IVersionAppState = {
  list: {
    loading: false,
    data: [],
    error: '',
  },
  lastVersion: {
    loading: false,
    data: [],
    error: '',
  },
}

export const versionAppReducer = (
  state: IVersionAppState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IVersionAppState => {
  switch (action.type) {
    case VersionAppTypes.GET_VERSION_APP_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      }

    case VersionAppTypes.GET_VERSION_APP_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload,
          loading: false,
        },
      }
    case VersionAppTypes.GET_VERSION_APP_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          data: {},
          error: 'Falha ao carregar dados',
          loading: true,
        },
      }
    case VersionAppTypes.GET_LAST_VERSION_APP_REQUEST:
      return {
        ...state,
        lastVersion: {
          ...state.lastVersion,
          loading: true,
        },
      }

    case VersionAppTypes.GET_LAST_VERSION_APP_SUCCESS:
      return {
        ...state,
        lastVersion: {
          ...state.lastVersion,
          data: action.payload,
          loading: false,
        },
      }
    case VersionAppTypes.GET_LAST_VERSION_APP_FAILURE:
      return {
        ...state,
        lastVersion: {
          ...state.lastVersion,
          data: {},
          error: 'Falha ao carregar dados',
          loading: true,
        },
      }

    default:
      return state
  }
}
