/* eslint-disable @typescript-eslint/camelcase */
import { PreviasTypes, IPreviasState } from './types'
import { Action, PayloadAction, TypeConstant } from 'typesafe-actions'

export const initialState: IPreviasState = {
  loadingPrevias: false,
  loadingDocument: false,
  loadingUploadConsultaExterna: false,
  backstage_flags: {
    analise_pf: false,
    analise_pj: false,
    arquivar_previa: false,
    cadastro_pf: false,
    gerar_matricula: false,
    historico: false,
  },
  hasPj: false,
  serviceUnit: [],
  pj: {},
  pf: {
    nome: '',
    celular: '',
    data_nascimento: '',
    email: '',
    endereco: '',
    estado_civil: '',
    nacionalidade: '',
    orgao_emissor: '',
    profissao: '',
    rg: '',
    cidade_nascimento: '',
    uf_nascimento: '',
    cpf: '',
    nome_conjuge: '',
    telefone_fixo: '',
    _id: '',
    approvable: false,
    botsDocuments: [],
    cidades_resididas: [],
    docNotApproved: [],
    documents: [],
    externalDocuments: [],
    inAnalise: false,
  },
  list: {
    loading: false,
    data: [],
    count: 0,
    enableFilters: {
      bu: undefined,
      status: undefined,
    },
    error: '',
  },
  listNotFiltred: {
    loading: false,
    data: [],
    count: 0,
    enableFilters: {
      bu: undefined,
      status: undefined,
    },
    error: '',
  },
  listRelatorio: {
    loading: false,
    data: [],
    error: '',
  },
  userDetailTerm: {
    loading: false,
    data: [],
    error: '',
  },
  historyList: {
    loading: false,
    data: [],
    count: 0,
    error: '',
  },
  sendComment: {
    loading: false,
    error: '',
  },
  units: {
    loading: false,
    data: [],
    error: '',
  },
  uploadPrevias: {
    loading: false,
    error: '',
  },
  documents: {
    loading: false,
    data: {
      mappedDocuments: [],
      externos: [],
    },
    error: '',
  },
  sendCommentsStatus: {
    loading: false,
    error: '',
  },
  uclaFlag: {
    loading: false,
    data: {
      porcentage: undefined,
      modalContrato: false,
      modalContratoDetalhes: false,
    },
    error: '',
  },
  witness: {
    loading: false,
    data: {
      person: undefined,
      representante: undefined,
      testemunhas: [],
    },
    error: '',
  },
  updateUserPj: {
    loading: false,
    error: '',
  },
  setDocument: {
    loading: false,
    error: '',
  },
  prorrogar: {
    loading: false,
    error: '',
  },
  arquivarPrevia: {
    loading: false,
    error: '',
  },
  banks: {
    data: [],
  },
  robots: undefined,
  minuta: undefined,
  robotsList: undefined,
  emailCorpo: {
    email: '',
    password: '',
  },
  contratoDetail: {
    loading: false,
    data: {
      candidatoData: undefined,
      licenciadoData: undefined,
      idContrato: '',
    },
    error: '',
  },
  cancelContrato: {
    loading: false,
    error: '',
  },
  finishPrevia: undefined,
  termConfidentialityDetail: {
    loading: false,
    data: [],
    error: '',
  },
  termConfidentialityCancel: {
    loading: false,
    data: [],
    error: '',
  },
  blackListPrevia: {
    loading: false,
    data: [],
    error: '',
  },
  _id: '',
  dataRegistro: '',
  unidade: '',
  lojista: '',
  bu: '',
  status: '',
  sla: '',
  quinzeDias: false,
  trintaDias: false,
  uca: undefined,
  desistencia: false,
  matriculas: undefined,
  hasTerm: false,
  term: undefined,
  resendTerm: false,
  hasUca: false,
  hasContrato: false,
  contract: undefined,
  config: {
    docTypes: [],
    witness: {
      person: undefined,
      representante: undefined,
      testemunhas: [],
    },
  },
  preMatricula: undefined,
  newconValidationCandidato: undefined,
  motivosPadrao: {
    loading: false,
  },
  blacklist: {
    uploadLoading: false,
    totalPage: 0,
    loading: false,
    data: [],
    file: '',
  },
  blacklistDetail: {
    loading: false,
    data: {
      previas: [],
      blacklist: {
        _id: '',
        name: '',
        cpfCnpj: '',
        createdAt: '',
        user: '',
        active: false,
        historic: [],
        updatedAt: '',
      },
    },
  },
}

export const previasReducer = (
  state: IPreviasState = initialState,
  action: Action<TypeConstant> & PayloadAction<TypeConstant, any>,
): IPreviasState => {
  switch (action.type) {
    case PreviasTypes.GET_LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      }
    case PreviasTypes.GET_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data.previas,
          count: action.payload.data.count,
          loading: false,
        },
      }
    case PreviasTypes.GET_LIST_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }

    case PreviasTypes.GET_ARCHIVED_LISTS_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      }
    case PreviasTypes.GET_ARCHIVED_LISTS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data.previas,
          count: action.payload.data.count,
          loading: false,
        },
      }
    case PreviasTypes.GET_ARCHIVED_LISTS_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }

    case PreviasTypes.SET_FILTER_LIST_REQUEST:
      return {
        ...state,
        listNotFiltred: {
          ...state.listNotFiltred,
          loading: true,
        },
      }
    case PreviasTypes.SET_FILTER_LIST_SUCCESS:
      return {
        ...state,
        listNotFiltred: {
          ...state.listNotFiltred,
          data: action.payload.data.previas,
          count: action.payload.data.count,
          enableFilters: action.payload.data.enableFilters,
          loading: false,
        },
      }
    case PreviasTypes.SET_FILTER_LIST_FAILURE:
      return {
        ...state,
        listNotFiltred: {
          ...state.listNotFiltred,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }

    case PreviasTypes.SET_MOTIVOS_PADRAO_REQUEST:
      return {
        ...state,
        motivosPadrao: {
          ...state.motivosPadrao,
          loading: true,
        },
      }
    case PreviasTypes.SET_MOTIVOS_PADRAO_SUCCESS:
      return {
        ...state,
        motivosPadrao: {
          ...state.motivosPadrao,
          data: action.payload.data,
          loading: false,
        },
      }
    case PreviasTypes.SET_BLACKLIST_REQUEST:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          loading: true,
        },
      }
    case PreviasTypes.SET_BLACKLIST_SUCCESS:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          totalPage: action.payload.data.totalPage,
          data: action.payload.data.data,
          loading: false,
        },
      }
    case PreviasTypes.SET_BLACKLIST_FAILURE:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          loading: false,
        },
      }
    case PreviasTypes.SET_BLACKLIST_UPLOAD_REQUEST:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          uploadLoading: true,
        },
      }
    case PreviasTypes.SET_BLACKLIST_UPLOAD_SUCCESS:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          uploadLoading: false,
          file: action.payload.data[0],
        },
      }
    case PreviasTypes.SET_BLACKLIST_UPLOAD_FAILURE:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          uploadLoading: false,
        },
      }
    case PreviasTypes.CLEAR_BLACKLIST_FILE:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          file: null,
        },
      }
    case PreviasTypes.SET_ADD_BLACKLIST_REQUEST:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          loading: true,
        },
      }
    case PreviasTypes.SET_ADD_BLACKLIST_SUCCESS:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          loading: false,
        },
      }
    case PreviasTypes.SET_ADD_BLACKLIST_FAILURE:
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          loading: false,
        },
      }
    case PreviasTypes.SET_BLACKLIST_BY_ID_REQUEST:
      return {
        ...state,
        blacklistDetail: {
          ...state.blacklistDetail,
          loading: true,
        },
      }
    case PreviasTypes.SET_BLACKLIST_BY_ID_SUCCESS:
      return {
        ...state,
        blacklistDetail: {
          ...state.blacklistDetail,
          data: action.payload.data,
          loading: false,
        },
      }
    case PreviasTypes.SET_BLACKLIST_BY_ID_FAILURE:
      return {
        ...state,
        blacklistDetail: {
          ...state.blacklistDetail,
          loading: false,
        },
      }
    case PreviasTypes.SET_REMOVE_BLACKLIST_REQUEST:
      return {
        ...state,
        blacklistDetail: {
          ...state.blacklistDetail,
          loading: true,
        },
      }
    case PreviasTypes.SET_REMOVE_BLACKLIST_SUCCESS:
      return {
        ...state,
        blacklistDetail: {
          ...state.blacklistDetail,
          loading: false,
        },
      }
    case PreviasTypes.SET_REMOVE_BLACKLIST_FAILURE:
      return {
        ...state,
        blacklistDetail: {
          ...state.blacklistDetail,
          loading: false,
        },
      }
    case PreviasTypes.SET_BLACKLIST_ADD_COMMENT:
      return {
        ...state,
        blacklistDetail: {
          ...state.blacklistDetail,
          loading: true,
        },
      }
    case PreviasTypes.SET_BLACKLIST_ADD_COMMENT_SUCCESS:
      return {
        ...state,
        blacklistDetail: {
          ...state.blacklistDetail,
          loading: false,
        },
      }
    case PreviasTypes.SET_BLACKLIST_ADD_COMMENT_FAILURE:
      return {
        ...state,
        blacklistDetail: {
          ...state.blacklistDetail,
          loading: false,
        },
      }
    case PreviasTypes.GET_LIST_RELATORIO_REQUEST:
      return {
        ...state,
        listRelatorio: {
          ...state.listRelatorio,
          loading: true,
        },
      }
    case PreviasTypes.GET_LIST_RELATORIO_SUCCESS:
      return {
        ...state,
        listRelatorio: {
          ...state.listRelatorio,
          data: action.payload.data,
          loading: false,
        },
      }
    case PreviasTypes.GET_LIST_RELATORIO_FAILURE:
      return {
        ...state,
        listRelatorio: {
          ...state.listRelatorio,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case PreviasTypes.GET_USER_DETAIL_TERM_REQUEST:
      return {
        ...state,
        userDetailTerm: {
          ...state.userDetailTerm,
          loading: true,
        },
      }
    case PreviasTypes.GET_USER_DETAIL_TERM_SUCCESS:
      return {
        ...state,
        userDetailTerm: {
          ...state.userDetailTerm,
          data: action.payload.data,
          loading: false,
        },
      }
    case PreviasTypes.GET_USER_DETAIL_TERM_FAILURE:
      return {
        ...state,
        userDetailTerm: {
          ...state.userDetailTerm,
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case PreviasTypes.GET_HISTORY_LIST_REQUEST:
      return {
        ...state,
        historyList: {
          ...state.historyList,
          loading: true,
        },
      }
    case PreviasTypes.GET_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        historyList: {
          ...state.historyList,
          data: action.payload.data.data,
          loading: false,
        },
      }
    case PreviasTypes.GET_HISTORY_LIST_FAILURE:
      return {
        ...state,
        historyList: {
          ...state.historyList,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case PreviasTypes.SEND_COMMENT_REQUEST:
      return {
        ...state,
        sendComment: {
          ...state.sendComment,
          loading: true,
        },
      }
    case PreviasTypes.SEND_COMMENT_SUCCESS:
      return {
        ...state,
        sendComment: {
          ...state.sendComment,
          loading: false,
        },
      }
    case PreviasTypes.SEND_COMMENT_FAILURE:
      return {
        ...state,
        sendComment: {
          ...state.sendComment,
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case PreviasTypes.SEND_DESISTENCIA_REQUEST:
      return {
        ...state,
        sendComment: {
          ...state.sendComment,
          loading: true,
        },
      }
    case PreviasTypes.SEND_DESISTENCIA_SUCCESS:
      return {
        ...state,
        sendComment: {
          ...state.sendComment,
          loading: false,
        },
      }
    case PreviasTypes.SEND_DESISTENCIA_FAILURE:
      return {
        ...state,
        sendComment: {
          ...state.sendComment,
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case PreviasTypes.GET_UNITS_REQUEST:
      return {
        ...state,
        units: {
          ...state.units,
          loading: true,
        },
      }
    case PreviasTypes.GET_UNITS_SUCCESS:
      return {
        ...state,
        units: {
          ...state.units,
          data: action.payload.data,
          loading: false,
        },
      }
    case PreviasTypes.GET_UNITS_FAILURE:
      return {
        ...state,
        units: {
          ...state.units,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case PreviasTypes.UPLOAD_PREVIAS_REQUEST:
      return {
        ...state,
        uploadPrevias: {
          ...state.uploadPrevias,
          loading: true,
        },
      }
    case PreviasTypes.UPLOAD_PREVIAS_SUCCESS:
      return {
        ...state,
        uploadPrevias: {
          ...state.uploadPrevias,
          loading: false,
        },
      }
    case PreviasTypes.UPLOAD_PREVIAS_FAILURE:
      return {
        ...state,
        uploadPrevias: {
          ...state.uploadPrevias,
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case PreviasTypes.SET_DOCUMENTS_REQUEST:
      return {
        ...state,
        sendCommentsStatus: {
          ...state.sendCommentsStatus,
          loading: true,
        },
      }
    case PreviasTypes.SET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        sendCommentsStatus: {
          ...state.sendCommentsStatus,
          loading: false,
        },
      }
    case PreviasTypes.SET_DOCUMENTS_FAILURE:
      return {
        ...state,
        sendCommentsStatus: {
          ...state.sendCommentsStatus,
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case PreviasTypes.GET_USER_DETAIL_REQUEST:
      return {
        ...state,
        loadingPrevias: action.payload.shouldReload ?? true,
        loadingDocument: true,
      }
    case PreviasTypes.GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        loadingPrevias: false,
        loadingDocument: false,
      }
    case PreviasTypes.GET_USER_DETAIL_FAILURE:
      return {
        ...state,
        loadingPrevias: false,
        loadingDocument: false,
      }
    case PreviasTypes.GET_UCLA_FLAG_REQUEST:
      return {
        ...state,
        uclaFlag: {
          ...state.uclaFlag,
          loading: true,
        },
      }
    case PreviasTypes.GET_UCLA_FLAG_SUCCESS:
      return {
        ...state,
        uclaFlag: {
          ...state.uclaFlag,
          data: action.payload.data,
          loading: false,
        },
      }
    case PreviasTypes.GET_UCLA_FLAG_FAILURE:
      return {
        ...state,
        uclaFlag: {
          ...state.uclaFlag,
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }

    case PreviasTypes.GET_WITNESS_REQUEST:
      return {
        ...state,
        witness: {
          ...state.witness,
          loading: true,
        },
      }
    case PreviasTypes.GET_WITNESS_SUCCESS:
      return {
        ...state,
        witness: {
          ...state.witness,
          data: action.payload.data,
          loading: false,
        },
      }
    case PreviasTypes.GET_WITNESS_FAILURE:
      return {
        ...state,
        witness: {
          ...state.witness,
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }

    case PreviasTypes.UPDATE_USER_PJ_REQUEST:
      return {
        ...state,
        updateUserPj: {
          ...state.updateUserPj,
          loading: true,
        },
      }
    case PreviasTypes.UPDATE_USER_PJ_SUCCESS:
      return {
        ...state,
        updateUserPj: {
          ...state.updateUserPj,
          loading: false,
        },
      }
    case PreviasTypes.UPDATE_USER_PJ_FAILURE:
      return {
        ...state,
        updateUserPj: {
          ...state.updateUserPj,
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }

    case PreviasTypes.SET_PRORROGAR_REQUEST:
      return {
        ...state,
        prorrogar: {
          ...state.prorrogar,
          loading: true,
        },
      }
    case PreviasTypes.SET_PRORROGAR_SUCCESS:
      return {
        ...state,
        prorrogar: {
          ...state.prorrogar,
          loading: false,
        },
      }
    case PreviasTypes.SET_PRORROGAR_FAILURE:
      return {
        ...state,
        prorrogar: {
          ...state.prorrogar,
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }

    case PreviasTypes.GET_BANKS_REQUEST:
      return {
        ...state,
        banks: {
          ...state.banks,
        },
      }
    case PreviasTypes.GET_BANKS_SUCCESS:
      return {
        ...state,
        banks: {
          ...state.banks,
          data: action.payload.data,
        },
      }
    case PreviasTypes.GET_BANKS_FAILURE:
      return {
        ...state,
        banks: {
          ...state.banks,
          data: [],
        },
      }

    case PreviasTypes.SET_CONTRATO_REQUEST:
      return {
        ...state,
        setDocument: {
          ...state.setDocument,
          loading: true,
        },
      }
    case PreviasTypes.SET_CONTRATO_SUCCESS:
      return {
        ...state,
        setDocument: {
          ...state.setDocument,
          loading: false,
        },
      }
    case PreviasTypes.SET_CONTRATO_FAILURE:
      return {
        ...state,
        setDocument: {
          ...state.setDocument,
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }

    case PreviasTypes.GET_ROBOTS_REQUEST:
      return {
        ...state,
        robots: {
          ...state.robots,
          loading: true,
        },
      }
    case PreviasTypes.GET_ROBOTS_SUCCESS:
      return {
        ...state,
        robots: {
          ...state.robots,
          data: action.payload.data,
          loading: false,
        },
      }
    case PreviasTypes.GET_ROBOTS_FAILURE:
      return {
        ...state,
        robots: {
          ...state.robots,
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }

    case PreviasTypes.GET_MINUTA_REQUEST:
      return {
        ...state,
        minuta: {
          ...state.minuta,
          loading: true,
        },
      }
    case PreviasTypes.GET_MINUTA_SUCCESS:
      return {
        ...state,
        minuta: {
          ...state.minuta,
          data: action.payload.data,
          loading: false,
        },
      }
    case PreviasTypes.GET_MINUTA_FAILURE:
      return {
        ...state,
        minuta: {
          ...state.minuta,
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }

    case PreviasTypes.GET_ROBOTS_LISTS_REQUEST:
      return {
        ...state,
        robotsList: {
          ...state.robotsList,
          loading: true,
        },
      }
    case PreviasTypes.GET_ROBOTS_LISTS_SUCCESS:
      return {
        ...state,
        robotsList: {
          ...state.robotsList,
          data: action.payload.data,
          loading: false,
        },
      }
    case PreviasTypes.GET_ROBOTS_LISTS_FAILURE:
      return {
        ...state,
        robotsList: {
          ...state.robotsList,
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }

    case PreviasTypes.RESET_STATE_REQUEST:
      return {
        ...state,
        minuta: undefined,
      }
    case PreviasTypes.RESET_STATE_SUCCESS:
      return {
        ...state,
        minuta: undefined,
      }
    case PreviasTypes.RESET_STATE_FAILURE:
      return {
        ...state,
        minuta: undefined,
      }

    case PreviasTypes.ARQUIVAR_PREVIA_REQUEST:
      return {
        ...state,
        arquivarPrevia: {
          ...state.arquivarPrevia,
          loading: true,
        },
      }
    case PreviasTypes.ARQUIVAR_PREVIA_SUCCESS:
      return {
        ...state,
        arquivarPrevia: {
          ...state.arquivarPrevia,
          loading: false,
        },
      }
    case PreviasTypes.ARQUIVAR_PREVIA_FAILURE:
      return {
        ...state,
        arquivarPrevia: {
          ...state.arquivarPrevia,
          loading: false,
          error: 'Falha ao carregar dados',
        },
      }

    case PreviasTypes.GET_CONTRATO_DETAIL_REQUEST:
      return {
        ...state,
        contratoDetail: {
          ...state.contratoDetail,
          loading: true,
        },
      }
    case PreviasTypes.GET_CONTRATO_DETAIL_SUCCESS:
      return {
        ...state,
        contratoDetail: {
          ...state.contratoDetail,
          data: action.payload.data,
          loading: false,
        },
      }
    case PreviasTypes.GET_CONTRATO_DETAIL_FAILURE:
      return {
        ...state,
        contratoDetail: {
          ...state.contratoDetail,
          loading: false,
          error: 'Falha ao carregar dados',
        },
      }

    case PreviasTypes.SET_CANCEL_CONTRATO_REQUEST:
      return {
        ...state,
        cancelContrato: {
          ...state.cancelContrato,
          loading: true,
        },
      }
    case PreviasTypes.SET_CANCEL_CONTRATO_SUCCESS:
      return {
        ...state,
        cancelContrato: {
          ...state.cancelContrato,
          loading: false,
        },
      }
    case PreviasTypes.SET_CANCEL_CONTRATO_FAILURE:
      return {
        ...state,
        cancelContrato: {
          ...state.cancelContrato,
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case PreviasTypes.FINISH_PREVIA_REQUEST:
      return {
        ...state,
        finishPrevia: {
          ...state.finishPrevia,
          sucess: false,
          loading: true,
        },
      }
    case PreviasTypes.FINISH_PREVIA_SUCCESS:
      return {
        ...state,
        finishPrevia: {
          ...state.finishPrevia,
          sucess: true,
          loading: false,
        },
      }
    case PreviasTypes.FINISH_PREVIA_FAILURE:
      return {
        ...state,
        finishPrevia: {
          ...state.finishPrevia,
          error: true,
          sucess: false,
          loading: false,
        },
      }

    case PreviasTypes.GET_TERM_CONFIDENTIALITY_FAILURE:
      return {
        ...state,
        termConfidentialityDetail: {
          ...state.termConfidentialityDetail,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case PreviasTypes.GET_TERM_CONFIDENTIALITY_REQUEST:
      return {
        ...state,
        termConfidentialityDetail: {
          ...state.termConfidentialityDetail,
          loading: true,
        },
      }
    case PreviasTypes.GET_TERM_CONFIDENTIALITY_SUCCESS:
      return {
        ...state,
        termConfidentialityDetail: {
          ...state.termConfidentialityDetail,
          data: action.payload.data,
          loading: false,
        },
      }

    case PreviasTypes.SET_CANCEL_TERM_CONFIDENTIALITY_FAILURE:
      return {
        ...state,
        termConfidentialityCancel: {
          ...state.termConfidentialityCancel,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }
    case PreviasTypes.SET_CANCEL_TERM_CONFIDENTIALITY_REQUEST:
      return {
        ...state,
        termConfidentialityCancel: {
          ...state.termConfidentialityCancel,
          loading: true,
        },
      }
    case PreviasTypes.SET_CANCEL_TERM_CONFIDENTIALITY_SUCCESS:
      return {
        ...state,
        termConfidentialityCancel: {
          ...state.termConfidentialityCancel,
          data: action.payload.data,
          loading: false,
        },
      }
    case PreviasTypes.POST_ADD_BLACK_LIST_REQUEST:
      return {
        ...state,
        blackListPrevia: {
          ...state.blackListPrevia,
          loading: true,
        },
      }
    case PreviasTypes.POST_ADD_BLACK_LIST_SUCCESS:
      return {
        ...state,
        blackListPrevia: {
          ...state.blackListPrevia,
          data: action.payload.data,
          loading: false,
        },
      }
    case PreviasTypes.POST_ADD_BLACK_LIST_FAILURE:
      return {
        ...state,
        blackListPrevia: {
          ...state.blackListPrevia,
          data: [],
          error: 'Falha ao carregar dados',
          loading: false,
        },
      }

    case PreviasTypes.SEND_APROVAR_REPROVAR_DOCUMENTO_REQUEST:
      return {
        ...state,
        loadingDocument: true,
      }
    case PreviasTypes.SEND_APROVAR_REPROVAR_DOCUMENTO_SUCCESS:
    case PreviasTypes.SEND_APROVAR_REPROVAR_DOCUMENTO_FAILURE:
      return {
        ...state,
        loadingDocument: false,
      }

    case PreviasTypes.REFRESH_DOCS_SUCCESS:
      return {
        ...state,
        pf: {
          ...state.pf,
          documents: action.payload.data.mappedDocuments,
          externalDocuments: action.payload.data.externos,
        },
      }
    
    case PreviasTypes.REFRESH_DOCS_SUCCESS_PJ:
      return {
        ...state,
        pj: {
          ...state.pj,
          documentos: action.payload.data.mappedDocuments,
        },
      }

      case PreviasTypes.SEND_APROVAR_ALL_DOCUMENTS_REQUEST:
      return {
        ...state,
        loadingDocument: true,
      }
    case PreviasTypes.SEND_APROVAR_ALL_DOCUMENTS_SUCCESS:
    case PreviasTypes.SEND_APROVAR_ALL_DOCUMENTS_FAILURE:
      return {
        ...state,
        loadingDocument: false,
      }

    case PreviasTypes.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        loadingUploadConsultaExterna: true,
      }
    case PreviasTypes.UPLOAD_FILE_SUCCESS:
    case PreviasTypes.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        loadingUploadConsultaExterna: false,
      }

    case PreviasTypes.SET_RESEND_TERM_CONFIDENTIALITY_REQUEST:
    case PreviasTypes.APROVAR_ANALISE_PF_REQUEST:
    case PreviasTypes.REPROVAR_ANALISE_PF_REQUEST:
      return {
        ...state,
        loadingPrevias: true,
      }
    case PreviasTypes.SET_RESEND_TERM_CONFIDENTIALITY_SUCCESS:
    case PreviasTypes.APROVAR_ANALISE_PF_SUCCESS:
    case PreviasTypes.APROVAR_ANALISE_PF_FAILURE:
    case PreviasTypes.REPROVAR_ANALISE_PF_SUCCESS:
    case PreviasTypes.REPROVAR_ANALISE_PF_FAILURE:
      return {
        ...state,
        loadingPrevias: false,
      }
    default:
      return state
  }
}
